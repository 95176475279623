import PropTypes from "prop-types";
import React, { Fragment } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { stylesMain } from "../../css";
import { formatDate } from "../../util";

const PaymentHistoryDialog = (props: any) => {
  const { classes } = props;

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Payment History</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item xs={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Paid at</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.payments &&
                  props.payments.map(
                    (payment: {
                      paid_at: string;
                      amount: number;
                      note: string;
                    }) => (
                      <TableRow>
                        <TableCell component="th" scope="payment">
                          {payment.paid_at &&
                            formatDate(new Date(payment.paid_at))}
                        </TableCell>
                        <TableCell>
                          {"¥" +
                            payment.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell>{payment.note}</TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>{" "}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.close} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

PaymentHistoryDialog.propTypes = {
  payments: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default withStyles(stylesMain)(PaymentHistoryDialog);
