import MaterialTable from "material-table";
import React, { Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesMain } from "../../css";
import { useGetSchool } from "../../fetch/school";
import useUserStateContext from "../../userstate";

const School = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { schools, getSchools } = useGetSchool();

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getSchools({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                School
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/school/create"
                  className={classes.createButton}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        {schools && (
          <div className={classes.table}>
            <MaterialTable
              columns={[{ title: "Name", field: "name" }]}
              data={schools}
              options={{
                headerStyle: {
                  backgroundColor: "#fafafa"
                },
                showTitle: false,
                pageSize: 20,
                pageSizeOptions: [20, 40, 60],
                searchFieldAlignment: "left",
                emptyRowsWhenPaging: false
              }}
              components={{
                Container: Fragment
              }}
              onRowClick={(event, rowData: any) =>
                rowData &&
                props.history.push({
                  pathname: `/school/${rowData.id}`,
                  state: { school: rowData }
                })
              }
            />
          </div>
        )}
      </main>
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(School));
