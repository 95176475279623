import React, { useEffect } from "react";

import { stylesMain } from "../../css";
import { useGetStudent } from "../../fetch/student";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

const StudentDetail = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { student, getStudent } = useGetStudent();

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getStudent({ studentId: props.match.params.studentId });
  }, [userState, props.history]);

  return (
    <>
      <main className={classes.main}>
        <p className={classes.studentDetail}>
          {JSON.stringify(student, null, "\t")}
        </p>
      </main>
    </>
  );
};

export default withStyles(stylesMain)(withRouter(StudentDetail));
