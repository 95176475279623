import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { doubleToSingleArray, renderTerm, toDoubleDigits } from "../../util";
import {
  useGetCourse,
  useGetCourses,
  useGetCoursesAdmission,
} from "../../fetch/course";

import DateFnsUtils from "@date-io/date-fns";
import { ITemplate } from "../../../../interface";
import PropTypes from "prop-types";
import SchoolSelect from "../school/SchoolSelect";
import Select from "@material-ui/core/Select";
import { TextField } from "formik-material-ui";
import { schoolNamesDict } from "../../../../enum";
import { stylesForm } from "../../css";
import { useGetTemplate } from "../../fetch/template";
import { usePostProduct } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { withStyles } from "@material-ui/styles";

const ProductIkuseiSchema = Yup.object().shape({
  description: Yup.string().required(),
  term: Yup.string(),
  open_at_bc: Yup.date().required(),
  open_at_bs: Yup.date().required(),
  open_at_ad: Yup.date().required(),
  open_at_integ: Yup.date().required(),
  close_at_bc: Yup.date().required(),
  close_at_bs: Yup.date().required(),
  close_at_ad: Yup.date().required(),
  close_at_integ: Yup.date().required(),
  email_template_id: Yup.string().required(),
});

const ProductIkuseiCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();
  const { coursesAdmission, getCoursesAdmission } = useGetCoursesAdmission();
  const { getCourseSync } = useGetCourse();
  const { postProduct } = usePostProduct();
  const [terms, setTerms] = useState<any[]>([]);
  const [term, setTerm] = useState("");
  const { templates, getTemplates } = useGetTemplate();

  useEffect(() => {
    if (props.open) {
      if (userState.selectedSchool) {
        getCourses({ schoolId: userState.selectedSchool.id });
      }
      getCoursesAdmission({});
      getTemplates({});
    }
  }, [props.open, userState.selectedSchool]);

  useEffect(() => {
    const terms = courses
      .filter((course: any) => {
        return course.term;
      })
      .map((course: any) => {
        return course.term;
      })
      .sort((a: string, b: string) => {
        return a < b ? 1 : -1;
      });
    const termsUnique = Array.from(new Set(terms));
    setTerms(termsUnique);
  }, [courses]);

  interface IFormValues {
    schoolId: any;
    description: string;
    term: string;
    open_at_bc: Date | null;
    open_at_bs: Date | null;
    open_at_ad: Date | null;
    open_at_integ: Date | null;
    close_at_bc: Date | null;
    close_at_bs: Date | null;
    close_at_ad: Date | null;
    close_at_integ: Date | null;
    entranceExamIds: Array<string>;
    email_template_id: string;
  }

  const initialValues: IFormValues = {
    schoolId: userState.selectedSchool ? userState.selectedSchool.id : "",
    description: "",
    term: "",
    open_at_bc: null,
    open_at_bs: null,
    open_at_ad: null,
    open_at_integ: null,
    close_at_bc: null,
    close_at_bs: null,
    close_at_ad: null,
    close_at_integ: null,
    entranceExamIds: [],
    email_template_id: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProductIkuseiSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);

        const breakFunction = (alertMessage: string) => {
          alert(alertMessage);
          resetForm();
          props.handleClose();
          throw "error";
        };

        let admission = coursesAdmission.filter((course: any) => {
          return course.price_weight && course.price_weight > 0;
        });
        let admissionFree = coursesAdmission.filter((course: any) => {
          return course.price_weight === 0;
        });
        if (admission.length !== 1 || admissionFree.length !== 1) {
          if (admission.length < 1) {
            breakFunction(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）がありません。先にそのコースを登録してください。Admission course with price_weight > zero is required. Register the course first.`
            );
          } else if (admission.length > 1) {
            breakFunction(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）が2つ以上あります。想定していない状態です。該当するコースを削除するか、どちらかのコースのprice_weightをnull（0ではなく空）にするか、categoriesのAdmissionのチェックを外してください。More than two Admission course with price_weight > zero are found.`
            );
          }
          if (admissionFree.length < 1) {
            breakFunction(
              `price_weightが0の無料の入学金コース（categoriesの中のAdmissionにチェックが入ったコース）がありません。先にそのコースを登録してください。Admission course with price_weight == zero is required. Register the course first.`
            );
          } else if (admissionFree.length > 1) {
            breakFunction(
              `price_weightが0の無料の入学金コース（categoriesの中のAdmissionにチェックが入ったコース）が2つ以上あります。想定していない状態です。該当するコースを削除するか、どちらかのコースのprice_weightをnull（0ではなく空）にするか、categoriesのAdmissionのチェックを外してください。More than two Admission course with price_weight == zero are found.`
            );
          }
        }
        admission = admission[0];
        admissionFree = admissionFree[0];

        // ~オンライン will also be considered as ikusei
        const coursesBC = courses.filter((course: any) => {
          return (
            course.code === "ブートキャンプステップ" &&
            course.term === values.term
          );
        });
        const coursesBS = courses.filter((course: any) => {
          return (
            course.code === "ベーシックステップ" && course.term === values.term
          );
        });
        const coursesAD = courses.filter((course: any) => {
          return (
            course.code === "アドバンスステップ" && course.term === values.term
          );
        });
        const coursesINTEG = courses.filter((course: any) => {
          return (
            course.code === "インテグレーションステップ" &&
            course.term === values.term
          );
        });

        const getWeekdayTimes = (courses: any) => {
          return Array.from(
            new Set(
              courses.map((course: any) => {
                if (course.weekday) {
                  const time =
                    course.name.match(/\d+/g).length > 0 &&
                    course.name.match(/\d+/g).length === 1
                      ? `${course.name.match(/\d+/g)[0]}:00`
                      : course.name.match(/\d+/g).join(":");
                  return `${course.weekday}-${time}`;
                } else {
                  return `7`;
                }
              })
            )
          ).sort();
        };

        const weekdayTimes: any[] = getWeekdayTimes(coursesBC);
        let newProducts: any = [];

        const selectedSchoolName = schoolNamesDict.filter(
          (schoolNameDict: any) => {
            return schoolNameDict.nameJa === userState.selectedSchool?.name;
          }
        )[0].name;

        const generateProduct = async ({
          weekday,
          courses,
          open_at,
          close_at,
          tags,
          application_conditions,
          hidden,
          email_template_id,
        }: {
          weekday: any;
          courses: any[];
          open_at: any;
          close_at: any;
          tags: string[];
          application_conditions: object;
          hidden: boolean;
          email_template_id: string;
        }) => {
          const coursesWithSessions = await Promise.all(
            courses.map(async (course: any) => {
              return await getCourseSync({ courseId: course.id });
            })
          );
          let name = coursesWithSessions
            .map((course: any) => {
              return course.name;
            })
            .join(" / ");
          name = `【${renderTerm(values.term)}】 ${name}`;
          const price = coursesWithSessions
            // .filter((course: any) => {
            //   return !course.categories.includes("Admission");
            // })
            .map((course: any) => {
              if (course.price_weight === null) {
                breakFunction(
                  `"${course.name}"というコースにprice_weight（価格）が登録されていません。先にprice_weightを登録してください。Course: "${course.name}" does not have price_weight. Set price_weight first.`
                );
              }
              return course.price_weight;
            })
            .reduce(function (p: number, c: number) {
              return p + c;
            });
          const course_ids = coursesWithSessions.map((course: any) => {
            return course.id;
          });

          let start_ats = doubleToSingleArray(
            coursesWithSessions
              .filter((course: any) => {
                return !course.categories.includes("Admission");
              })
              .map((course: any) => {
                return course.sessions;
              })
          );

          start_ats = start_ats.map((session: any) => {
            let newDate = new Date(session.start_at);
            return `${toDoubleDigits(
              newDate.getHours().toString()
            )}:${toDoubleDigits(newDate.getMinutes().toString())}`;
          });
          start_ats.sort((a: string, b: string) => {
            return a > b ? 1 : -1;
          });
          start_ats.length === 0 &&
            breakFunction(
              `セッションが登録されていないコースがあります。先にセッションを登録してください。Course without session was found.`
            );
          const start_at = `weekday_${weekday}`;
          tags.push(start_at);

          newProducts.push({
            name: name,
            description: values.description,
            code: "",
            price: price,
            open_at: open_at,
            close_at: close_at,
            hidden: hidden,
            course_ids: course_ids,
            tags: tags,
            application_conditions: application_conditions,
            email_template_id: email_template_id,
          });
        };
        console.log(weekdayTimes);
        for (let weekdayTime of weekdayTimes) {
          const courseBC = coursesBC.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseBS = coursesBS.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseAD = coursesAD.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseINTEG = coursesINTEG.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          console.log(courseBC);
          console.log(courseBS);
          console.log(courseAD);
          console.log(courseINTEG);
          !courseBC &&
            breakFunction(
              `ブートキャンプステップの曜日情報が正しく登録されていないようです。Bootcamp step for weekday ${weekdayTime} was not found`
            );
          !courseBS &&
            breakFunction(
              `ベーシックステップの曜日情報が正しく登録されていないようです。Basic step for weekday ${weekdayTime} was not found`
            );
          !courseAD &&
            breakFunction(
              `アドバンスステップの曜日情報が正しく登録されていないようです。Advance step for weekday ${weekdayTime} was not found`
            );
          !courseINTEG &&
            breakFunction(
              `インテグレーションステップの曜日情報が正しく登録されていないようです。Integration step for weekday ${weekdayTime} was not found`
            );

          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS, courseAD, courseINTEG, admissionFree],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_all`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission", progress: "no" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission", progress: "no" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS, courseAD, courseINTEG],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_all`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, admission],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bc`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission", progress: "no" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission", progress: "no" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bc`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS, admission],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bcbs`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission", progress: "no" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission", progress: "no" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bcbs`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });

          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS, courseAD, admission],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bcbsad`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission", progress: "no" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission", progress: "no" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBC, courseBS, courseAD],
            open_at: values.open_at_bc,
            close_at: values.close_at_bc,
            tags: [
              `ikusei`,
              `type_bcbsad`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions:
              values.entranceExamIds.length > 0
                ? values.entranceExamIds.map((id: string) => {
                    return {
                      courses: [
                        { id: id, progress: "completed" },
                        { code: "Admission" },
                      ],
                    };
                  })
                : [{ courses: [{ code: "Admission" }] }],
            hidden: false,
            email_template_id: values.email_template_id,
          });

          await generateProduct({
            weekday: weekdayTime,
            courses: [courseBS],
            open_at: values.open_at_bs,
            close_at: values.close_at_bs,
            tags: [
              `ikusei`,
              `type_bs`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions: [
              {
                courses: [
                  {
                    code: "ブートキャンプステップ",
                    school_id: userState.selectedSchool
                      ? userState.selectedSchool.id
                      : null,
                  },
                ],
              },
            ],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseAD],
            open_at: values.open_at_ad,
            close_at: values.close_at_ad,
            tags: [
              `ikusei`,
              `type_ad`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions: [
              {
                courses: [
                  {
                    code: "ベーシックステップ",
                    school_id: userState.selectedSchool
                      ? userState.selectedSchool.id
                      : null,
                  },
                ],
              },
            ],
            hidden: false,
            email_template_id: values.email_template_id,
          });
          await generateProduct({
            weekday: weekdayTime,
            courses: [courseINTEG],
            open_at: values.open_at_integ,
            close_at: values.close_at_integ,
            tags: [
              `ikusei`,
              `type_integ`,
              `term_${term}`,
              `school_${selectedSchoolName}`,
            ],
            application_conditions: [
              {
                courses: [
                  {
                    code: "アドバンスステップ",
                    school_id: userState.selectedSchool
                      ? userState.selectedSchool.id
                      : null,
                  },
                ],
              },
            ],
            hidden: false,
            email_template_id: values.email_template_id,
          });
        }
        console.log(JSON.stringify(newProducts));
        if (
          window.confirm(
            `${
              values.entranceExamIds.length === 0
                ? `入学試験コースが選択されていません。生成されるプロダクトは全ての受講生が申し込めるようになります。No Entrance Exam was selected. Products will be available for all students.\n`
                : ``
            }${
              newProducts.length
            } 個のプロダクトが生成されます。よろしいですか？ Totally ${
              newProducts.length
            } new products will be created. Confirm to proceed?`
          )
        ) {
          for (const product of newProducts) {
            await postProduct({ body: product });
          }
        }

        setSubmitting(false);
        props.handleClose();
      }}
      render={({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Create Ikusei Products
            </DialogTitle>
            {isSubmitting && <LinearProgress />}
            <Divider />
            <DialogContent>
              <Fragment>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <SchoolSelect />
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="description"
                      label="Product Description"
                      component={TextField}
                      fullWidth
                      multiline
                    ></Field>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label-term">Term</InputLabel>
                      <Select
                        value={values.term}
                        onChange={(event: any) => {
                          setFieldValue("term", event.target.value);
                          setTerm(event.target.value);
                        }}
                        name="term"
                        disabled={isSubmitting}
                      >
                        {terms.map((term: any) => {
                          return <MenuItem value={term}>{term}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  {[
                    { name: "open_at_bc", label: "Open at of Bootcamp" },
                    { name: "close_at_bc", label: "Close at of Bootcamp" },
                    { name: "open_at_bs", label: "Open at of Basic" },
                    { name: "close_at_bs", label: "Close at of Basic" },
                    { name: "open_at_ad", label: "Open at of Advance" },
                    { name: "close_at_ad", label: "Close at of Advance" },
                    { name: "open_at_integ", label: "Open at of Integration" },
                    {
                      name: "close_at_integ",
                      label: "Close at of Integration",
                    },
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={6} className={classes.mt1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            name={item.name}
                            label={item.label}
                            value={eval(`values.${item.name}`)}
                            onChange={(date) => setFieldValue(item.name, date)}
                            format="yyyy/MM/dd HH:mm"
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} className={classes.mt1}>
                    <FormControl className={classes.w100}>
                      <InputLabel>
                        Entrance Exams for the Term Selected Above
                      </InputLabel>
                      <Select
                        multiple
                        value={values.entranceExamIds}
                        name="code"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setFieldValue(
                            "entranceExamIds",
                            event.target.value as string[]
                          );
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                        // MenuProps={MenuProps}
                        disabled={isSubmitting}
                      >
                        {courses
                          ?.filter((course: any) => {
                            return course.name.includes("試験");
                          })
                          .map((course: any) => (
                            <MenuItem key={course.id} value={course.id}>
                              <ListItemText primary={`${course.name}`} />
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        After students complete one of the courses selected
                        here, products BC, BCBS, ALL will be available for them.
                        Only courses whose name include "試験" are listed up
                        here.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="email_template_id"
                      label="Email Template"
                      select
                      // margin="normal"
                      component={TextField}
                      fullWidth
                    >
                      {templates.map((template: ITemplate) => (
                        <MenuItem key={template.id} value={template.id}>
                          {`${template.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                color="default"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                // disabled={}
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  submitForm();
                  // props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

ProductIkuseiCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(stylesForm)(ProductIkuseiCreateDialog);
