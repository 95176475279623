import { getUrl } from "../userstate";
import useFetch from "./useFetch";
import React from "react";

export const useGetSession = (): any => {
  const { execute } = useFetch("GET");
  const [session, setSession] = React.useState(null);
  const [getSessionError, setGetSessionError] = React.useState(null);
  const [sessions, setSessions] = React.useState<any[]>([]);
  const [getSessionsError, setGetSessionsError] = React.useState(null);

  const getSession = async ({ sessionId }: { sessionId: string }) => {
    try {
      const fetchedSession = await execute({
        url: getUrl() + `v1/a/session/${sessionId}`
      });
      setSession(fetchedSession);
    } catch (error) {
      setGetSessionError(error);
    }
  };

  const getSessions = async () => {
    try {
      const fetchedSessions = await execute({
        url: getUrl() + `v1/a/session`
      });
      setSessions(fetchedSessions);
    } catch (error) {
      setGetSessionsError(error);
    }
  };

  return {
    session,
    sessions,
    getSession,
    getSessionError,
    getSessions,
    getSessionsError
  };
};

export const usePostSession = (): any => {
  const { execute } = useFetch("POST");

  const postSession = ({
    courseId,
    body
  }: {
    courseId: string;
    body: object;
  }) => {
    try {
      const newSession = execute({
        url: getUrl() + `v1/a/course/${courseId}/session`,
        body: body
      });
      return newSession;
    } catch (error) {
      alert(error);
    }
  };

  return { postSession };
};

export const usePutSession = () => {
  const { execute } = useFetch("PUT");

  const putSession = async ({
    sessionId,
    body
  }: {
    sessionId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/session/${sessionId}`,
        body: body
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putSession };
};

export const useCancelSession = () => {
  const { execute } = useFetch("PUT");

  const cancelSession = async ({
    studentId,
    sessionId
  }: {
    studentId: string;
    sessionId: string;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/student/${studentId}/session/${sessionId}/cancel`,
        body: undefined
      });
    } catch (error) {
      alert(error);
    }
  };

  return { cancelSession };
};

export const useUndoCancelSession = () => {
  const { execute } = useFetch("DELETE");

  const undoCancelSession = async ({
    studentId,
    sessionId
  }: {
    studentId: string;
    sessionId: string;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/student/${studentId}/session/${sessionId}/cancel`,
        body: undefined
      });
    } catch (error) {
      alert(error);
    }
  };

  return { undoCancelSession };
};

export const useTransferSession = () => {
  const { execute } = useFetch("PUT");

  const transferSession = async ({
    studentId,
    fromSessionId,
    toSessionId
  }: {
    studentId: string;
    fromSessionId: string;
    toSessionId: string;
  }) => {
    try {
      await execute({
        url:
          getUrl() +
          `v1/a/student/${studentId}/session/${fromSessionId}/transfer/${toSessionId}`,
        body: undefined
      });
    } catch (error) {
      alert(error);
    }
  };

  return { transferSession };
};

export const useAddAttendSession = () => {
  const { execute } = useFetch("PUT");

  const addAttendSession = async ({
    studentId,
    sessionId
  }: {
    studentId: string;
    sessionId: string;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/student/${studentId}/session/${sessionId}/attend`,
        body: undefined
      });
    } catch (error) {
      alert(error);
    }
  };

  return { addAttendSession };
};

export const useRemoveAttendSession = () => {
  const { execute } = useFetch("DELETE");

  const removeAttendSession = async ({
    studentId,
    sessionId
  }: {
    studentId: string;
    sessionId: string;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/student/${studentId}/session/${sessionId}/attend`,
        body: undefined
      });
    } catch (error) {
      alert(error);
    }
  };

  return { removeAttendSession };
};

// const useDeleteSession
