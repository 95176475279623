import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { intToWeekday } from "../../../../enum";
import { useGetSession } from "../../fetch/session";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";
import SchoolSelect from "../school/SchoolSelect";

const SessionsSelectDialog = (props: any) => {
  const { userState } = useUserStateContext();
  const { sessions, getSessions } = useGetSession();

  useEffect(() => {
    getSessions({});
  }, [userState, props.history]);

  useEffect(() => {
    sessions.forEach((session: any) => {
      return props.sessions
        .map((sessionTemp: any) => {
          return sessionTemp.id;
        })
        .includes(session.id)
        ? (session.tableData = { checked: true })
        : session;
    });
  }, [props.open]);

  const [columnObject] = useState<object[]>([
    { title: "School ID", field: "course.school_id" },
    { title: "Term", field: "course.term" },
    { title: "Course Name", field: "course.name" },
    { title: "Session Name", field: "name" },
    // { title: "Session ID", field: "id" },
    // {
    //   title: "Room",
    //   field: "room_id",
    //   render: (rowData: any) => {
    //     const matchRooms = rooms.filter((room: any) => {
    //       return rowData.room_id === room.id;
    //     });
    //     return matchRooms.length === 1 && matchRooms[0].name;
    //   }
    // },
    {
      title: "Start at",
      field: "start_at",
      type: "datetime",
      defaultSort: "asc",
      render: (rowData: any) => {
        return formatDateTime(new Date(rowData.start_at));
      }
    },
    {
      title: "End at",
      field: "end_at",
      type: "datetime",
      render: (rowData: any) => {
        return formatDateTime(new Date(rowData.end_at));
      }
    },
    {
      title: "Course Price Weight",
      field: "course.price_weight",
      type: "numeric"
    }
  ]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Session
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <main>
            <div>
              <MaterialTable
                columns={columnObject}
                data={sessions && sessions}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa"
                  },
                  showTitle: false,
                  pageSize: 5000,
                  pageSizeOptions: [10, 20, 40, 5000],
                  selection: true,
                  searchFieldAlignment: "left",
                  emptyRowsWhenPaging: false,
                  filtering: true
                }}
                onSelectionChange={rows => {
                  props.setSessions(rows);
                }}
                components={{
                  Container: Fragment
                }}
              />
            </div>
          </main>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

SessionsSelectDialog.propTypes = {
  sessions: PropTypes.array.isRequired,
  setSessions: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default SessionsSelectDialog;
