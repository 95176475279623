import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetSchool = (): any => {
  const { execute } = useFetch("GET");
  const [schools, setSchools] = React.useState([]);
  const [getSchoolsError, setGetSchoolsError] = React.useState(null);

  const getSchools = async () => {
    try {
      const fetchedSchools = await execute({
        url: getUrl() + `v1/a/school`,
      });
      setSchools(fetchedSchools);
    } catch (error) {
      setGetSchoolsError(error);
    }
  };

  return { schools, getSchools, getSchoolsError };
};

export const usePostSchool = () => {
  const { execute } = useFetch("POST");

  const postSchool = async ({ body }: { body: object }) => {
    try {
      const newSchool = await execute({
        url: getUrl() + `v1/a/school`,
        body: body,
      });
      return newSchool;
    } catch (error) {
      alert(error);
    }
  };

  return { postSchool };
};

export const usePutSchool = () => {
  const { execute } = useFetch("PUT");

  const putSchool = async ({
    schoolId,
    body,
  }: {
    schoolId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/school/${schoolId}`,
        body: body,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putSchool };
};

// const useDeleteSchool
