import { Field, FieldArray, Form, Formik } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import {
  Button,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { intToWeekday } from "../../../../enum";
import { IItem, IQuestionnaire, ITemplate } from "../../../../interface";
import { stylesForm } from "../../css";
import {
  useGetQuestionnaire,
  usePostQuestionnaire,
  usePutQuestionnaire,
} from "../../fetch/questionnaire";
import { useGetTemplate } from "../../fetch/template";
import CoursesSelectDialog from "../course/CoursesSelectDialog";
import SessionsSelectDialog from "../course/SessionsSelectDialog";
import { formatDateTime } from "../../util";

const QuestionnaireForm = (props: any) => {
  const { classes } = props;
  const [questionnaire, setQuestionnaire] = useState<
    null | IQuestionnaire | any
  >(null);
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { postQuestionnaire } = usePostQuestionnaire();
  const { putQuestionnaire } = usePutQuestionnaire();
  const { templates, getTemplates } = useGetTemplate();
  const [courses, setCourses] = useState<any>([]);
  const [coursesAdding, setCoursesAdding] = useState<any[]>([]);
  const [courseSelectionOpen, setCourseSelectionOpen] = useState(false);
  const [sessions, setSessions] = useState<any>([]);
  const [sessionsAdding, setSessionsAdding] = useState<any>([]);
  const [sessionSelectionOpen, setSessionSelectionOpen] = useState(false);

  const QuestionnaireSchema = Yup.object().shape({
    title: Yup.string(),
    description: Yup.string().required(),
    items: Yup.array().of(
      Yup.object({
        name: Yup.string().required(),
        type: Yup.mixed().oneOf([
          "text",
          "textarea",
          "select",
          "radio",
          "checkbox",
          // "range"
        ]),
        description: Yup.string(),
        values: Yup.array().of(Yup.string()),
        // .required(),
        required: Yup.bool().required(),
      }).required()
    ),
    email_template_id: Yup.string().nullable(),
    course_ids: Yup.array().of(Yup.string()),
    // .required(),
    session_ids: Yup.array().of(Yup.string()),
    // .required()
  });

  useEffect(() => {
    getQuestionnaires({});
    getTemplates({});
  }, []);

  useEffect(() => {
    const matchQuestionnaires = questionnaires.filter(
      (questionnaire: IQuestionnaire) => {
        return questionnaire.id === props.match.params.questionnaireId;
      }
    );
    matchQuestionnaires.length > 0 && setQuestionnaire(matchQuestionnaires[0]);
  }, [questionnaires]);

  useEffect(() => {
    if (!courseSelectionOpen && coursesAdding.length > 0) {
      setCourses([...coursesAdding, ...courses]);
      setCoursesAdding([]);
    }
  }, [courseSelectionOpen]);

  useEffect(() => {
    if (!sessionSelectionOpen && sessionsAdding.length > 0) {
      setSessions([...sessionsAdding, ...sessions]);
      setSessionsAdding([]);
    }
  }, [sessionSelectionOpen]);

  useEffect(() => {
    setCourses(
      questionnaire
        ? questionnaire?.courses.map((course: any) => {
            course.id = course._id;
            return course;
          })
        : []
    );
    setSessions(questionnaire?.sessions ?? []);
  }, [questionnaire]);

  return (
    <Formik
      initialValues={
        questionnaire
          ? {
              title: questionnaire.title ? questionnaire.title : "",
              description: questionnaire.description
                ? questionnaire.description
                : "",
              items: questionnaire.items
                ? questionnaire.items
                : [
                    {
                      name: "",
                      type: "text",
                      description: "",
                      values: [],
                      required: false,
                    },
                  ],
              email_template_id: questionnaire?.email_template_id,
              course_ids: questionnaire?.course_ids,
              session_ids: questionnaire?.session_ids,
            }
          : {
              title: "",
              description: "",
              items: [
                {
                  name: "",
                  type: "text",
                  description: "",
                  values: [],
                  required: false,
                },
              ],
              email_template_id: null,
              course_ids: [],
              session_ids: [],
            }
      }
      enableReinitialize
      validationSchema={QuestionnaireSchema}
      onSubmit={async (value) => {
        value.course_ids = courses.map((course: any) => {
          return course.id;
        });
        value.session_ids = sessions.map((session: any) => {
          return session.id;
        });
        questionnaire
          ? await putQuestionnaire({
              questionnaireId: questionnaire?.id,
              body: value,
            })
          : await postQuestionnaire({ body: value });
        props.history.push("/questionnaire");
      }}
      render={({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Fragment>
          <Form>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Field
                  margin="dense"
                  name="title"
                  type="text"
                  label="Title"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  margin="dense"
                  name="description"
                  type="text"
                  label="Description"
                  component={TextField}
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.mt3} variant="h6">
                Items
              </Typography>
            </Grid>
            <Grid
              container
              xs={12}
              spacing={2}
              alignItems="center"
              className={classes.ml3}
            >
              <FieldArray
                name="items"
                render={(arrayHelpers: any) =>
                  values.items?.map((item: IItem, index: any) => (
                    <Fragment key={index}>
                      <Grid item xs={5}>
                        <Field
                          margin="dense"
                          name={`items.${index}.name`}
                          type="text"
                          label="Name"
                          component={TextField}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Field
                          margin="dense"
                          type="text"
                          name={`items.${index}.type`}
                          label="Type"
                          select
                          // margin="normal"
                          component={TextField}
                          fullWidth
                        >
                          {[
                            "text",
                            "textarea",
                            "select",
                            "radio",
                            "checkbox",
                            // "range"
                          ].map((type: string) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          margin="dense"
                          name={`items.${index}.values`}
                          type="text"
                          label="Values（input with comma e.g. 'apple,banana,orange'）"
                          component={TextField}
                          fullWidth
                          disabled={
                            values.items[index].type != "select" &&
                            values.items[index].type != "radio" &&
                            values.items[index].type != "checkbox"
                          }
                          InputProps={{
                            onBlur: (e: any) => {
                              setFieldValue(
                                `items.${index}.values`,
                                e.target.value.match(/".*"|[^,"\s]+/g)
                              );
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Field
                          margin="dense"
                          // type="bool"
                          name={`items.${index}.required`}
                          component={CheckboxWithLabel}
                          Label={{ label: "Required" }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Field
                          margin="dense"
                          name={`items.${index}.description`}
                          type="text"
                          label="Description"
                          component={TextField}
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid container xs={2} justify="space-evenly">
                        <Button
                          color="primary"
                          onClick={() => {
                            let newItems = values.items;
                            newItems.splice(index + 1, 0, {
                              name: "",
                              type: "text",
                              description: "",
                              values: [],
                              required: false,
                            });
                            setFieldValue("items", newItems);
                          }}
                        >
                          ADD
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => {
                            setFieldValue(
                              "items",
                              values.items.filter((item: any, i: number) => {
                                return i !== index;
                              })
                            );
                          }}
                        >
                          REMOVE
                        </Button>
                      </Grid>
                    </Fragment>
                  ))
                }
              />
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={12} sm={6} className={classes.mt2}>
                <Field
                  margin="dense"
                  name="email_template_id"
                  type="text"
                  label="Email Template"
                  select
                  component={TextField}
                  fullWidth
                >
                  <MenuItem value={undefined}>No Email Template</MenuItem>
                  {templates.map((template: ITemplate) => (
                    <MenuItem key={template.id} value={template.id}>
                      {`${template.title}`}
                    </MenuItem>
                  ))}
                </Field>
                {values.email_template_id && (
                  <Typography variant="caption">
                    {`Selected Email Template ID is  ${values.email_template_id} （not shown because of a bug）`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={3} className={classes.mt2}>
                <Link href={"../template"} target="_blank" rel="noopener">
                  <Typography style={{ marginTop: "24px", marginLeft: "24px" }}>
                    TEMPLATES
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid container justify="space-between" alignItems="flex-end">
              <Grid className={classes.mt2}>
                <Typography variant="h6" className={classes.tableTitle}>
                  Courses Linked
                </Typography>
              </Grid>
              <Grid>
                <Button
                  color="primary"
                  onClick={() => {
                    setCourseSelectionOpen(true);
                  }}
                >
                  Select Courses
                </Button>
              </Grid>
            </Grid>
            <div className={classes.table}>
              <MaterialTable
                columns={[
                  { title: "Name", field: "name" },
                  { title: "Code", field: "code" },
                  { title: "Term", field: "term" },
                  {
                    title: "Weekday",
                    render: (rowData: any) => {
                      return intToWeekday[Number(rowData.weekday)];
                    },
                  },
                  {
                    title: "Categories",
                    render: (rowData: any) => {
                      return rowData.categories.join(", ");
                    },
                  },
                  { title: "Capacity", field: "capacity", type: "numeric" },
                  {
                    title: "Price Weight",
                    field: "price_weight",
                    type: "currency",
                    currencySetting: {
                      // locale: "ja",
                      currencyCode: "JPY",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    },
                  },
                ]}
                data={courses && courses}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa",
                  },
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 40],
                  search: false,
                  actionsColumnIndex: 99,
                  showTextRowsSelected: false,
                  toolbar: false,
                  emptyRowsWhenPaging: false,
                }}
                components={{
                  Toolbar: (props) => (
                    <MTableToolbar
                      {...props}
                      classes={{ root: classes.tableTitle }}
                    />
                  ),
                  Container: Fragment,
                }}
                title="Courses Linked"
                actions={[
                  {
                    icon: "add",
                    tooltip: "Add Course",
                    isFreeAction: true,
                    onClick: (event) => {
                      setCourseSelectionOpen(true);
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Remove",
                    onClick: (event, rowData: any) => {
                      setCourses(
                        courses.filter((item: any) => item !== rowData)
                      );
                    },
                  },
                ]}
              />
            </div>

            <Grid container justify="space-between" alignItems="flex-end">
              <Grid className={classes.mt2}>
                <Typography variant="h6" className={classes.tableTitle}>
                  Sessions Linked
                </Typography>
              </Grid>
              <Grid>
                <Button
                  color="primary"
                  onClick={() => {
                    setSessionSelectionOpen(true);
                  }}
                >
                  Select Sessions
                </Button>
              </Grid>
            </Grid>
            <div className={classes.table}>
              <MaterialTable
                columns={[
                  { title: "ID", field: "id" },
                  { title: "Name", field: "name" },
                  { title: "Description", field: "description" },
                  // {
                  //   title: "Room",
                  //   field: "room_id",
                  //   render: (rowData: any) => {
                  //     const matchRooms = rooms.filter((room: any) => {
                  //       return rowData.room_id === room.id;
                  //     });
                  //     return matchRooms.length === 1 && matchRooms[0].name;
                  //   }
                  // },
                  {
                    title: "Start at",
                    field: "start_at",
                    type: "datetime",
                    defaultSort: "asc",
                    render: (rowData: any) =>
                      formatDateTime(new Date(rowData.start_at)),
                  },
                  {
                    title: "End at",
                    field: "end_at",
                    type: "datetime",
                    render: (rowData: any) =>
                      formatDateTime(new Date(rowData.start_at)),
                  },
                  {
                    title: "Price Weight",
                    field: "price_weight",
                    type: "numeric",
                  },
                ]}
                data={sessions && sessions}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa",
                  },
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 40],
                  search: false,
                  actionsColumnIndex: 99,
                  showTextRowsSelected: false,
                  toolbar: false,
                  emptyRowsWhenPaging: false,
                }}
                components={{
                  Toolbar: (props) => (
                    <MTableToolbar
                      {...props}
                      classes={{ root: classes.tableTitle }}
                    />
                  ),
                  Container: Fragment,
                }}
                title="Sessions Linked"
                actions={[
                  {
                    icon: "add",
                    tooltip: "Add Session",
                    isFreeAction: true,
                    onClick: (event) => {
                      setSessionSelectionOpen(true);
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Remove",
                    onClick: (event, rowData: any) => {
                      setSessions(
                        sessions.filter((item: any) => item !== rowData)
                      );
                    },
                  },
                ]}
              />
            </div>

            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>

          <CoursesSelectDialog
            courses={coursesAdding}
            setCourses={setCoursesAdding}
            open={courseSelectionOpen}
            handleClose={() => {
              setCourseSelectionOpen(false);
            }}
          />
          <SessionsSelectDialog
            sessions={sessionsAdding}
            setSessions={setSessionsAdding}
            open={sessionSelectionOpen}
            handleClose={() => {
              setSessionSelectionOpen(false);
            }}
          />
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(QuestionnaireForm));
