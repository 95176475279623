import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as Yup from "yup";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

import { usePostPayment } from "../../fetch/payment";
import { usePutOrder } from "../../fetch/order";

const PaymentSchema = Yup.object().shape({
  paid_at: Yup.date().required(),
  amount: Yup.number().required(),
  note: Yup.string().nullable()
});

const PaymentDialog = (props: any) => {
  const { postPayment } = usePostPayment();
  const { putOrder } = usePutOrder();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        paid_at: null,
        amount: props.selectedOrder ? props.selectedOrder.unpaid_amount : 0,
        note: undefined
      }}
      validationSchema={PaymentSchema}
      onSubmit={async (values, { resetForm }) => {
        await postPayment({
          orderId: props.selectedOrder.id,
          body: values
        });

        // post payment and company then receipt gets available
        if (
          props.selectedOrder.prepayment === false &&
          props.selectedOrder.oneself === false &&
          values.amount >= props.selectedOrder.unpaid_amount
        ) {
          let confirmed = window.confirm(
            `Amount is fully paid. This order is Postpayment / Company. Normally, Receipt should turn into downloadable (Name, Postcode and Address being editable for the first time.) Confirm allowing the student to download Receipt? \n全額の支払いが確認されました。この注文は 後払い/法人 です。通常、領収書を受講生個人がダウンロード可能にします（初回のみ宛名・郵便番号・住所を編集可能）が、特別な対応が必要になる場合、ダウンロードは不可のままとします。受講生個人が領収書をダウンロードをできるようにしますか?`
          );
          if (confirmed) {
            await putOrder({
              orderId: props.selectedOrder.id,
              body: { receipt: { name: null, postcode: null, address: null } }
            });
          }
        }

        props.getOrders({});
        resetForm();
      }}
      render={({ submitForm, isValid, values, setFieldValue }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Record Payment</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid item xs={12} md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="paid_at"
                    label="Paid at"
                    value={values.paid_at}
                    onChange={(date: any) => setFieldValue("paid_at", date)}
                    format="yyyy/MM/dd"
                    fullWidth
                    defaultValue="null"
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={12}>
                <Field
                  // autoFocus
                  margin="dense"
                  name="amount"
                  label="Amount"
                  type="number"
                  fullWidth
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  margin="dense"
                  name="note"
                  label="Note"
                  type="text"
                  fullWidth
                  component={TextField}
                  multiline
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="default">
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                onClick={() => {
                  submitForm();
                  props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

PaymentDialog.propTypes = {
  payments: PropTypes.array.isRequired,
  setPayments: PropTypes.func.isRequired,
  selectedOrder: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getOrders: PropTypes.func
};

export default PaymentDialog;
