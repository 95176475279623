import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { stylesMain } from "../../css";
import {
  useGetProduct,
  usePostProduct,
  usePutProduct,
} from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";
import ProductFreeCreateDialog from "./ProductFreeCreateDialog";
import ProductFreeSelectDialog from "./ProductFreeSelectDialog";
import ProductIkuseiCreateDialog from "./ProductIkuseiCreateDialog";
import ProductJunbiCreateDialog from "./ProductJunbiCreateDialog";
import ProductWeatherCreateDialog from "./ProductWeatherCreateDialog";
import ProductHRCreateDialog from "./ProductHRCreateDialog";
import ProductPDACreateDialog from "./ProductPDACreateDialog";
import ProductDataEngCreateDialog from "./ProductDataEngCreateDialog";
import ProductAIMLEngCreateDialog from "./ProductAIMLEngCreateDialog";
import ProductDSIkuseiCreateDialog from "./ProductDSIkuseiCreateDialog";
import ProductDSWeatherCreateDialog from "./ProductDSWeatherCreateDialog";

const Product = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { products, getProducts } = useGetProduct();
  const { postProduct } = usePostProduct();
  const { putProduct } = usePutProduct();

  const [productJunbiCreateModalOpen, setProductJunbiCreateModalOpen] =
    useState(false);
  const [productIkuseiCreateModalOpen, setProductIkuseiCreateModalOpen] =
    useState(false);
  const [productFreeCreateModalOpen, setProductFreeCreateModalOpen] =
    useState(false);
  const [selectedFreeProduct, setSelectedFreeProduct] = useState<any>(null);
  const [productFreeSelectModalOpen, setProductFreeSelectModalOpen] =
    useState(false);
  const [productWeatherCreateModalOpen, setProductWeatherCreateModalOpen] =
    useState(false);
  const [productHRCreateModalOpen, setProductHRCreateModalOpen] =
    useState(false);
  const [productPDACreateModalOpen, setProductPDACreateModalOpen] =
    useState(false);
  const [productDataEngCreateModalOpen, setProductDataEngCreateModalOpen] =
    useState(false);
  const [productAIMLEngCreateModalOpen, setProductAIMLEngCreateModalOpen] =
    useState(false);
  const [productDSIkuseiCreateModalOpen, setProductDSIkuseiCreateModalOpen] =
    useState(false);
  const [ProductDSWeatherCreateModalOpen, setProductDSWeatherCreateModalOpen] =
    useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getProducts({});
  }, []);

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
  }, [
    userState,
    productJunbiCreateModalOpen,
    productIkuseiCreateModalOpen,
    productFreeCreateModalOpen,
    productWeatherCreateModalOpen,
    productHRCreateModalOpen,
    productPDACreateModalOpen,
    productDataEngCreateModalOpen,
    productAIMLEngCreateModalOpen,
    productDSIkuseiCreateModalOpen,
    ProductDSWeatherCreateModalOpen,
  ]);

  // This is to isolate the status of columns (especially filters) so that the
  // filtering to be kept even after the data of Material Table being refreshed.
  const [columnObject] = useState<object[]>([
    { title: "Product ID", field: "id" },
    { title: "Name", field: "name", filtering: true },
    { title: "Code", field: "code", filtering: true },
    {
      title: "Courses",
      render: (rowData: any) => {
        const course_names = rowData.courses.map((course: any) => {
          return course.name;
        });
        return course_names.join(", ");
      },
      customFilterAndSearch: (value: any, rowData: any) => {
        return rowData.courses
          .map((course: any) => {
            return course.name;
          })
          .join(", ")
          .includes(value);
      },
    },
    {
      title: "Price",
      field: "price",
      type: "currency",
      currencySetting: {
        // locale: "ja",
        currencyCode: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      filtering: true,
      customFilterAndSearch: (value: any, rowData: any) => {
        return value.includes(">")
          ? rowData.price > Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : value.includes("<")
          ? rowData.price < Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : rowData.price === Number(value)
          ? true
          : false;
      },
    },
    {
      title: "Open at",
      field: "open_at",
      type: "datetime",
      render: (rowData: any) => {
        return formatDateTime(new Date(rowData.open_at));
      },
      filtering: false,
    },
    {
      title: "Close at",
      field: "close_at",
      type: "datetime",
      render: (rowData: any) => {
        return formatDateTime(new Date(rowData.close_at));
      },
      filtering: false,
    },
    {
      title: "Tags",
      field: "tags",
      render: (rowData: any) => {
        const tag_names = rowData.tags
          ? rowData.tags.map((tag: any) => {
              return tag;
            })
          : "";
        return tag_names.join(", ");
      },
      filtering: true,
    },
    {
      title: "Hidden",
      field: "hidden",
      type: "boolean",
      filtering: true,
    },
    {
      title: "Email Template ID",
      field: "email_template_id",
    },
  ]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Product
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                component={Link}
                to="/product/create"
                className={classes.createButton}
              >
                Create
              </Button>

              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                className={classes.createProductsMoreVert}
              >
                Create Products
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuItem
                  onClick={() => {
                    setProductFreeSelectModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create Free Products From Existing
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductFreeCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create Free Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductJunbiCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create Junbi Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductIkuseiCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create Ikusei Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductWeatherCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create Weather Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductHRCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create HR Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductPDACreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create PDA Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductDataEngCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create DataEngineer Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductAIMLEngCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create AIMLEngineer Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductDSIkuseiCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create DSIkusei Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setProductDSWeatherCreateModalOpen(true);
                    handleMenuClose();
                  }}
                >
                  Create DSWeather Products
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={products && products}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 40,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Product List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
            }}
            components={{
              Container: Fragment,
            }}
            editable={{
              onRowUpdate: (newProduct: any, oldProduct) =>
                new Promise(async (resolve, reject) => {
                  delete newProduct.courses;
                  const productId = newProduct.id;
                  delete newProduct.id;
                  newProduct.price === ""
                    ? (newProduct.price = null)
                    : newProduct.price === "0"
                    ? (newProduct.price = 0)
                    : (newProduct.price = Number(newProduct.price));

                  if (typeof newProduct.tags === "string") {
                    newProduct.tags = newProduct.tags.split(",");
                  }
                  if (newProduct.tags === "") {
                    newProduct.tags = [];
                  }
                  newProduct.email_template_id =
                    newProduct.email_template_id === ""
                      ? null
                      : newProduct.email_template_id;
                  await putProduct({
                    productId: productId,
                    body: newProduct,
                  });
                  getProducts({});
                  resolve();
                }),
            }}
            actions={[
              {
                icon: "open_in_new",
                tooltip: "Detail in New Tab",
                onClick: (event, rowData) => {
                  window.open(`/product/${rowData.id}`);
                },
              },
              {
                icon: "file_copy",
                tooltip: "Copy Product",
                onClick: async (event, rowData) => {
                  let confirmed = window.confirm(
                    "Are you sure to copy the product? Courses, Open at and Close at need to be modified afterwords."
                  );
                  if (confirmed) {
                    let product = rowData;
                    delete product.id;
                    delete product.courses;
                    delete product.tableData;
                    product.name = `${product.name}_copy`;
                    product.open_at = new Date();
                    product.close_at = new Date();
                    product.course_ids = [];
                    await postProduct({ body: product });

                    getProducts({});
                  }
                },
              },
            ]}
            // localization={{
            //   header: { actions: "" }
            // }}
            onRowClick={(event, rowData: any) => {
              rowData &&
                props.history.push({
                  pathname: `/product/${rowData.id}`,
                });
            }}
          />
        </div>
      </main>
      <ProductFreeCreateDialog
        open={productFreeCreateModalOpen}
        handleClose={() => setProductFreeCreateModalOpen(false)}
        selectedFreeProduct={selectedFreeProduct}
      />
      <ProductFreeSelectDialog
        setProduct={setSelectedFreeProduct}
        open={productFreeSelectModalOpen}
        setProductFreeCreateModalOpen={setProductFreeCreateModalOpen}
        handleClose={() => setProductFreeSelectModalOpen(false)}
      />
      <ProductJunbiCreateDialog
        open={productJunbiCreateModalOpen}
        handleClose={() => setProductJunbiCreateModalOpen(false)}
      />
      <ProductIkuseiCreateDialog
        open={productIkuseiCreateModalOpen}
        handleClose={() => setProductIkuseiCreateModalOpen(false)}
      />
      <ProductWeatherCreateDialog
        open={productWeatherCreateModalOpen}
        handleClose={() => setProductWeatherCreateModalOpen(false)}
      />
      <ProductHRCreateDialog
        open={productHRCreateModalOpen}
        handleClose={() => setProductHRCreateModalOpen(false)}
      />
      <ProductPDACreateDialog
        open={productPDACreateModalOpen}
        handleClose={() => setProductPDACreateModalOpen(false)}
      />
      <ProductDataEngCreateDialog
        open={productDataEngCreateModalOpen}
        handleClose={() => setProductDataEngCreateModalOpen(false)}
      />
      <ProductAIMLEngCreateDialog
        open={productAIMLEngCreateModalOpen}
        handleClose={() => setProductAIMLEngCreateModalOpen(false)}
      />
      <ProductDSIkuseiCreateDialog
        open={productDSIkuseiCreateModalOpen}
        handleClose={() => setProductDSIkuseiCreateModalOpen(false)}
      />
      <ProductDSWeatherCreateDialog
        open={ProductDSWeatherCreateModalOpen}
        handleClose={() => setProductDSWeatherCreateModalOpen(false)}
      />
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Product));
