import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from "@material-ui/core";

const RoomSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .required(),
  capacity: Yup.number().min(0, "Capacity must be bigger than 0")
  // .positive()
});

const RoomCreateDialog = (props: any) => {
  return (
    <Formik
      enableReinitialize
      initialValues={
        props.selectedRoom
          ? {
              name: props.selectedRoom.name,
              capacity: props.selectedRoom.capacity
            }
          : {
              name: "",
              capacity: 0
            }
      }
      validationSchema={RoomSchema}
      onSubmit={(values, { resetForm }) => {
        // handling 2 cases. 1: add new room / 2: modify one room in rooms
        // 2 required the particular room to be filtered then modified.
        if (props.selectedRoom) {
          const selectedRoomNumber = props.selectedRoom.tableData.id;
          const newRooms = props.rooms.map((item: any) => {
            if (item.tableData.id === selectedRoomNumber) {
              let newRoom = item;
              newRoom.name = values.name;
              newRoom.capacity = values.capacity;
              return newRoom;
            } else {
              return item;
            }
          });
          props.setRooms(newRooms);
        } else {
          props.setRooms([values, ...props.rooms]);
        }
        resetForm();
      }}
      render={({ submitForm, isValid, values }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Room</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Field
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field
                    margin="dense"
                    name="capacity"
                    label="Capacity"
                    type="number"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="default">
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                onClick={() => {
                  submitForm();
                  props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

RoomCreateDialog.propTypes = {
  rooms: PropTypes.array.isRequired,
  setRooms: PropTypes.func.isRequired,
  selectedRoom: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default RoomCreateDialog;
