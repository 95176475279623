import * as Yup from "yup";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  DateTimePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Field, Form, Formik } from "formik";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import {
  useGetProduct,
  usePostProduct,
  usePutProduct,
} from "../../fetch/product";

import ChipInput from "material-ui-chip-input";
import CoursesSelectDialog from "../course/CoursesSelectDialog";
import DateFnsUtils from "@date-io/date-fns";
import { ITemplate } from "../../../../interface";
import { TextField } from "formik-material-ui";
import { intToWeekday } from "../../../../enum";
import { stylesForm } from "../../css";
import { useGetTemplate } from "../../fetch/template";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/styles";

const ProductForm = (props: any) => {
  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState<any>(null);
  const [courses, setCourses] = useState<any>([]);
  const { products, getProducts } = useGetProduct();
  const { postProduct } = usePostProduct();
  const { putProduct } = usePutProduct();
  const { templates, getTemplates } = useGetTemplate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ProductSchema = Yup.object().shape({
    name: Yup.string().min(1).required(),
    description: Yup.string(),
    code: Yup.string(),
    price: Yup.number().min(0).required(),
    open_at: Yup.date().required(),
    close_at: Yup.date().required(),
    email_template_id: Yup.string().required(),
  });

  useEffect(() => {
    props.match.params.productId &&
      getProducts({ productId: props.match.params.productId });
    getTemplates({});
  }, []);

  useEffect(() => {
    setProduct(
      products.filter((product: any) => {
        return product.id === props.match.params.productId;
      })[0]
    );
  }, [products]);

  useEffect(() => {
    product && setCourses(product?.courses);
  }, [product]);

  return (
    <Formik
      initialValues={
        props.match.params.productId
          ? {
              name: product?.name,
              description: product?.description,
              code: product?.code,
              price: product?.price,
              open_at: product?.open_at,
              close_at: product?.close_at,
              hidden: product?.hidden,
              course_ids: product?.course_ids,
              tags: product?.tags,
              application_conditions: JSON.stringify(
                product?.application_conditions
              ),
              email_template_id: product?.email_template_id,
            }
          : {
              name: "",
              description: undefined,
              code: "",
              price: null,
              open_at: null,
              close_at: null,
              hidden: false,
              course_ids: [],
              tags: [],
              application_conditions: "[]",
              email_template_id: null,
            }
      }
      enableReinitialize
      validationSchema={ProductSchema}
      onSubmit={async (values) => {
        values.course_ids = courses.map((course: any) =>
          course.id ? course.id : course._id
        );
        values.application_conditions = JSON.parse(
          values.application_conditions
        );
        values.email_template_id =
          values.email_template_id === "" ? null : values.email_template_id;
        product
          ? await putProduct({ productId: product.id, body: values })
          : await postProduct({ body: values });
        props.history.replace("/product");
      }}
      render={({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
        <Fragment>
          <Form>
            <Grid container xs={12} sm={6}>
              <Field
                margin="dense"
                name="name"
                type="text"
                label="Name"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid container xs={12} sm={12}>
              <Field
                margin="dense"
                name="description"
                type="text"
                label="Description"
                component={TextField}
                fullWidth
                multiline
              />
            </Grid>
            <Grid container xs={12} sm={3}>
              <Field
                margin="dense"
                name="code"
                type="text"
                label="Code"
                component={TextField}
                fullWidth
              />
            </Grid>

            <Grid container justify="space-between" alignItems="flex-end">
              <Grid>
                <Typography variant="h6" className={classes.tableTitle}>
                  Courses
                </Typography>
              </Grid>
              <Grid>
                <Button color="primary" onClick={handleClickOpen}>
                  Select Course
                </Button>
              </Grid>
            </Grid>
            {/* <Table className={classes.table}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Term</TableCell>
                  <TableCell>Weekday</TableCell>
                  <TableCell>Capacity</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courses &&
                  courses.map(
                    (course: {
                      name: React.ReactNode;
                      code: React.ReactNode;
                      term: React.ReactNode;
                      weekday: React.ReactNode;
                      capacity: React.ReactNode;
                      price_weight: React.ReactNode;
                    }) => (
                      <TableRow>
                        <TableCell component="th" scope="course">
                          {course.name}
                        </TableCell>
                        <TableCell>{course.code}</TableCell>
                        <TableCell>{course.term}</TableCell>
                        <TableCell>{course.weekday}</TableCell>
                        <TableCell>{course.capacity}</TableCell>
                        <TableCell>{course.price_weight}</TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table> */}

            <div className={classes.table}>
              <MaterialTable
                columns={[
                  { title: "Name", field: "name" },
                  { title: "Code", field: "code" },
                  { title: "Term", field: "term" },
                  {
                    title: "Weekday",
                    render: (rowData: any) => {
                      return intToWeekday[Number(rowData.weekday)];
                    },
                  },
                  {
                    title: "Categories",
                    render: (rowData: any) => {
                      return rowData.categories.join(", ");
                    },
                  },
                  { title: "Capacity", field: "capacity", type: "numeric" },
                  {
                    title: "Price Weight",
                    field: "price_weight",
                    type: "currency",
                    currencySetting: {
                      // locale: "ja",
                      currencyCode: "JPY",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    },
                  },
                ]}
                data={courses && courses}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa",
                  },
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 20],
                  search: false,
                  actionsColumnIndex: 99,
                  showTextRowsSelected: false,
                  toolbar: false,
                  emptyRowsWhenPaging: false,
                }}
                components={{
                  Toolbar: (props) => (
                    <MTableToolbar
                      {...props}
                      classes={{ root: classes.tableTitle }}
                    />
                  ),
                  Container: Fragment,
                }}
                title="Courses"
                actions={[
                  {
                    icon: "add",
                    tooltip: "Add Course",
                    isFreeAction: true,
                    onClick: (event) => {
                      handleClickOpen();
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Remove",
                    onClick: (event, rowData: any) => {
                      setCourses(
                        courses.filter((item: any) => item !== rowData)
                      );
                    },
                  },
                ]}
              />
            </div>

            <Grid container xs={12} md={3}>
              <Field
                className={classes.mt2}
                margin="dense"
                name="price"
                label="Price"
                type="number"
                fullWidth
                component={TextField}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} className={classes.mt1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    name="open_at"
                    label="Open at"
                    value={values.open_at}
                    onChange={(date) => setFieldValue("open_at", date)}
                    format="yyyy/MM/dd HH:mm"
                    fullWidth
                    helperText="Required Field"
                  />
                  {/* <DateTimePicker
                    name="open_at"
                    label="Open at"
                    value={values.open_at}
                    onChange={date => setFieldValue("open_at", date)}
                    format="yyyy/MM/dd HH:mm"
                    fullWidth
                    helperText="Required Field"
                  /> */}
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={3} className={classes.mt1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    name="close_at"
                    label="Close at"
                    value={values.close_at}
                    onChange={(date) => setFieldValue("close_at", date)}
                    format="yyyy/MM/dd HH:mm"
                    fullWidth
                    helperText="Required Field"
                  />
                  {/* <DateTimePicker
                    name="close_at"
                    label="Close at"
                    value={values.close_at}
                    onChange={date => setFieldValue("close_at", date)}
                    format="yyyy/MM/dd HH:mm"
                    fullWidth
                    helperText="Required Field"
                  /> */}
                  {/* <KeyboardDateTimePicker
                    name="open_at"
                    label="Open at"
                    value={values.open_at}
                    onChange={date => setFieldValue("open_at", date)}
                    format="yyyy/MM/dd HH:mm"
                    fullWidth
                    errorText={errors.open_at}
                  /> */}
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container className={classes.mtb1}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="hidden"
                    control={<Switch color="primary" />}
                    label="Hidden"
                    labelPlacement="start"
                    checked={values.hidden}
                    onChange={(event: any) => {
                      setFieldValue("hidden", event.target.checked);
                    }}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid container xs={12} sm={12}>
              <Field
                className={classes.mt1}
                margin="dense"
                name="application_conditions"
                type="text"
                label="Application Conditions"
                component={TextField}
                fullWidth
                multiline
              />
            </Grid>

            <Grid container xs={12} sm={12} className={classes.mt1}>
              <Grid item xs={12} sm={12}>
                <ChipInput
                  // value={values.tags}
                  label="Tags"
                  defaultValue={values.tags}
                  onChange={(chips) => {
                    setFieldValue("tags", chips);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} sm={12}>
              <Grid item xs={12} sm={6} className={classes.mt2}>
                <Field
                  margin="dense"
                  type="text"
                  name="email_template_id"
                  label="Email Template"
                  select
                  // margin="normal"
                  component={TextField}
                  fullWidth
                >
                  {templates.map((template: ITemplate) => (
                    <MenuItem key={template.id} value={template.id}>
                      {`${template?.title}`}
                    </MenuItem>
                  ))}
                </Field>
                {values.email_template_id && (
                  <Typography variant="caption">
                    {`Selected Email Template is  ${
                      templates.filter(
                        (template: ITemplate) =>
                          template.id === values.email_template_id
                      )[0]?.title
                    } （not shown because of a bug）`}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={3} className={classes.mt2}>
                <Link href={"../template"} target="_blank" rel="noopener">
                  <Typography style={{ marginTop: "24px", marginLeft: "24px" }}>
                    TEMPLATES
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>
          <CoursesSelectDialog
            courses={product ? product.courses : []}
            setCourses={setCourses}
            open={open}
            handleClose={handleClose}
          />
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(ProductForm));
