import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  Theme
} from "@material-ui/core";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { ISchool } from "../../../../interface";
import { useGetSchool } from "../../fetch/school";
import useUserStateContext from "../../userstate";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    select: {
      paddingLeft: theme.spacing(1)
    }
  });

const SchoolSelect = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const { schools, getSchools } = useGetSchool();

  useEffect(() => {
    (async () => {
      getSchools({});
    })();
    return () => {
      // cleanup 処理
    };
  }, [userState, props.history]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    if (event.target.name !== undefined) {
      const selectedSchool: ISchool = schools.filter(
        (item: { id: unknown }) => item.id === event.target.value
      )[0];
      selectSchool(selectedSchool);
    }
  };

  return (
    <FormControl>
      {props.disabled ? (
        <Select
          value={userState.selectedSchool?.id}
          onChange={handleChange}
          displayEmpty
          name="schools"
          className={classes.select}
          disabled
        >
          {/* <MenuItem>All Schools</MenuItem>; */}
          {schools &&
            schools.map((item: ISchool) => {
              if (item.id !== null) {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              }
            })}
        </Select>
      ) : (
        <Select
          value={userState.selectedSchool?.id}
          onChange={handleChange}
          displayEmpty
          name="schools"
          className={classes.select}
        >
          {/* <MenuItem>All Schools</MenuItem>; */}
          {schools &&
            schools.map((item: ISchool) => {
              if (item.id !== null) {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              }
            })}
        </Select>
      )}
    </FormControl>
  );
};

SchoolSelect.propTypes = {
  disabled: PropTypes.bool
};

export default withStyles(styles)(SchoolSelect);
