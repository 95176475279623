import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesMain } from "../../css";
import {
  useGetQuestionnaireAnswer,
  useGetQuestionnaire,
} from "../../fetch/questionnaire";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";

const QuestionnaireAnswer = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const [questionnaire, setQuestionnaire] = useState<any>(null);
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { questionnaireAnswer, getQuestionnaireAnswer } =
    useGetQuestionnaireAnswer();

  const [columnObject] = useState<object[]>([
    { title: "ID", field: "id" },
    { title: "Course ID", field: "course.id" },
    { title: "Course Name", field: "course.name" },
    { title: "Course Code", field: "course.code" },
    { title: "Course Term", field: "course.term" },
    { title: "Course School ID", field: "course.school.id" },
    { title: "Session ID", field: "session.id" },
    { title: "Session Name", field: "session.name" },
    { title: "Student Name", field: "student.full_name" },
    { title: "Student Name Kana", field: "student.full_name_kana" },
    { title: "Student E-mail", field: "student.email" },
  ]);

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    props.match.params.questionnaireId &&
      getQuestionnaireAnswer({
        questionnaireId: props.match.params.questionnaireId,
      });
    props.match.params.questionnaireId && getQuestionnaires({});
  }, []);

  useEffect(() => {
    questionnaires.length > 0 &&
      setQuestionnaire(
        questionnaires.filter((questionnaire: any) => {
          return questionnaire.id === props.match.params.questionnaireId;
        })[0]
      );
    questionnaires
      .filter((questionnaire: any) => {
        return questionnaire.id === props.match.params.questionnaireId;
      })[0]
      ?.items?.forEach((item: any) => {
        columnObject.push({
          title: item.name,
          field: `items.${item.name}`,
          render: (rowData: any) => {
            return rowData["items"][item.name]?.join(",");
          },
        });
      });
  }, [questionnaires]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                {`Questionnaire Answer - ${questionnaire?.title}`}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={questionnaireAnswer && questionnaireAnswer}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 20,
              pageSizeOptions: [20, 40, 60],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Questionnaire Answer List ${
                questionnaire?.title
              }_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
            }}
            components={{
              Container: Fragment,
            }}
          />
        </div>
      </main>
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(QuestionnaireAnswer));
