import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { useTransferSession } from "../../fetch/session";
import { formatDateTime } from "../../util";

const SessionSelectDialog = (props: any) => {
  const { transferSession } = useTransferSession();

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Session to Transfer
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MaterialTable
            columns={[
              { title: "Course", field: "course.name" },
              { title: "Name", field: "name" },
              { title: "Description", field: "description" },
              //   {
              //     title: "Room",
              //     field: "room_id"
              //   },
              {
                title: "Start at",
                field: "start_at",
                type: "datetime",
                // defaultSort: "asc",
                render: (rowData: any) => {
                  return formatDateTime(new Date(rowData.start_at));
                }
              },
              {
                title: "End at",
                field: "end_at",
                type: "datetime",
                render: (rowData: any) => {
                  return formatDateTime(new Date(rowData.end_at));
                }
              },
              //   {
              //     title: "Price Weight",
              //     field: "price_weight",
              //     type: "numeric"
              //   },
              { title: "Remainings", field: "remainings", type: "numeric" }
            ]}
            data={props.sessions && props.sessions}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa"
              },
              showTitle: false,
              pageSize: 5,
              pageSizeOptions: [5, 10, 20],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              toolbarButtonAlignment: "left"
              //   filtering: true
            }}
            components={{
              Container: Fragment
            }}
            onRowClick={async (event, rowData: any) => {
              console.log(props.student);
              let confirmed = window.confirm(
                `Are you sure to transfer session ${
                  props.fromSession.name
                } starting at ${formatDateTime(
                  new Date(props.fromSession.start_at)
                )} to session ${rowData.name} starting at ${formatDateTime(
                  new Date(rowData.start_at)
                )} for the student ${props.student.last_name} ${
                  props.student.first_name
                } ? System will not send E-mail. \n\n${
                  props.student.last_name
                } ${props.student.first_name} さんの出席予定の授業 ${
                  props.fromSession.name
                }（${formatDateTime(
                  new Date(props.fromSession.start_at)
                )} 開始）を ${rowData.name}（${formatDateTime(
                  new Date(rowData.start_at)
                )} 開始） に振替えます。よろしいですか? なお、E-mail が自動で送信されることはありません。`
              );
              if (confirmed) {
                await transferSession({
                  studentId: props.student.id,
                  fromSessionId: props.fromSession.id,
                  toSessionId: rowData.id
                });
              }
              props.handleClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

SessionSelectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
  fromSession: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired
};

export default SessionSelectDialog;
