import { Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { formatDate, formatDateTime } from "../../util";

import MaterialTable from "material-table";
import { stylesMain } from "../../css";
import { useGetStudents } from "../../fetch/student";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

const Student = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { students, getStudents } = useGetStudents();

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getStudents({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Student
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={[
              {
                title: "Student ID",
                field: "id",
              },
              {
                title: "Name",
                field: "full_name",
              },
              {
                title: "Name Kana",
                field: "full_name_kana",
              },

              { title: "E-mail", field: "email" },
              { title: "Gender", field: "gender" },
              {
                title: "Birth Date",
                field: "birth_date",
                type: "datetime",
                render: (rowData: any) => {
                  return (
                    rowData.birth_date &&
                    formatDate(new Date(rowData.birth_date))
                  );
                },
              },
              { title: "Tel Mobile Phone", field: "tel_mobile_phone" },
              { title: "Name Emergency", field: "name_emergency" },
              { title: "Tel Emergency", field: "tel_emergency" },
              { title: "Postcode", field: "postcode" },
              { title: "Address", field: "address" },
              { title: "Company Name", field: "company.name" },
              { title: "Company Tel", field: "company.tel" },
              { title: "Company Address", field: "company.address" },
            ]}
            data={students && students}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 40,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Student List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
            }}
            components={{
              Container: Fragment,
            }}
            onRowClick={(event, rowData: any) => {
              rowData &&
                props.history.push({
                  pathname: `/student/${rowData.id}`,
                  state: { student: rowData },
                });
            }}
          />
        </div>
      </main>
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Student));
