import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesMain } from "../../css";
import {
  useGetQuestionnaire,
  usePostQuestionnaire
} from "../../fetch/questionnaire";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";

const Questionnaire = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { postQuestionnaire } = usePostQuestionnaire();

  const [columnObject] = useState<object[]>([
    { title: "ID", field: "id" },
    { title: "Title", field: "title" },
    {
      title: "Description",
      field: "description",
      render: (rowData: any) => {
        return rowData.description.length > 100
          ? `${rowData.description.slice(0, 100)} ...`
          : rowData.description;
      }
    }
  ]);

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getQuestionnaires({});
  }, []);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Questionnaire
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/questionnaire/create"
                  className={classes.createButton}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={questionnaires && questionnaires}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa"
              },
              showTitle: false,
              pageSize: 5000,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Questionnaire List_${formatDateTime(
                new Date()
              )}`,
              toolbarButtonAlignment: "left",
              filtering: true
            }}
            components={{
              Container: Fragment
            }}
            onRowClick={(event, rowData: any) => {
              if (rowData) {
                props.history.push({
                  pathname: `/questionnaire/${rowData.id}`
                });
              }
            }}
            actions={[
              {
                icon: "people",
                tooltip: "Answers",
                onClick: async (event, rowData) => {
                  props.history.push(`./questionnaire/${rowData.id}/answer`);
                }
              },
              {
                icon: "file_copy",
                tooltip: "Copy Questionnaire",
                onClick: async (event, rowData) => {
                  let confirmed = window.confirm(
                    "Are you sure to copy the Questionnaire? Course and Session linkages need to be , Open at and Close at need to be modified afterwords."
                  );
                  if (confirmed) {
                    await postQuestionnaire({
                      body: {
                        title: `${rowData.title}_copy`,
                        description: rowData.description,
                        items: rowData.items,
                        email_template_id: rowData.email_template_id,
                        course_ids: [],
                        session_ids: []
                      }
                    });
                    getQuestionnaires({});
                  }
                }
              },
              {
                icon: "link",
                tooltip: "Copy Questionnaire Link To Clipboard",
                onClick: async (event, rowData: any) => {
                  var copyText = `${process.env.REACT_APP_STUDENT_SITE_URL}questionnaire/${rowData.id}`;
                  document.addEventListener("copy", function handler(
                    clipboardEvent
                  ) {
                    clipboardEvent.preventDefault();
                    clipboardEvent?.clipboardData?.setData(
                      "text/plain",
                      copyText
                    );
                    document.removeEventListener("copy", handler);
                  });
                  if (document.execCommand("copy")) {
                    alert(
                      "URL Copied To Clipboard!\n" +
                        copyText +
                        "\nPlease make sure that this link can not specify neither course nor session even if linked."
                    );
                  } else {
                    alert("Copy Failed ...\n" + copyText);
                  }
                }
              }
            ]}
          />
        </div>
      </main>
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Questionnaire));
