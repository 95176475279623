import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetRoom = (): any => {
  const { execute } = useFetch("GET");
  const [rooms, setRooms] = React.useState([]);
  const [getRoomsError, setGetRoomsError] = React.useState(null);

  const getRooms = async ({ schoolId }: { schoolId: string }) => {
    try {
      const fetchedRooms = await execute({
        url: getUrl() + `v1/a/school/${schoolId}/room`
      });
      setRooms(fetchedRooms);
    } catch (error) {
      setGetRoomsError(error);
    }
  };

  return { rooms, setRooms, getRooms, getRoomsError };
};

export const usePostRoom = (): any => {
  const { execute } = useFetch("POST");

  const postRoom = ({ schoolId, body }: { schoolId: string; body: object }) => {
    try {
      const newRoom = execute({
        url: getUrl() + `v1/a/school/${schoolId}/room`,
        body: body
      });
      return newRoom;
    } catch (error) {
      alert(error);
    }
  };

  return { postRoom };
};

export const usePutRoom = () => {
  const { execute } = useFetch("PUT");

  const putRoom = async ({
    roomId,
    body
  }: {
    roomId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/room/${roomId}`,
        body: body
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putRoom };
};

export const useDeleteRoom = () => {
  const { execute } = useFetch("DELETE");

  const deleteRoom = async ({ roomId }: { roomId: string }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/room/${roomId}`
      });
    } catch (error) {
      alert(error);
    }
  };

  return { deleteRoom };
};
