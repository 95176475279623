import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetStudents = (): any => {
  const { execute } = useFetch("GET");
  const [students, setStudents] = React.useState([]);
  const [getStudentsError, setGetStudentsError] = React.useState(null);

  const getStudents = async () => {
    try {
      const fetchedStudents = await execute({
        url: getUrl() + `v1/a/student`,
      });
      setStudents(fetchedStudents);
    } catch (error) {
      setGetStudentsError(error);
    }
  };

  return { students, getStudents, getStudentsError };
};

export const useGetStudent = (): any => {
  const { execute } = useFetch("GET");
  const [student, setStudent] = React.useState([]);
  const [getStudentError, setGetStudentError] = React.useState(null);

  const getStudent = async ({ studentId }: { studentId: string }) => {
    try {
      const fetchedStudent = await execute({
        url: getUrl() + `v1/a/student/${studentId}`,
      });
      setStudent(fetchedStudent);
    } catch (error) {
      setGetStudentError(error);
    }
  };

  return { student, getStudent, getStudentError };
};
