import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetCourses = (): any => {
  const { execute } = useFetch("GET");
  const [courses, setCourses] = React.useState([]);
  const [getCoursesError, setGetCoursesError] = React.useState(null);

  const getCourses = async ({ schoolId }: { schoolId?: string }) => {
    try {
      if (schoolId) {
        const fetchedCourses = await execute({
          url: getUrl() + `v1/a/course?school_id=${schoolId}`,
        });
        setCourses(fetchedCourses);
      } else {
        const fetchedCourses = await execute({
          url: getUrl() + `v1/a/course`,
        });
        setCourses(fetchedCourses);
      }
    } catch (error) {
      setGetCoursesError(error);
    }
  };

  return { courses, getCourses, setCourses, getCoursesError };
};

export const useGetCourse = (): any => {
  const { execute } = useFetch("GET");
  const [course, setCourse] = React.useState(undefined);
  const [getCourseError, setGetCourseError] = React.useState(null);

  const getCourse = async ({ courseId }: { courseId: string }) => {
    try {
      const fetchedCourse = await execute({
        url: getUrl() + `v1/a/course/${courseId}`,
      });
      setCourse(fetchedCourse);
    } catch (error) {
      setGetCourseError(error);
    }
  };

  const getCourseSync = async ({ courseId }: { courseId: string }) => {
    try {
      const course = await execute({
        url: getUrl() + `v1/a/course/${courseId}`,
      });
      return course;
    } catch (error) {
      alert(error);
    }
  };

  return { course, getCourse, getCourseError, getCourseSync };
};

export const useGetCoursesAdmission = (): any => {
  const { execute } = useFetch("GET");
  const [coursesAdmission, setCoursesAdmission] = React.useState([]);
  const [getCoursesError, setGetCoursesError] = React.useState(null);

  const getCoursesAdmission = async () => {
    try {
      let fetchedCourses = await execute({
        url: getUrl() + `v1/a/course`,
      });
      fetchedCourses = fetchedCourses.filter((course: any) => {
        return course.categories.includes("Admission");
      });
      setCoursesAdmission(fetchedCourses);
    } catch (error) {
      setGetCoursesError(error);
    }
  };

  return { coursesAdmission, getCoursesAdmission, getCoursesError };
};

export const usePostCourse = (): any => {
  const { execute } = useFetch("POST");

  const postCourse = ({
    schoolId,
    body,
  }: {
    schoolId: string;
    body: object;
  }) => {
    try {
      const newCourse = execute({
        url: getUrl() + `v1/a/school/${schoolId}/course`,
        body: body,
      });
      return newCourse;
    } catch (error) {
      alert(error);
    }
  };

  return { postCourse };
};

export const usePutCourse = () => {
  const { execute } = useFetch("PUT");

  const putCourse = async ({
    courseId,
    body,
  }: {
    courseId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/course/${courseId}`,
        body: body,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putCourse };
};

export const useTransferCourse = () => {
  const { execute } = useFetch("PUT");

  const transferCourse = async ({
    studentId,
    fromCourseId,
    toCourseId,
  }: {
    studentId: string;
    fromCourseId: string;
    toCourseId: string;
  }) => {
    try {
      await execute({
        url:
          getUrl() +
          `v1/a/student/${studentId}/course/${fromCourseId}/transfer/${toCourseId}`,
        body: undefined,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { transferCourse };
};

export const useCompleteCourseEnrollment = () => {
  const { execute } = useFetch("PUT");

  const completeCourseEnrollment = async ({
    studentId,
    courseId,
    body,
  }: {
    studentId: string;
    courseId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/student/${studentId}/course/${courseId}`,
        body: body,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { completeCourseEnrollment };
};

// const useDeleteCourse
