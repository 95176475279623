import * as Yup from "yup";

import {
  Button,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  TextField as PureTextField,
  Typography,
} from "@material-ui/core";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Field, Form, Formik } from "formik";
import { IQuestionnaire, ISchool } from "../../../../interface";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { intToWeekday, weekdayOptions } from "../../../../enum";
import { useGetCourse, usePostCourse, usePutCourse } from "../../fetch/course";
import {
  useGetQuestionnaire,
  usePutQuestionnaire,
} from "../../fetch/questionnaire";
import { usePostSession, usePutSession } from "../../fetch/session";

import QuestionnaireSelectDialog from "../questionnaire/QuesionnaireSelectDialog";
import SessionCreateDialog from "./SessionCreateDialog";
import { formatDateTime } from "../../util";
import { stylesForm } from "../../css";
import { useGetRoom } from "../../fetch/room";
import { useGetSchool } from "../../fetch/school";
import useUserStateContext from "../../userstate";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/styles";

const CourseForm = (props: any) => {
  const { classes } = props;
  const { schools, getSchools } = useGetSchool();
  const { userState, selectSchool } = useUserStateContext();
  const { course, getCourse } = useGetCourse();
  const { postCourse } = usePostCourse();
  const { putCourse } = usePutCourse();
  const { postSession } = usePostSession();
  const { putSession } = usePutSession();
  const [open, setOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessions, setSessions] = useState<any>([]);
  const { rooms, getRooms } = useGetRoom();
  const [repeat, setRepeat] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const CourseSchema = Yup.object().shape({
    name: Yup.string().min(1).required(),
    description: Yup.string(),
    code: Yup.string().min(1).required(),
    term: Yup.string().max(6),
    weekday: Yup.number().min(0).max(6).nullable(),
    capacity: Yup.number().min(0).nullable(),
    url: Yup.string().url(),
    price_weight: Yup.number().min(0).nullable(),
  });

  // Questionnaire
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { putQuestionnaire } = usePutQuestionnaire();
  const [addingSessionId, setAddingSessionId] = useState<string | null>(null);
  const [removingSessionId, setRemovingSessionId] = useState<string | null>(
    null
  );
  const [questionnaireSelectOpen, setQuestionnaireSelectOpen] = useState<
    boolean
  >(false);

  useEffect(() => {
    getSchools({});
    props.match.params.courseId &&
      getCourse({ courseId: props.match.params.courseId });
    getQuestionnaires({});
  }, []);

  useEffect(() => {
    course && setSessions(course.sessions);
    if (course && schools && !userState.selectedSchool) {
      const selectedSchool: ISchool = schools.filter(
        (item: { id: unknown }) => item.id === course.school_id
      )[0];
      selectSchool(selectedSchool);
    }
  }, [course, schools]);

  useEffect(() => {
    if (userState.selectedSchool) {
      getRooms({ schoolId: userState.selectedSchool.id });
    }
  }, [userState]);

  return (
    <Formik
      initialValues={
        course
          ? {
              name: course.name,
              description: course.description,
              code: course.code,
              term: course.term,
              weekday:
                course.weekday === 0
                  ? course.weekday
                  : course.weekday
                  ? course.weekday
                  : null,
              capacity: course.capacity,
              url: course.url ? course.url : "",
              categories: course.categories ? course.categories : "",
              price_weight: course.price_weight,
              admission: course.categories
                ? course.categories.includes("Admission")
                : false,
              eLearning: course.categories
                ? course.categories.includes("E-Learning")
                : false,
              online: course.categories
                ? course.categories.includes("Online")
                : false,
            }
          : {
              name: "",
              description: "",
              code: "",
              term: "",
              weekday: null,
              capacity: null,
              url: "",
              categories: [] as any,
              price_weight: null,
              admission: false,
              eLearning: false,
              online: false,
            }
      }
      enableReinitialize
      validationSchema={CourseSchema}
      onSubmit={async (value) => {
        if (userState.selectedSchool) {
          value.categories = [];
          if (value.admission && !value.categories.includes("Admission")) {
            value.categories.push("Admission");
          }
          if (value.eLearning && !value.categories.includes("E-Learning")) {
            value.categories.push("E-Learning");
          }
          if (value.online && !value.categories.includes("Online")) {
            value.categories.push("Online");
          }
          delete value.admission;
          delete value.eLearning;
          delete value.online;

          value.weekday = value.weekday === undefined ? null : value.weekday;
          value.capacity = value.capacity === "" ? null : value.capacity;
          value.price_weight =
            value.price_weight === "" ? null : value.price_weight;

          let courseId = "";
          if (course) {
            courseId = course.id;
            putCourse({
              courseId: course.id,
              body: value,
            });
            sessions.map((session: any) => {
              delete session.tableData;
              delete session.room_name;
              delete session.id_tmp;
              if (session.id) {
                putSession({ sessionId: session.id, body: session });
              } else {
                postSession({ courseId: course.id, body: session });
              }
            });
          } else {
            const newCourse = await postCourse({
              body: value,
              schoolId: userState.selectedSchool.id,
            });
            sessions.map((session: any) => {
              delete session.tableData;
              delete session.room_name;
              delete session.id_tmp;
              postSession({ body: session, courseId: newCourse.id });
            });
            courseId = newCourse.id;
          }
          props.history.push("/course");
        } else {
          alert("Select school first.");
        }
      }}
      render={({ submitForm, isSubmitting, values }) => (
        <Fragment>
          <Form>
            <Grid item xs={12} sm={6}>
              <Field
                margin="dense"
                name="name"
                type="text"
                label="Name"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                margin="dense"
                name="description"
                type="text"
                label="Description"
                component={TextField}
                fullWidth
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                margin="dense"
                name="code"
                type="text"
                label="Code"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                margin="dense"
                name="term"
                type="text"
                label="Term"
                component={TextField}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Field
                margin="dense"
                type="text"
                name="weekday"
                label="Weekday"
                select
                // margin="normal"
                component={TextField}
                fullWidth
              >
                <MenuItem>No Weekday</MenuItem>
                {weekdayOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Typography variant="caption">
              {`selected weekday: ${intToWeekday[values.weekday]}`}
            </Typography>
            <Grid item xs={12} md={3}>
              <Field
                margin="dense"
                name="capacity"
                label="Capacity"
                type="number"
                fullWidth
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field
                margin="dense"
                name="url"
                type="text"
                label="URL"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputLabel shrink={false} className={classes.inputLabel}>
                Categories
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup row>
                <Field
                  margin="dense"
                  name="eLearning"
                  component={CheckboxWithLabel}
                  values={null}
                  Label={{ label: "E-Learning" }}
                />
                <Field
                  margin="dense"
                  name="admission"
                  component={CheckboxWithLabel}
                  values={null}
                  Label={{ label: "Admission" }}
                />
                <Field
                  margin="dense"
                  name="online"
                  component={CheckboxWithLabel}
                  values={null}
                  Label={{ label: "Online" }}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={3}>
              <Field
                // margin="dense"
                name="price_weight"
                label="Price Weight"
                type="number"
                fullWidth
                component={TextField}
              />
            </Grid>

            <MaterialTable
              columns={[
                { title: "ID", field: "id" },
                { title: "Name", field: "name" },
                { title: "Description", field: "description" },
                {
                  title: "Room",
                  field: "room_id",
                  render: (rowData: any) => {
                    const matchRooms = rooms.filter((room: any) => {
                      return rowData.room_id === room.id;
                    });
                    return matchRooms.length === 1 && matchRooms[0].name;
                  },
                },
                {
                  title: "Start at",
                  field: "start_at",
                  type: "datetime",
                  defaultSort: "asc",
                  render: (rowData: any) => {
                    return formatDateTime(new Date(rowData.start_at));
                  },
                },
                {
                  title: "End at",
                  field: "end_at",
                  type: "datetime",
                  render: (rowData: any) => {
                    return formatDateTime(new Date(rowData.end_at));
                  },
                },
                {
                  title: "Price Weight",
                  field: "price_weight",
                  type: "numeric",
                  // currencySetting: {
                  //   // locale: "ja",
                  //   currencyCode: "JPY",
                  //   minimumFractionDigits: 0,
                  //   maximumFractionDigits: 3
                  // }
                },
                {
                  title: "Questionnaires",
                  field: "questionnaires",
                  render: (rowData: any) => {
                    return rowData.questionnaires
                      ? rowData.questionnaires
                          .map((questionnaire: any) => {
                            return questionnaire.title;
                          })
                          .join(", ")
                      : null;
                  },
                },
              ]}
              data={sessions}
              options={{
                headerStyle: {
                  backgroundColor: "#fafafa",
                },
                pageSize: 10,
                pageSizeOptions: [10, 20, 40],
                search: false,
                searchFieldAlignment: "left",
                actionsColumnIndex: 99,
                emptyRowsWhenPaging: false,
                exportButton: true,
                exportFileName: `S2M Session List of ${
                  course ? course.name : ""
                }_${formatDateTime(new Date())}`,
                toolbarButtonAlignment: "left",
              }}
              components={{
                Toolbar: (props) => (
                  <MTableToolbar
                    {...props}
                    classes={{ root: classes.tableTitle }}
                  />
                ),
                Container: Fragment,
              }}
              title="Sessions"
              editable={{
                onRowUpdate: (newSession, oldSession) =>
                  new Promise((resolve, reject) => {
                    // Bug in MaterialTable? numeric turns into string
                    newSession.price_weight
                      ? (newSession.price_weight = Number(
                          newSession.price_weight
                        ))
                      : (newSession.price_weight = null);

                    const newSessions = sessions.map((session: any) => {
                      if (
                        newSession.id === session.id &&
                        session.id !== undefined
                      ) {
                        delete newSession.tableData;
                        return newSession;
                      } else if (
                        newSession.id_tmp === session.id_tmp &&
                        session.id_tmp !== undefined
                      ) {
                        return newSession;
                      } else {
                        return session;
                      }
                    });
                    newSessions && setSessions(newSessions);
                    resolve();
                  }),
              }}
              actions={[
                {
                  icon: "add",
                  tooltip: "Add Session",
                  isFreeAction: true,
                  onClick: (event) => {
                    setSelectedSession(null);
                    setRepeat(true);
                    handleClickOpen();
                  },
                },
                {
                  icon: "people",
                  tooltip: "Students",
                  onClick: async (event, rowData) => {
                    window.open(`/session/${rowData.id}`);
                    // props.history.push({
                    //   pathname: `/session/${rowData.id}`
                    // });
                  },
                },
                {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData: any) => {
                    if (rowData.id) {
                      alert("Already saved session can not be deleted.");
                    } else {
                      setSessions(
                        sessions.filter((item: any) => item !== rowData)
                      );
                    }
                  },
                },
                {
                  icon: "question_answer",
                  tooltip: "Select Questionnaire",
                  onClick: async (event, rowData) => {
                    setAddingSessionId(rowData.id);
                    setQuestionnaireSelectOpen(true);
                  },
                },
              ]}
              onRowClick={(event, rowData: any) => {
                setSelectedSession(rowData);
                setRepeat(false);
                handleClickOpen();
              }}
              // localization={{
              //   header: { actions: "" }
              // }}
            />
            <MaterialTable
              columns={[
                { title: "ID", field: "id" },
                { title: "Title", field: "title" },
              ]}
              data={course?.questionnaires}
              options={{
                headerStyle: {
                  backgroundColor: "#fafafa",
                },
                pageSize: 10,
                pageSizeOptions: [10, 20, 40],
                search: false,
                searchFieldAlignment: "left",
                actionsColumnIndex: 99,
                emptyRowsWhenPaging: false,
                exportButton: true,
                exportFileName: `S2M Questionnaires List of ${
                  course ? course.name : ""
                }_${formatDateTime(new Date())}`,
                toolbarButtonAlignment: "left",
              }}
              components={{
                Toolbar: (props) => (
                  <MTableToolbar
                    {...props}
                    classes={{ root: classes.tableTitle }}
                  />
                ),
                Container: Fragment,
              }}
              title="Questionnaires"
              actions={[
                {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: async (event, rowData: any) => {
                    questionnaires.map(async (questionnaire: any) => {
                      if (questionnaire.id === rowData.id) {
                        await putQuestionnaire({
                          questionnaireId: questionnaire.id,
                          body: {
                            course_ids: questionnaire.courses
                              .filter((courseTemp: any) => {
                                return courseTemp._id !== course.id;
                              })
                              .map((courseTemp: any) => {
                                return courseTemp._id;
                              }),
                          },
                        });
                      }
                    });
                    getCourse({ courseId: props.match.params.courseId });
                  },
                },
                {
                  icon: "camera_alt",
                  tooltip: "Show QR Code",
                  onClick: async (event, rowData: any) => {
                    window.open(
                      `https://api.qrserver.com/v1/create-qr-code/?size=750x750&qzone=10&data=${process.env.REACT_APP_STUDENT_SITE_URL}questionnaire/${rowData.id}?course_id=${course.id}`,
                      "_blank"
                    );
                  },
                },
                {
                  icon: "link",
                  tooltip: "Copy Questionnaire Link To Clipboard",
                  onClick: async (event, rowData: any) => {
                    var copyText = `${process.env.REACT_APP_STUDENT_SITE_URL}questionnaire/${rowData.id}?course_id=${course.id}`;
                    document.addEventListener("copy", function handler(
                      clipboardEvent
                    ) {
                      clipboardEvent.preventDefault();
                      clipboardEvent?.clipboardData?.setData(
                        "text/plain",
                        copyText
                      );
                      document.removeEventListener("copy", handler);
                    });
                    if (document.execCommand("copy")) {
                      alert("URL Copied To Clipboard!\n" + copyText);
                    } else {
                      alert("Copy Failed ...\n" + copyText);
                    }
                  },
                },
              ]}
            />
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>
          <SessionCreateDialog
            sessions={sessions}
            setSessions={setSessions}
            selectedSession={selectedSession}
            open={open}
            handleClose={handleClose}
            repeat={repeat}
            setRepeat={setRepeat}
          />
          <QuestionnaireSelectDialog
            addingSessionId={addingSessionId}
            removingSessionId={removingSessionId}
            open={questionnaireSelectOpen}
            handleClose={() => {
              setQuestionnaireSelectOpen(false);
            }}
          />
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(CourseForm));
