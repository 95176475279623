import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import { Button, Grid, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { ITemplate } from "../../../../interface";
import { stylesForm } from "../../css";
import {
  useGetTemplate,
  usePostTemplate,
  usePutTemplate
} from "../../fetch/template";

const TemplateForm = (props: any) => {
  const { classes } = props;
  const [template, setTemplate] = useState<null | ITemplate>(null);
  const { templates, getTemplates } = useGetTemplate();
  const { postTemplate } = usePostTemplate();
  const { putTemplate } = usePutTemplate();

  const TemplateSchema = Yup.object().shape({
    title: Yup.string(),
    body: Yup.string().required()
  });

  useEffect(() => {
    getTemplates({});
  }, []);

  useEffect(() => {
    const matchTemplates = templates.filter((template: ITemplate) => {
      return template.id === props.match.params.templateId;
    });
    matchTemplates.length > 0 && setTemplate(matchTemplates[0]);
  }, [templates]);

  return (
    <Formik
      initialValues={
        template
          ? {
              title: template.title ? template.title : "",
              body: template.body ? template.body : ""
            }
          : {
              title: "",
              body: ""
            }
      }
      enableReinitialize
      validationSchema={TemplateSchema}
      onSubmit={async value => {
        template
          ? await putTemplate({ templateId: template?.id, body: value })
          : await postTemplate({ body: value });
        props.history.push("/template");
      }}
      render={({ submitForm, isSubmitting }) => (
        <Fragment>
          <Form>
            <Grid item xs={12}>
              <Field
                margin="dense"
                name="title"
                type="text"
                label="Title"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                margin="dense"
                name="body"
                type="text"
                label="Body"
                component={TextField}
                fullWidth
                multiline
                rows="32"
              />
            </Grid>

            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(TemplateForm));
