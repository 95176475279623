import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import { Button, Grid, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { IRoom } from "../../../../interface";
import { stylesForm } from "../../css";
import {
  useDeleteRoom,
  useGetRoom,
  usePostRoom,
  usePutRoom
} from "../../fetch/room";
import { usePostSchool, usePutSchool } from "../../fetch/school";
import RoomCreateDialog from "./RoomCreateDialog";

const SchoolForm = (props: any) => {
  const { classes } = props;
  const { postSchool } = usePostSchool();
  const { putSchool } = usePutSchool();
  const { postRoom } = usePostRoom();
  const { putRoom } = usePutRoom();
  const { deleteRoom } = useDeleteRoom();
  const { rooms, setRooms, getRooms } = useGetRoom();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SchoolSchema = Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required()
  });

  useEffect(() => {
    props.school && getRooms({ schoolId: props.school.id });
  }, []);

  return (
    <Formik
      initialValues={
        props.school
          ? props.school
          : {
              id: undefined,
              name: ""
            }
      }
      validationSchema={SchoolSchema}
      onSubmit={async value => {
        if (props.school) {
          putSchool({
            schoolId: props.school.id,
            body: value
          });
          rooms.map((room: any) => {
            room.capacity = room.capacity === "" ? null : room.capacity;
            delete room.tableData;
            room.id
              ? putRoom({ roomId: room.id, body: room })
              : postRoom({ schoolId: props.school.id, body: room });
          });
        } else {
          const newSchool = await postSchool({ body: value });
          rooms.map((room: IRoom) => {
            postRoom({ schoolId: newSchool.id, body: room });
          });
        }
        props.history.replace("/school");
      }}
      render={({ submitForm, isSubmitting }) => (
        <Fragment>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Field
                  autoFocus
                  name="name"
                  type="name"
                  label="Name"
                  component={TextField}
                  fullWidth
                />
              </Grid>
            </Grid>
            <MaterialTable
              columns={[
                { title: "Name", field: "name" },
                { title: "Capacity", field: "capacity", type: "numeric" }
              ]}
              data={rooms}
              options={{
                headerStyle: {
                  backgroundColor: "#fafafa"
                },
                pageSize: 5,
                pageSizeOptions: [5, 10, 20],
                search: false,
                actionsColumnIndex: 99,
                searchFieldAlignment: "left",
                emptyRowsWhenPaging: false
              }}
              components={{
                Toolbar: props => (
                  <MTableToolbar
                    {...props}
                    classes={{ root: classes.tableTitle }}
                  />
                ),
                Container: Fragment
              }}
              title="Rooms"
              actions={[
                {
                  icon: "add",
                  tooltip: "Add Room",
                  isFreeAction: true,
                  onClick: event => {
                    setSelectedRoom(null);
                    handleClickOpen();
                  }
                },
                {
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData: any) => {
                    if (rowData.id) {
                      let confirmed = window.confirm(
                        "Are you sure to delete the room? Linking sessions will lose the room."
                      );
                      if (confirmed) {
                        deleteRoom({ roomId: rowData.id });
                        setRooms(rooms.filter((item: any) => item !== rowData));
                      }
                    } else {
                      setRooms(rooms.filter((item: any) => item !== rowData));
                    }
                  }
                }
              ]}
              onRowClick={(event, rowData: any) => {
                setSelectedRoom(rowData);
                handleClickOpen();
              }}
              localization={{
                header: { actions: "" }
              }}
            />
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>
          <RoomCreateDialog
            rooms={rooms}
            setRooms={setRooms}
            selectedRoom={selectedRoom}
            open={open}
            handleClose={handleClose}
          />
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(SchoolForm));
