import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";

import { stylesMain } from "../../css";
import {
  useGetQuestionnaire,
  usePutQuestionnaire
} from "../../fetch/questionnaire";

const QuestionnaireSelectDialog = (props: any) => {
  const { classes } = props;
  const {
    questionnaires,
    getQuestionnaires,
    setQuestionnaires
  } = useGetQuestionnaire();
  const { putQuestionnaire } = usePutQuestionnaire();
  const [questionnaireIds, setQuestionnaireIds] = useState([]);

  useEffect(() => {
    getQuestionnaires({});
  }, []);

  useEffect(() => {
    const newQuestionnaires = questionnaires.map((questionnaire: any) => {
      if (
        questionnaire?.courses
          ?.map((course: any) => {
            return course._id;
          })
          .includes(props.courseId)
      ) {
        questionnaire.tableData = { checked: true };
      }
      return questionnaire;
    });
    setQuestionnaires(newQuestionnaires);
  }, [props.courseId]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Questionnaire
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MaterialTable
            columns={[
              { title: "ID", field: "id" },
              { title: "Title", field: "title" }
            ]}
            data={questionnaires}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa"
              },
              showTitle: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 40],
              selection: true,
              searchFieldAlignment: "left",
              emptyRowsWhenPaging: false,
              filtering: true
            }}
            onSelectionChange={(rows: any) => {
              setQuestionnaireIds(
                rows.map((row: any) => {
                  return row.id;
                })
              );
            }}
            components={{
              Container: Fragment
            }}
          />
          {/* <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionnaires?.map((questionnaire: any) => (
                <TableRow
                  hover
                  onClick={async (event: any) => {
                    if (props.addingCourseId) {
                      let course_ids = questionnaire.courses.map(
                        (course: any) => {
                          return course._id;
                        }
                      );
                      props.addingCourseId &&
                        course_ids.push(props.addingCourseId);
                      course_ids = props.removingCourseId
                        ? course_ids.filter((course_id: string) => {
                            return course_id !== props.removingCourseId;
                          })
                        : course_ids;
                      props.addingCourseId &&
                        (await putQuestionnaire({
                          questionnaireId: questionnaire?.id,
                          body: { course_ids: course_ids }
                        }));
                    } else if (props.addingSessionId) {
                      let session_ids = questionnaire.sessions.map(
                        (session: any) => {
                          return session._id;
                        }
                      );
                      props.addingSessionId &&
                        session_ids.push(props.addingSessionId);
                      session_ids = props.removingSessionId
                        ? session_ids.filter((session_id: string) => {
                            return session_id !== props.removingSessionId;
                          })
                        : session_ids;
                      props.addingSessionId &&
                        (await putQuestionnaire({
                          questionnaireId: questionnaire?.id,
                          body: { session_ids: session_ids }
                        }));
                    }

                    props.handleClose();
                  }}
                >
                  <TableCell component="th" scope="payment">
                    {questionnaire.id}
                  </TableCell>
                  <TableCell>{questionnaire.title}</TableCell>
                  <TableCell>{questionnaire.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="default">
            Cancel
          </Button>
          <Button
            // onClick={async () => {
            //   console.log(questionnaireIds);
            //   questionnaires.forEach((questionnaire: any) => {
            //     let newCourseIds: any = [];
            //     questionnaireIds.forEach((questionnaireId: string) => {
            //       console.log(questionnaire.id, questionnaireId);
            //       questionnaire.id === questionnaireId
            //           && questionnaire.courses
            //               .map((course: any) => {
            //                 return course.id;
            //               })
            //               .push(props.courseId)
            //           && questionnaire.courses
            //               .filter((course: any) => {
            //                 return course.id !== props.courseId;
            //               })
            //               .map((course: any) => {
            //                 return course.id;
            //               });
            //     });
            //     console.log(questionnaire);
            //     console.log(newCourseIds);
            //   });
            // }}

            //   await putQuestionnaire({
            //     questionnaireId: questionnaire?.id,
            //     body: { session_ids: session_ids }
            //   });
            // }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

QuestionnaireSelectDialog.propTypes = {
  // setQuestionnaire: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // setFieldValue: PropTypes.func.isRequired
  courseId: PropTypes.string,
  removingCourseId: PropTypes.string,
  addingSessionId: PropTypes.string,
  removingSessionId: PropTypes.string
};

export default withStyles(stylesMain)(QuestionnaireSelectDialog);
