import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField as CoreTextField
} from "@material-ui/core";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";

import { stylesForm } from "../../css";
import { useGetRoom } from "../../fetch/room";
import useUserStateContext from "../../userstate";

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .required(),
  description: Yup.string().nullable(),
  room_id: Yup.string().nullable(),
  start_at: Yup.date().required(),
  price_weight: Yup.number()
    .min(0, "Price Weight must be bigger than 0")
    .nullable()
  // end_at: Yup.date()
});

const SessionCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { rooms, getRooms } = useGetRoom();
  const [firstDate, setFirstDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [repeatTime, setRepeatTime] = useState<number>(0);

  useEffect(() => {
    if (userState.selectedSchool) {
      getRooms({ schoolId: userState.selectedSchool.id });
    }
  }, [userState, props.history]);

  const handleSwitch = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setRepeat(event.target.checked);
  };

  const handleRepeatTime = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatTime(Number(event.target.value));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        props.selectedSession
          ? {
              id: props.selectedSession.id,
              name: props.selectedSession.name,
              description: props.selectedSession.description,
              room_id: props.selectedSession.room_id,
              start_at: props.selectedSession.start_at,
              end_at: props.selectedSession.end_at,
              price_weight: props.selectedSession.price_weight
            }
          : {
              id: undefined,
              name: "",
              description: "",
              room_id: null,
              start_at: null,
              end_at: null,
              price_weight: null,
              category: null,
              id_tmp: ""
            }
      }
      validationSchema={SessionSchema}
      onSubmit={(values, { resetForm }) => {
        // values.room_id = values.room_id === "" && null;
        // create multiple sessions repeatedly
        if (props.repeat) {
          const newSessions = [...Array(repeatTime)].map(() => {
            const id_tmp = Math.random()
              .toString(32)
              .substring(2);
            if (firstDate) {
              let start_at = new Date(firstDate.setHours(startTime.getHours()));
              start_at = new Date(start_at.setMinutes(startTime.getMinutes()));
              let end_at = new Date(firstDate.setHours(endTime.getHours()));
              end_at = new Date(end_at.setMinutes(endTime.getMinutes()));

              setFirstDate(
                new Date(firstDate.setDate(firstDate.getDate() + 7))
              );
              return {
                name: values.name,
                description: values.description,
                room_id: values.room_id,
                start_at: start_at,
                end_at: end_at,
                price_weight: values.price_weight,
                category: values.category,
                id_tmp: id_tmp
              };
            }
          });

          props.setSessions([...newSessions, ...props.sessions]);
        } else {
          // handling 2 cases. 1: add new session / 2: modify one session in sessions
          // 2 required the particular session to be filtered then modified.
          if (props.selectedSession) {
            const selectedSessionNumber = props.selectedSession.tableData.id;
            const newSessions = props.sessions.map((item: any) => {
              if (item.tableData.id === selectedSessionNumber) {
                const newSession = {
                  id: values.id,
                  name: values.name,
                  description: values.description,
                  room_id: values.room_id,
                  start_at: values.start_at,
                  end_at: values.end_at,
                  price_weight: values.price_weight,
                  category: values.category
                };
                return newSession;
              } else {
                return item;
              }
            });
            props.setSessions(newSessions);
          } else {
            values["id_tmp"] = Math.random()
              .toString(32)
              .substring(2);
            props.setSessions([values, ...props.sessions]);
          }
        }
        resetForm();
      }}
      render={({ values, submitForm, isValid, setFieldValue }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Session</DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Field
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Field
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    component={TextField}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Field
                    margin="dense"
                    type="text"
                    name="room_id"
                    label="Room"
                    select
                    component={TextField}
                    fullWidth
                  >
                    <MenuItem value={""}>No Room</MenuItem>
                    {rooms &&
                      rooms.map(
                        (room: {
                          id: string | number | string[] | undefined;
                          name: React.ReactNode;
                        }) => <MenuItem value={room.id}>{room.name}</MenuItem>
                      )}
                  </Field>
                </Grid>
                {!props.selectedSession && (
                  <Grid item xs={12} md={12} className={classes.switchMargin}>
                    {/* <FormGroup row> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.repeat}
                          onChange={handleSwitch()}
                          // value="checkedB"
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Repeat"
                    />
                    {/* </FormGroup> */}
                  </Grid>
                )}
                {props.repeat ? (
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <DatePicker
                          name="first_date"
                          label="First Date"
                          value={firstDate}
                          onChange={date => (
                            setFirstDate(date), setFieldValue("start_at", date)
                          )}
                          format="yyyy/MM/dd"
                          fullWidth
                          className={classes.pickerMargin}
                          helperText="Sessions will be on the day of week of this date."
                        /> */}
                        <KeyboardDatePicker
                          name="first_date"
                          disableToolbar
                          // variant="inline"
                          format="yyyy/MM/dd"
                          margin="normal"
                          label="First Date"
                          value={firstDate}
                          onChange={date => (
                            setFirstDate(date), setFieldValue("start_at", date)
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          margin="normal"
                          name="start_time"
                          label="Start Time"
                          value={startTime}
                          onChange={time => setStartTime(time)}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                          format="HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        />
                        {/* <TimePicker
                          name="start_time"
                          label="Start Time"
                          value={startTime}
                          onChange={time => setStartTime(time)}
                          format="HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        /> */}
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          margin="normal"
                          name="end_time"
                          label="End Time"
                          value={endTime}
                          onChange={time => setEndTime(time)}
                          KeyboardButtonProps={{
                            "aria-label": "change time"
                          }}
                          format="HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        />

                        {/* <TimePicker
                          name="end_time"
                          label="End Time"
                          value={endTime}
                          onChange={time => setEndTime(time)}
                          format="HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        /> */}
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Field
                        margin="dense"
                        name="repeat_time"
                        label="Repeating Time"
                        value={repeatTime}
                        type="number"
                        fullWidth
                        component={CoreTextField}
                        onChange={handleRepeatTime()}
                        className={classes.fieldMargin}
                        helperText="For how many weeks the session to be repeated."
                      />
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          name="start_at"
                          label="Start at"
                          value={values.start_at}
                          onChange={date => setFieldValue("start_at", date)}
                          format="yyyy/MM/dd HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          name="end_at"
                          label="End at"
                          value={values.end_at}
                          onChange={date => setFieldValue("end_at", date)}
                          format="yyyy/MM/dd HH:mm"
                          fullWidth
                          className={classes.pickerMargin}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12} md={12}>
                  <Field
                    margin="dense"
                    name="price_weight"
                    label="Price Weight"
                    type="number"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="default">
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                onClick={() => {
                  submitForm();
                  props.handleClose();
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

SessionCreateDialog.propTypes = {
  sessions: PropTypes.array.isRequired,
  setSessions: PropTypes.func.isRequired,
  selectedSession: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  repeat: PropTypes.bool.isRequired,
  setRepeat: PropTypes.func.isRequired
};

export default withStyles(stylesForm)(SessionCreateDialog);
