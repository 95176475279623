import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { useGetProduct } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";

const ProductSelectDialog = (props: any) => {
  const { userState } = useUserStateContext();
  const { products, getProducts } = useGetProduct();

  useEffect(() => {
    userState.selectedSchool
      ? getProducts({ schoolId: userState.selectedSchool.id })
      : getProducts({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Product
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MaterialTable
            columns={[
              { title: "Name", field: "name", filtering: true },
              { title: "Code", field: "code", filtering: true },
              {
                title: "Courses",
                render: (rowData: any) => {
                  const course_names = rowData.courses.map((course: any) => {
                    return course.name;
                  });
                  return course_names.join(", ");
                },
                customFilterAndSearch: (value, rowData) => {
                  return rowData.courses
                    .map((course: any) => {
                      return course.name;
                    })
                    .join(", ")
                    .includes(value);
                }
              },
              {
                title: "Price",
                field: "price",
                type: "currency",
                currencySetting: {
                  // locale: "ja",
                  currencyCode: "JPY",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3
                },
                filtering: false
              },
              {
                title: "Open at",
                field: "open_at",
                type: "datetime",
                render: (rowData: any) => {
                  return formatDateTime(new Date(rowData.open_at));
                },
                filtering: false
              },
              {
                title: "Close at",
                field: "close_at",
                type: "datetime",
                render: (rowData: any) => {
                  return formatDateTime(new Date(rowData.close_at));
                },
                filtering: false
              },
              {
                title: "Tags",
                field: "tags",
                render: (rowData: any) => {
                  const tag_names = rowData.tags
                    ? rowData.tags.map((tag: any) => {
                        return tag;
                      })
                    : "";
                  return tag_names.join(", ");
                },
                filtering: true
              },
              {
                title: "Hidden",
                field: "hidden",
                type: "boolean",
                filtering: true
              }
            ]}
            data={products && products}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa"
              },
              showTitle: false,
              pageSize: 20,
              pageSizeOptions: [20, 40, 60],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              toolbarButtonAlignment: "left",
              filtering: true
            }}
            components={{
              Container: Fragment
            }}
            onRowClick={(event, rowData: any) => {
              props.setProduct(rowData);
              props.setFieldValue("product_id", rowData.id);
              props.setFieldValue("price", rowData.price);
              props.handleClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ProductSelectDialog.propTypes = {
  setProduct: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default ProductSelectDialog;
