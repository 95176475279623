import React from "react";

import { Grid } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      backgroundColor: theme.palette.common.white
    },
    selectEmpty: {
      paddingLeft: theme.spacing(2)
    }
  })
);

export default function HeaderBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center">
            <Typography variant="h6">Admin</Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
