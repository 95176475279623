import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { Fragment, useState } from "react";
import { withRouter } from "react-router";
import * as Yup from "yup";

import { Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { stylesForm } from "../../css";
import ProductSelectDialog from "../product/ProductSelectDialog";
import StudentSelectDialog from "../student/StudentSelectDialog";
import { usePostOrder } from "../../fetch/order";

const OrderForm = (props: any) => {
  const { classes } = props;
  const [product, setProduct] = useState<any>(null);
  const [productSelectDialogOpen, setProductSelectDialogOpen] = useState(false);
  const [student, setStudent] = useState<any>(null);
  const [studentSelectDialogOpen, setStudentSelectDialogOpen] = useState(false);
  const { postOrder } = usePostOrder();

  const handleProductSelectDialogClose = () => {
    setProductSelectDialogOpen(false);
  };

  const handleStudentSelectDialogClose = () => {
    setStudentSelectDialogOpen(false);
  };

  const OrderSchema = Yup.object().shape({
    student_id: Yup.string().required(),
    product_id: Yup.string().required(),
    price: Yup.number()
      .min(0)
      .required(),
    note: Yup.string(),
    questions: Yup.string().required()
  });

  const pyToJs = (value: string) => {
    value = value.trim();
    value = value.replace(/True/g, "true");
    value = value.replace(/False/g, "false");
    value = value.replace(/'/g, '"');
    value = value.replace(/None/g, "null");
    return value;
  };

  return (
    <Formik
      initialValues={{
        student_id: "",
        product_id: "",
        price: 0,
        note: "本お申し込みはスクール事業部にて作成致しました。",
        questions:
          '[{"prepayment": true}, {"oneself": true}, {"payment_name": ""}, {"postcode": ""}, {"address": ""}, {"student_name": ""}, {"student_address": ""}]',
        invoice: `{"name": null, "postcode": null, "address": null}`,
        receipt: `null`
      }}
      enableReinitialize
      validationSchema={OrderSchema}
      onSubmit={async values => {
        values.questions = JSON.parse(values.questions);
        values.invoice = JSON.parse(values.invoice);
        values.receipt = JSON.parse(values.receipt);
        const product_id = values.product_id;
        const student_id = values.student_id;
        delete values.product_id;
        delete values.student_id;
        await postOrder({
          productId: product_id,
          studentId: student_id,
          body: values
        });
        props.history.replace("/order");
      }}
      render={({
        submitForm,
        isSubmitting,
        values,
        setFieldValue,
        errors,
        isValid
      }) => (
        <Fragment>
          <Form>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={3}>
                <Field
                  margin="dense"
                  name="student_id"
                  type="text"
                  label="Student ID"
                  component={TextField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid>
                <Button
                  color="primary"
                  onClick={() => {
                    setStudentSelectDialogOpen(true);
                  }}
                >
                  Select Student
                </Button>
              </Grid>
              {student && (
                <Grid container>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.mt1}
                  >
                    （{student.last_name} {student.first_name}）
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={3}>
                <Field
                  margin="dense"
                  name="product_id"
                  type="text"
                  label="Product ID"
                  component={TextField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid>
                <Button
                  color="primary"
                  onClick={() => {
                    setProductSelectDialogOpen(true);
                  }}
                >
                  Select Product
                </Button>
              </Grid>
              {product && (
                <Grid container>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.mt1}
                  >
                    （{product.name}）
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container xs={12} md={3}>
              <Field
                className={classes.mt2}
                margin="dense"
                name="price"
                label="Price"
                type="number"
                fullWidth
                component={TextField}
                helperText="Tax Excluding. Tax will be added after submitting this order."
              />
            </Grid>
            <Grid container xs={12} sm={12}>
              <Field
                margin="dense"
                name="note"
                type="text"
                label="Note"
                component={TextField}
                fullWidth
                multiline
              />
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              sm={12}
            >
              <Grid item xs={12} sm={11}>
                <Field
                  margin="dense"
                  name="questions"
                  type="text"
                  label="Questions"
                  component={TextField}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  onClick={() => {
                    setFieldValue("questions", pyToJs(values.questions));
                  }}
                >
                  Format
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              sm={12}
            >
              <Grid item xs={12} sm={11}>
                <Field
                  margin="dense"
                  name="invoice"
                  type="text"
                  label="Invoice"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  onClick={() => {
                    setFieldValue("invoice", pyToJs(values.invoice));
                  }}
                >
                  Format
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              sm={12}
            >
              <Grid item xs={12} sm={11}>
                <Field
                  margin="dense"
                  name="receipt"
                  type="text"
                  label="Receipt"
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  onClick={() => {
                    setFieldValue("receipt", pyToJs(values.receipt));
                  }}
                >
                  Format
                </Button>
              </Grid>
            </Grid>

            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {isSubmitting && <LinearProgress />}
          </Form>
          <ProductSelectDialog
            setProduct={setProduct}
            open={productSelectDialogOpen}
            handleClose={handleProductSelectDialogClose}
            setFieldValue={setFieldValue}
            values={values}
          />
          <StudentSelectDialog
            setStudent={setStudent}
            open={studentSelectDialogOpen}
            handleClose={handleStudentSelectDialogClose}
            setFieldValue={setFieldValue}
          />
        </Fragment>
      )}
    />
  );
};

export default withStyles(stylesForm)(withRouter(OrderForm));
