import MaterialTable, { MTableBody } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { ISchool } from "../../../../interface";
import { stylesMain } from "../../css";
import { useGetCourse, useCompleteCourseEnrollment } from "../../fetch/course";
import { useGetSchool } from "../../fetch/school";
import useUserStateContext from "../../userstate";
import { formatDateTime, renderTerm } from "../../util";
import SchoolSelect from "../school/SchoolSelect";
import CourseSelectDialog from "./CourseSelectDialog";

const CourseEnrollment = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const { schools, getSchools } = useGetSchool();
  const { course, getCourse } = useGetCourse();
  const { completeCourseEnrollment } = useCompleteCourseEnrollment();
  const [open, setOpen] = useState(false);
  const [studentId, setStudentId] = useState<string>("");

  let completedFiltered: boolean | undefined = undefined;

  useEffect(() => {
    getCourse({ courseId: props.match.params.courseId });
    getSchools({});
    userState.signedIn === false && props.history.push("/login");
  }, [userState, props.history, open]);

  useEffect(() => {
    if (course && schools && !userState.selectedSchool) {
      const selectedSchool: ISchool = schools.filter(
        (item: { id: unknown }) => item.id === course.school_id
      )[0];
      selectSchool(selectedSchool);
    }
  }, [course, schools]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Roster｜ {course && `${renderTerm(course.term)}${course.name}`}
              </Typography>
            </Grid>
            <Grid item>
              <SchoolSelect disabled={true} />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={[
              {
                title: "ID",
                field: "student_id",
                editable: "never",
              },
              {
                title: "Name",
                field: "name",
                editable: "never",
              },
              {
                title: "Name Kana",
                field: "name_kana",
                editable: "never",
              },
              {
                title: "E-mail",
                field: "email",
                editable: "never",
              },
              {
                title: "Company Name",
                field: "company.name",
                editable: "never",
              },
              {
                title: "Transferred From",
                field: "transferred_from",
                editable: "never",
              },
              {
                title: "Completed",
                field: "completed",
                type: "boolean",
                // filtering: true
                // render: (rowData: any) => {
                //   return rowData.completed === true
                //     ? "Completed"
                //     : rowData.completed === false && "Not Completed";
                // },
                // lookup: { true: "Completed", false: "Not Completed" }
              },
              {
                title: "Score",
                field: "score",
                type: "numeric",
              },
            ]}
            data={course && course.roster_students}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 5000,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Roster Student List_${
                course && renderTerm(course.term)
              } ${course && course.name}_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
              rowStyle: (rowData) =>
                rowData.score < 0 ? { backgroundColor: "lightgrey" } : {},
            }}
            components={{
              Container: Fragment,
              Body: (props) => (
                <MTableBody
                  {...props}
                  classes={{ root: classes.tableTitle }}
                  // onFilterChanged={(columnId: any, value: any) => {
                  //   props.onFilterChanged(columnId, value);
                  //   console.log(value[0]);
                  //   completedFiltered =
                  //     value.length === 1
                  //       ? value[0] === "true"
                  //         ? true
                  //         : value[0] === "false" && false
                  //       : undefined;
                  // }}
                />
              ),
            }}
            editable={{
              onRowUpdate: (newOrder: any, oldOrder) =>
                new Promise(async (resolve, reject) => {
                  await completeCourseEnrollment({
                    studentId: newOrder.student_id,
                    courseId: course.id,
                    body: {
                      completed: newOrder.completed === "true" ? true : false,
                      score:
                        newOrder.score === "" ? null : Number(newOrder.score),
                    },
                  });
                  getCourse({ courseId: props.match.params.courseId });
                  resolve();
                }),
            }}
            actions={[
              {
                icon: "redo",
                tooltip: "Transfer to Another Course",
                onClick: (event, rowData: any) => {
                  setStudentId(rowData.student_id);
                  handleClickOpen();
                },
              },
              {
                icon: "check",
                tooltip: "Mark / Unmark Completion",
                onClick: async (event, rowData: any) => {
                  if (rowData.completed) {
                    await completeCourseEnrollment({
                      studentId: rowData.student_id,
                      courseId: course.id,
                      body: {
                        completed: false,
                      },
                    });
                  } else {
                    await completeCourseEnrollment({
                      studentId: rowData.student_id,
                      courseId: course.id,
                      body: {
                        completed: true,
                      },
                    });
                  }

                  getCourse({ courseId: props.match.params.courseId });
                },
              },
              {
                icon: "mail",
                tooltip: "Send E-mail for the students listed below",
                isFreeAction: true,
                onClick: () => {
                  const addresses = course?.roster_students
                    .filter((student: any) => {
                      return completedFiltered === undefined
                        ? true
                        : student.completed === completedFiltered;
                    })
                    .map((student: any) => {
                      return student.email;
                    });
                  window.open(`mailto:?bcc=${addresses}`, "_blank");
                },
              },
            ]}
          />
        </div>
      </main>
      <CourseSelectDialog
        fromCourseId={course && course.id}
        studentId={studentId}
        open={open}
        handleClose={handleClose}
      />
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(CourseEnrollment));
