import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetProduct = (): any => {
  const { execute } = useFetch("GET");
  const [products, setProducts] = React.useState([]);
  const [freeProducts, setFreeProducts] = React.useState([]);
  const [getProductsError, setGetProductsError] = React.useState(null);

  const getProducts = async () => {
    try {
      const fetchedProducts = await execute({
        url: getUrl() + `v1/a/product`,
      });
      setProducts(fetchedProducts);
    } catch (error) {
      setGetProductsError(error);
    }
  };

  const getFreeProducts = async () => {
    try {
      let fetchedProducts = await execute({
        url: getUrl() + `v1/a/product`,
      });
      fetchedProducts = fetchedProducts.filter((product: any) => {
        return product.price === 0;
      });
      setFreeProducts(fetchedProducts);
    } catch (error) {
      setGetProductsError(error);
    }
  };

  return {
    products,
    getProducts,
    freeProducts,
    getFreeProducts,
    getProductsError,
  };
};

export const usePostProduct = () => {
  const { execute } = useFetch("POST");

  const postProduct = async ({ body }: { body: object }) => {
    try {
      const newProduct = await execute({
        url: getUrl() + `v1/a/product`,
        body: body,
      });
      return newProduct;
    } catch (error) {
      alert(error);
    }
  };

  return { postProduct };
};

export const usePutProduct = () => {
  const { execute } = useFetch("PUT");

  const putProduct = async ({
    productId,
    body,
  }: {
    productId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/product/${productId}`,
        body: body,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putProduct };
};

// const useDeleteProduct
