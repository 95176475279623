import MaterialTable, { MTableToolbar } from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesForm, stylesMain } from "../../css";
import {
  useGetSession,
  useAddAttendSession,
  useRemoveAttendSession,
  useCancelSession,
  useUndoCancelSession
} from "../../fetch/session";
import useUserStateContext from "../../userstate";
import { formatDateTime, renderTerm } from "../../util";
import SessionSelectDialog from "./SessionSelectDialog";

const SessionDetail = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { session, getSession, sessions, getSessions } = useGetSession();
  const { addAttendSession } = useAddAttendSession();
  const { cancelSession } = useCancelSession();
  const { undoCancelSession } = useUndoCancelSession();
  const { removeAttendSession } = useRemoveAttendSession();
  const [transferrableSessions, setTransferrableSessions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);

  useEffect(() => {
    getSession({ sessionId: props.match.params.sessionId });
    getSessions();
    userState.signedIn === false && props.history.push("/login");
  }, [userState, props.history, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterTransferrableSessions = (sessionBase: any) => {
    return sessions.filter((session: any) => {
      return (
        session.course.term === sessionBase.course.term &&
        session.course.code === sessionBase.course.code &&
        session.course.weekday != sessionBase.course.weekday &&
        session.name === sessionBase.name &&
        session.course.school_id === sessionBase.course.school_id
      );
    });
  };

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Session Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.form}>
          <Grid container>
            <Grid item sm={12} className={classes.mt2}>
              <Typography variant="caption">Course / Session</Typography>
              <Typography variant="subtitle1">
                {session &&
                  `${renderTerm(session.course.term)} ${
                    session.course.name
                  }　/　${session.name}`}
              </Typography>
            </Grid>
            <Grid item sm={12} className={classes.mt2}>
              <Typography variant="caption">Start at ~ End at</Typography>
              <Typography variant="subtitle1">
                {session &&
                  `${formatDateTime(
                    new Date(session.start_at),
                    false
                  )}　~　${formatDateTime(new Date(session.end_at), false)}`}
              </Typography>
            </Grid>
            <Grid item sm={12} className={classes.mt2}>
              <Typography variant="caption">
                Room / Remainings / Capacity
              </Typography>
              <Typography variant="subtitle1">
                {session && session.room
                  ? `${session.room.name}　/　${session.remainings}　/　${session.room.capacity}`
                  : `No Room Associated`}
              </Typography>
            </Grid>
            <Grid item sm={12} className={classes.mt2}>
              <Typography variant="caption">Description</Typography>
              <Typography variant="subtitle1">
                {session && session.description
                  ? `${session.description}`
                  : `No Description`}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div className={classes.tableNoPT}>
          {session?.students && (
            <MaterialTable
              columns={[
                { title: "ID", field: "id" },
                {
                  title: "Name",
                  field: "full_name"
                },
                {
                  title: "Name Kana",
                  field: "full_name_kana"
                },
                { title: "E-mail", field: "email" },
                {
                  title: "Transferred From",
                  field: "from_session.name",
                  render: (rowData: any) => {
                    return rowData.from_session
                      ? `${rowData.from_session.name}（${formatDateTime(
                          new Date(rowData.from_session.start_at),
                          false
                        )} ~ ${formatDateTime(
                          new Date(rowData.from_session.end_at),
                          false
                        )}）`
                      : null;
                  }
                },
                { title: "Attended", field: "attend", type: "boolean" }
              ]}
              data={session && session.students}
              options={{
                headerStyle: {
                  backgroundColor: "#fafafa"
                },
                showTitle: true,
                pageSize: 5000,
                pageSizeOptions: [20, 40, 60, 5000],
                search: false,
                searchFieldAlignment: "left",
                actionsColumnIndex: 99,
                emptyRowsWhenPaging: false,
                exportButton: true,
                exportFileName: `S2M Student List of Session_${renderTerm(
                  session.course.term
                )} ${session.course.name}-${session.name}_${formatDateTime(
                  new Date()
                )}`,
                toolbarButtonAlignment: "left",
                filtering: true
              }}
              components={{
                Toolbar: props => (
                  <MTableToolbar
                    {...props}
                    classes={{ root: classes.tableTitle }}
                  />
                ),
                Container: Fragment
              }}
              title="Students Attending / Attended"
              actions={[
                {
                  icon: "check",
                  tooltip: "Mark / Unmark Attendance",
                  onClick: async (event, rowData: any) => {
                    rowData.attend
                      ? await removeAttendSession({
                          studentId: rowData.id,
                          sessionId: session && session.id
                        })
                      : await addAttendSession({
                          studentId: rowData.id,
                          sessionId: session && session.id
                        });
                    getSession({ sessionId: props.match.params.sessionId });
                  }
                },
                (rowData: any) => ({
                  icon: "redo",
                  tooltip: "Transfer to Another Session",
                  onClick: (event, rowData: any) => {
                    setSelectedStudent(rowData);
                    setTransferrableSessions(
                      filterTransferrableSessions(session)
                    );
                    handleClickOpen();
                  },
                  disabled: rowData.attend === true
                }),
                (rowData: any) => ({
                  icon: "cancel",
                  tooltip: "Cancel",
                  onClick: async (event, rowData: any) => {
                    window.confirm(
                      `Are you sure to cancel ${rowData.full_name}'s attendance to the session? Remaining will increase 1. System will not send E-mail.\n${rowData.full_name} さんの出席予定をキャンセルします。よろしいですか? 残席が1追加されます。なお、E-mail が自動で送信されることはありません。`
                    ) &&
                      (await cancelSession({
                        studentId: rowData.id,
                        sessionId: props.match.params.sessionId
                      }));
                    getSession({ sessionId: props.match.params.sessionId });
                  },
                  disabled: rowData.attend === true
                })
              ]}
            />
          )}
        </div>

        {session?.session_transfers_from.length > 0 && (
          <Fragment>
            <Divider className={classes.mt3} />
            <div className={classes.table}>
              <MaterialTable
                columns={[
                  { title: "ID", field: "student.id" },
                  {
                    title: "Name",
                    field: "student.full_name"
                  },
                  {
                    title: "Name Kana",
                    field: "student.full_name_kana"
                  },
                  { title: "E-mail", field: "student.email" },
                  {
                    title: "Transferred to / Canceled",
                    field: "to_session_id",
                    render: (rowData: any) => {
                      const matchSessions = sessions.filter((session: any) => {
                        return session.id === rowData.to_session_id;
                      });
                      return matchSessions.length > 0
                        ? `${matchSessions[0].name}（${formatDateTime(
                            new Date(matchSessions[0].start_at),
                            false
                          )} ~ ${formatDateTime(
                            new Date(matchSessions[0].end_at),
                            false
                          )}）`
                        : "Canceled";
                    }
                  }
                ]}
                data={session && session.session_transfers_from}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa"
                  },
                  showTitle: true,
                  pageSize: 5000,
                  pageSizeOptions: [20, 40, 60, 5000],
                  search: false,
                  searchFieldAlignment: "left",
                  actionsColumnIndex: 99,
                  emptyRowsWhenPaging: false,
                  exportButton: true,
                  exportFileName: `S2M Student List of Session_${renderTerm(
                    session.course.term
                  )} ${session.course.name}-${session.name}_${formatDateTime(
                    new Date()
                  )}`,
                  toolbarButtonAlignment: "left",
                  filtering: true
                }}
                components={{
                  Toolbar: props => (
                    <MTableToolbar
                      {...props}
                      classes={{ root: classes.tableTitle }}
                    />
                  ),
                  Container: Fragment
                }}
                title="Students Transferred / Canceled"
                actions={[
                  (rowData: any) => ({
                    icon: "undo",
                    tooltip: "Undo Cancel",
                    disabled: rowData.to_session_id != null,
                    onClick: async (event, rowData: any) => {
                      window.confirm(
                        `Are you sure to undo cancel ${rowData.student.full_name}'s attendance to the session? Remaining will decrease 1. System will not send E-mail.\n${rowData.student.full_name} さんの出席予定のキャンセルを取り消します。よろしいですか? 残席が1削減されます。なお、E-mail が自動で送信されることはありません。`
                      ) &&
                        (await undoCancelSession({
                          studentId: rowData.student.id,
                          sessionId: props.match.params.sessionId
                        }));
                      getSession({ sessionId: props.match.params.sessionId });
                    }
                  })
                ]}
              />
            </div>
          </Fragment>
        )}
      </main>
      <SessionSelectDialog
        open={open}
        handleClose={handleClose}
        sessions={transferrableSessions && transferrableSessions}
        fromSession={session && session}
        student={selectedStudent && selectedStudent}
      />
    </Fragment>
  );
};

export default withStyles(stylesMain)(
  withStyles(stylesForm)(withRouter(SessionDetail))
);
