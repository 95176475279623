import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetTemplate = (): any => {
  const { execute } = useFetch("GET");
  const [templates, setTemplates] = React.useState([]);
  const [getTemplatesError, setGetTemplatesError] = React.useState(null);

  const getTemplates = async ({}) => {
    try {
      const fetchedTemplates = await execute({
        url: getUrl() + `v1/a/template`
      });
      setTemplates(fetchedTemplates);
    } catch (error) {
      setGetTemplatesError(error);
    }
  };

  return { templates, setTemplates, getTemplates, getTemplatesError };
};

export const usePostTemplate = (): any => {
  const { execute } = useFetch("POST");

  const postTemplate = ({ body }: { body: object }) => {
    try {
      const newTemplate = execute({
        url: getUrl() + `v1/a/template`,
        body: body
      });
      return newTemplate;
    } catch (error) {
      alert(error);
    }
  };

  return { postTemplate };
};

export const usePutTemplate = () => {
  const { execute } = useFetch("PUT");

  const putTemplate = async ({
    templateId,
    body
  }: {
    templateId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/template/${templateId}`,
        body: body
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putTemplate };
};

export const useDeleteTemplate = () => {
  const { execute } = useFetch("DELETE");

  const deleteTemplate = async ({ templateId }: { templateId: string }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/template/${templateId}`
      });
    } catch (error) {
      alert(error);
    }
  };

  return { deleteTemplate };
};
