import { getUrl } from "../userstate";
import useFetch from "./useFetch";

// const useGetPayment : not planned to be implemented

export const usePostPayment = (): any => {
  const { execute } = useFetch("POST");

  const postPayment = ({
    orderId,
    body
  }: {
    orderId: string;
    body: object;
  }) => {
    try {
      const newPayment = execute({
        url: getUrl() + `v1/a/billing/order/${orderId}/payment`,
        body: body
      });
      return newPayment;
    } catch (error) {
      alert(error);
    }
  };

  return { postPayment };
};

// export const usePutPayment = () => {}
