import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import { allPossibleCases, doubleToSingleArray, renderTerm } from "../../util";
import {
  useGetCourse,
  useGetCourses,
  useGetCoursesAdmission,
} from "../../fetch/course";

import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { ITemplate } from "../../../../interface";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import SchoolSelect from "../school/SchoolSelect";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { TextField } from "formik-material-ui";
import { schoolNamesDict } from "../../../../enum";
import { stylesForm } from "../../css";
import { useGetTemplate } from "../../fetch/template";
import { usePostProduct } from "../../fetch/product";
import useUserStateContext from "../../userstate";
import { withStyles } from "@material-ui/styles";

const ProductJunbiSchema = Yup.object().shape({
  term: Yup.string(),
  open_at: Yup.date().required(),
  close_at: Yup.date(),
  codes: Yup.array().required(),
  email_template_id: Yup.string().required(),
});

const ProductJunbiCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();
  const { coursesAdmission, getCoursesAdmission } = useGetCoursesAdmission();
  const { getCourseSync } = useGetCourse();
  const { postProduct } = usePostProduct();
  const [terms, setTerms] = useState<any[]>([]);
  const [codesUnique, setCodesUnique] = useState<any[]>([]);
  const [term, setTerm] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const { templates, getTemplates } = useGetTemplate();

  useEffect(() => {
    if (props.open) {
      if (userState.selectedSchool) {
        getCourses({ schoolId: userState.selectedSchool.id });
      }
      getCoursesAdmission({});
      getTemplates({});
    }
  }, [props.open, userState.selectedSchool]);

  useEffect(() => {
    const terms = courses.map((course: any) => {
      return course.term;
    });
    const termsUnique = Array.from(new Set(terms));
    setTerms(termsUnique);
  }, [courses]);

  useEffect(() => {
    const codes = courses
      .filter((course: any) => {
        return course.term === term && course.price_weight > 0;
      })
      .map((course: any) => {
        return course.code;
      });
    setCodesUnique(Array.from(new Set(codes)));
  }, [term]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        schoolId: userState.selectedSchool ? userState.selectedSchool.id : "",
        term: "",
        open_at: null,
        close_at: null,
        codes: [],
        email_template_id: "",
      }}
      validationSchema={ProductJunbiSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);

        // all combinations of an item of each array with empty {} object
        let combinations = [];

        // admission is no longer considered for junbi products
        /* let admission = coursesAdmission.filter((course: any) => {
          return course.price_weight && course.price_weight > 0;
        });
        if (admission.length !== 1) {
          if (admission.length < 1) {
            alert(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）がありません。先にそのコースを登録してください。Admission course with price_weight > zero is required. Register the course first.`
            );
          } else if (admission.length > 1) {
            alert(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）が2つ以上あります。想定していない状態です。該当するコースを削除するか、どちらかのコースのprice_weightをnull（0ではなく空）にするか、categoriesのAdmissionのチェックを外してください。More than two Admission course with price_weight > zero are found.`
            );
          }

          resetForm();
          props.handleClose();
          throw "error";
        }
        admission.push({}); 
        combinations.push(admission); */

        for (let code of values.codes) {
          let coursesArray = courses.filter((course: any) => {
            return course.code === code && course.term === values.term;
          });
          coursesArray.push({});
          combinations.push(coursesArray);
        }
        combinations = allPossibleCases(combinations);

        const selectedSchoolName = schoolNamesDict.filter(
          (schoolNameDict: any) => {
            return schoolNameDict.nameJa === userState.selectedSchool?.name;
          }
        )[0].name;

        let newProducts: any = [];

        // make new products. exclude fully empty one
        for (let combination of combinations) {
          const notEmptiesNoSession = combination
            .filter((course: any) => {
              return Object.keys(course).length !== 0;
            })
            .reverse();

          const notEmpties = await Promise.all(
            notEmptiesNoSession.map(async (course: any) => {
              return await getCourseSync({ courseId: course.id });
            })
          );

          if (notEmpties.length > 0) {
            let allELearning = true;
            let onlyAdmission = true;
            let onlyAdmissionOrELearning = true;
            let hasAdmission = false;
            notEmpties.forEach((course: any) => {
              !course.categories.includes("E-Learning") &&
                (allELearning = false);
              if (
                !(
                  course.categories.includes("Admission") ||
                  course.categories.includes("E-Learning")
                )
              ) {
                onlyAdmissionOrELearning = false;
              }
              if (course.categories.includes("Admission")) {
                hasAdmission = true;
              } else {
                onlyAdmission = false;
              }
            });

            // prepare body values
            let name = notEmpties
              .map((course: any) => {
                return course.name;
              })
              .join(" / ");
            name = `【${renderTerm(values.term)}】 ${name}`;

            const price = notEmpties
              .map((course: any) => {
                if (!course.price_weight) {
                  alert(
                    `"${course.name}"というコースにprice_weight（価格）が登録されていません。先にprice_weightを登録してください。Course: "${course.name}" does not have price_weight. Set price_weight first.`
                  );
                  resetForm();
                  props.handleClose();
                  throw "error";
                }
                return course.price_weight;
              })
              .reduce(function (p: number, c: number) {
                return p + c;
              });

            // close_at was calculated based on the earliest session's start_at but now using fixed date.
            // https://datamix-jp.slack.com/archives/CKQNNMPT4/p1594283082009400
            // const close_ats = doubleToSingleArray(
            //   notEmpties.map((course: any) => {
            //     return course.sessions;
            //   })
            // )
            //   .map((session: any) => {
            //     let newDate = new Date(session.start_at);
            //     newDate.setDate(newDate.getDate() - 7);
            //     return newDate;
            //   })
            //   .sort((a: Date, b: Date) => {
            //     return a > b ? 1 : -1;
            //   });
            // const close_at =
            //   close_ats.length === 0 ? values.close_at : close_ats[0];
            const close_at = values.close_at;

            const course_ids = notEmpties.map((course: any) => {
              return course.id;
            });
            let application_conditions: any = [];

            // product with all E-Learning does not require admission
            if (allELearning) {
              newProducts.push({
                name: name,
                description: "",
                code: "",
                price: price,
                open_at: values.open_at,
                close_at: close_at,
                hidden: false,
                course_ids: course_ids,
                tags: ["junbi", `term_${term}`, `school_${selectedSchoolName}`],
                application_conditions: application_conditions,
                email_template_id: values.email_template_id,
              });
              // with admission and without admission for every combination
            } else if (onlyAdmission || onlyAdmissionOrELearning) {
            } else {
              // admission is no longer considered for junbi products
              /* application_conditions = hasAdmission
                ? [{ courses: [{ code: "Admission", progress: "no" }] }]
                : [{ courses: [{ code: "Admission" }] }]; */
              newProducts.push({
                name: name,
                description: "",
                code: "",
                price: price,
                open_at: values.open_at,
                close_at: close_at,
                hidden: false,
                course_ids: course_ids,
                tags: ["junbi", `term_${term}`, `school_${selectedSchoolName}`],
                application_conditions: application_conditions,
                email_template_id: values.email_template_id,
              });
            }
          }
        }
        console.log(JSON.stringify(newProducts));
        if (
          window.confirm(
            `${newProducts.length} 個のプロダクトが生成されます。よろしいですか？ Totally ${newProducts.length} new products will be created. Confirm to proceed?`
          )
        ) {
          for (const product of newProducts) {
            await postProduct({ body: product });
          }
        }

        resetForm();
        props.handleClose();
      }}
      render={({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Create Junbi Products
            </DialogTitle>
            {isSubmitting && <LinearProgress />}
            <Divider />
            <DialogContent>
              <Fragment>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <SchoolSelect />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label-term">Term</InputLabel>
                      <Select
                        value={values.term}
                        onChange={(event: any) => {
                          setFieldValue("term", event.target.value);
                          setTerm(event.target.value);
                        }}
                        name="term"
                        disabled={isSubmitting}
                      >
                        {terms.map((term: any) => {
                          return <MenuItem value={term}>{term}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12} className={classes.mt2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        name="open_at"
                        label="Open at"
                        value={values.open_at}
                        onChange={(date) => setFieldValue("open_at", date)}
                        format="yyyy/MM/dd HH:mm"
                        fullWidth
                        // helperText="Required Field"
                        disabled={isSubmitting}
                      />
                      {/* <DateTimePicker
                        name="open_at"
                        label="Open at"
                        value={values.open_at}
                        onChange={date => setFieldValue("open_at", date)}
                        format="yyyy/MM/dd HH:mm"
                        fullWidth
                        // helperText="Required Field"
                        disabled={isSubmitting}
                      /> */}
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.mt2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        name="close_at"
                        label="Close at"
                        value={values.close_at}
                        onChange={(date) => setFieldValue("close_at", date)}
                        format="yyyy/MM/dd HH:mm"
                        fullWidth
                        helperText="If new product does not have any course having session (situation like having only E-Learning course) this datetime will be used for close_at for the product."
                        disabled={isSubmitting}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} className={classes.mt1}>
                    <FormControl className={classes.w100}>
                      <InputLabel>Codes</InputLabel>
                      <Select
                        multiple
                        value={values.codes}
                        name="code"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setFieldValue(
                            "codes",
                            event.target.value as string[]
                          );
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                        // MenuProps={MenuProps}
                        disabled={isSubmitting}
                      >
                        {codesUnique.map((codeUnique: any) => (
                          <MenuItem key={codeUnique} value={codeUnique}>
                            {/* <Checkbox
                              checked={values.codes.indexOf(codeUnique) > -1}
                            /> */}
                            <ListItemText primary={codeUnique} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="email_template_id"
                      label="Email Template"
                      select
                      // margin="normal"
                      component={TextField}
                      fullWidth
                    >
                      {templates.map((template: ITemplate) => (
                        <MenuItem key={template.id} value={template.id}>
                          {`${template.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                color="default"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                // disabled={}
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  submitForm();
                  // props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={snackBarOpen}
            autoHideDuration={6000}
            onClose={() => {
              setSnackBarOpen(false);
            }}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Junbi Products Created</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => {
                  setSnackBarOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Fragment>
      )}
    />
  );
};

ProductJunbiCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(stylesForm)(ProductJunbiCreateDialog);
