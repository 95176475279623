import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import { StyleRules } from "@material-ui/core/styles/withStyles";

export const stylesMain = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      marginTop: theme.spacing(8),
      display: "block",
      width: "calc(100% - 240px) ;",
    },
    table: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    tableNoPT: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    createButton: {
      marginTop: theme.spacing(9),
      right: theme.spacing(3),
      position: "absolute",
      zIndex: 1,
    },
    createProductsMoreVert: {
      marginTop: theme.spacing(9),
      right: theme.spacing(16),
      position: "absolute",
      zIndex: 1,
    },
    studentDetail: {
      marginLeft: theme.spacing(2),
      whiteSpace: "pre-wrap",
    },
  });

export const stylesForm = (theme: Theme): StyleRules =>
  createStyles({
    button: {
      marginTop: theme.spacing(2),
    },
    table: {
      marginTop: theme.spacing(1),
    },
    tableTitle: {
      marginTop: theme.spacing(2),
    },
    tableHeader: {
      backgroundColor: "#fafafa",
    },
    inputLabel: {
      marginTop: theme.spacing(3),
    },
    pickerMargin: {
      marginTop: theme.spacing(1),
      // paddingBottom: theme.spacing(2),
    },
    fieldMargin: {
      paddingTop: theme.spacing(1),
    },
    switchMargin: {
      marginTop: theme.spacing(2),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    ml3: {
      marginLeft: theme.spacing(3),
    },
    mtb1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    w100: {
      width: "100%",
    },
  });
