import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { intToWeekday } from "../../../../enum";
import { useGetCourses, useTransferCourse } from "../../fetch/course";
import useUserStateContext from "../../userstate";
import SchoolSelect from "../school/SchoolSelect";

const CourseSelectDialog = (props: any) => {
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();
  const { transferCourse } = useTransferCourse();

  useEffect(() => {
    userState.selectedSchool
      ? getCourses({ schoolId: userState.selectedSchool.id })
      : getCourses({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Course
              </Typography>
            </Grid>
            <Grid item>
              <SchoolSelect />
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <main>
            <div>
              <MaterialTable
                columns={[
                  { title: "Name", field: "name" },
                  { title: "Code", field: "code" },
                  { title: "Term", field: "term" },
                  {
                    title: "Weekday",
                    render: (rowData: any) => {
                      return intToWeekday[Number(rowData.weekday)];
                    }
                  },
                  {
                    title: "Categories",
                    render: (rowData: any) => {
                      return rowData.categories.join(", ");
                    }
                  },
                  { title: "Capacity", field: "capacity", type: "numeric" },
                  {
                    title: "Price Weight",
                    field: "price_weight",
                    type: "numeric"
                  }
                ]}
                data={courses && courses}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa"
                  },
                  showTitle: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 40],
                  searchFieldAlignment: "left",
                  emptyRowsWhenPaging: false,
                  filtering: true
                }}
                onSelectionChange={rows => {
                  props.setCourses(rows);
                }}
                components={{
                  Container: Fragment
                }}
                onRowClick={async (event, rowData: any) => {
                  window.confirm(
                    `Are you sure to transfer course enrollment for the student?  System will not send E-mail \nコースを振替え（延期）ます。よろしいですか? なお、E-mail が自動で送信されることはありません、`
                  ) &&
                    (await transferCourse({
                      studentId: props.studentId,
                      fromCourseId: props.fromCourseId,
                      toCourseId: rowData.id
                    }));
                  props.handleClose();
                  console.log(rowData);
                }}
              />
            </div>
          </main>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

CourseSelectDialog.propTypes = {
  fromCourseId: PropTypes.string.isRequired,
  studentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CourseSelectDialog;
