import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetQuestionnaire = (): any => {
  const { execute } = useFetch("GET");
  const [questionnaires, setQuestionnaires] = React.useState([]);
  const [getQuestionnairesError, setGetQuestionnairesError] = React.useState(
    null
  );

  const getQuestionnaires = async ({}) => {
    try {
      const fetchedQuestionnaires = await execute({
        url: getUrl() + `v1/a/questionnaire`
      });
      setQuestionnaires(fetchedQuestionnaires);
    } catch (error) {
      setGetQuestionnairesError(error);
    }
  };

  return {
    questionnaires,
    setQuestionnaires,
    getQuestionnaires,
    getQuestionnairesError
  };
};

export const usePostQuestionnaire = (): any => {
  const { execute } = useFetch("POST");

  const postQuestionnaire = ({ body }: { body: object }) => {
    try {
      const newQuestionnaire = execute({
        url: getUrl() + `v1/a/questionnaire`,
        body: body
      });
      return newQuestionnaire;
    } catch (error) {
      alert(error);
    }
  };

  return { postQuestionnaire };
};

export const usePutQuestionnaire = () => {
  const { execute } = useFetch("PUT");

  const putQuestionnaire = async ({
    questionnaireId,
    body
  }: {
    questionnaireId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/questionnaire/${questionnaireId}`,
        body: body
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putQuestionnaire };
};

export const useDeleteQuestionnaire = () => {
  const { execute } = useFetch("DELETE");

  const deleteQuestionnaire = async ({
    questionnaireId
  }: {
    questionnaireId: string;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/questionnaire/${questionnaireId}`
      });
    } catch (error) {
      alert(error);
    }
  };

  return { deleteQuestionnaire };
};

export const useGetQuestionnaireAnswer = (): any => {
  const { execute } = useFetch("GET");
  const [questionnaireAnswer, setQuestionnaireAnswer] = React.useState([]);
  const [
    getQuestionnaireAnswerError,
    setGetQuestionnaireAnswerError
  ] = React.useState(null);

  const getQuestionnaireAnswer = async ({
    questionnaireId
  }: {
    questionnaireId: string;
  }) => {
    try {
      const fetchedQuestionnaireAnswer = await execute({
        url: getUrl() + `v1/a/questionnaire/${questionnaireId}/answer`
      });
      setQuestionnaireAnswer(fetchedQuestionnaireAnswer);
    } catch (error) {
      setGetQuestionnaireAnswerError(error);
    }
  };

  return {
    questionnaireAnswer,
    setQuestionnaireAnswer,
    getQuestionnaireAnswer,
    getQuestionnaireAnswerError
  };
};
