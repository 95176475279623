import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesMain } from "../../css";
import useUserStateContext from "../../userstate";
import SchoolForm from "./SchoolForm";

const SchoolDetail = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
  }, [userState, props.history]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                School Detail
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.form}>
          <SchoolForm school={props.location.state.school} />
        </div>
      </main>
    </Fragment>
  );
};

SchoolDetail.prototype = {
  school: PropTypes.object
};
export default withStyles(stylesMain)(withRouter(SchoolDetail));
