import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Box, Link, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { StyleRules } from "@material-ui/core/styles/withStyles";

import firebase from "../firebase";
import useUserStateContext from "../userstate";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(10),
      // marginRight: theme.spacing(3),
      // [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      //   width: 400,
      //   marginLeft: "auto",
      //   marginRight: "auto"
      // },
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

function Dashboard(props: any) {
  const { classes } = props;
  const { userState } = useUserStateContext();

  useEffect(() => {
    !userState.signedIn && props.history.push("/");
  }, [userState, props.history]);

  return (
    <main className={classes.main}>
      <Box fontWeight="fontWeightBold" fontSize={16}>
        Welcome to S2M
      </Box>
      <br />
      <Typography>
        You can grasp the overview of S2M{" "}
        <Link href="https://datamix.docbase.io/posts/830483">here</Link>
      </Typography>
      <Typography>
        Manual can be found{" "}
        <Link href="https://datamix.docbase.io/posts/964812">here</Link>
      </Typography>
      <Typography>
        For more information, please explore{" "}
        <Link href="https://datamix.docbase.io/tagged/S2M">here</Link>
      </Typography>{" "}
    </main>
  );

  async function logout() {
    await firebase.logout();
    props.history.push("/");
  }
}

export default withStyles(styles)(withRouter(Dashboard));
