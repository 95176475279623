import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { CircularProgress, CssBaseline } from "@material-ui/core";
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  MuiThemeProvider,
  Theme,
} from "@material-ui/core/styles";

import firebase from "../firebase";
import useUserStateContext from "../userstate";
import Course from "./course/Course";
import CourseCreate from "./course/CourseCreate";
import CourseDetail from "./course/CourseDetail";
import CourseEnrollment from "./course/CourseEnrollment";
import SessionDetail from "./course/SessionDetail";
import Dashboard from "./Dashboard";
import Login from "./Login";
import HeaderBar from "./Navigation";
import Order from "./order/Order";
import OrderCreate from "./order/OrderCreate";
import Product from "./product/Product";
import ProductCreate from "./product/ProductCreate";
import ProductDetail from "./product/ProductDetail";
import School from "./school/School";
import SchoolCreate from "./school/ShoolCreate";
import SchoolDetail from "./school/ShoolDetail";
import Student from "./student/Student";
import StudentDetail from "./student/StudentDetail";
import Template from "./template/Template";
import TemplateCreate from "./template/TemplateCreate";
import TemplateDetail from "./template/TemplateDetail";
import UserStateWtapper from "./UserStateWrapper";
import Questionnaire from "./questionnaire/Questionnaire";
import QuestionnaireCreate from "./questionnaire/QuestionnaireCreate";
import QuestionnaireDetail from "./questionnaire/QuestionnaireDetail";
import QuestionnaireAnswer from "./questionnaire/QuestionnaireAnswer";

const theme = createMuiTheme({
  props: {
    // MuiButtonBase: {
    //   disableRipple: true // No more ripple, on the whole application 💣!
    // }
  },
  palette: {
    // you can change theme color globally here.
    secondary: { main: "#3f6fb5" },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "#fff",
    },
  })
);

export default function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    firebase
      .isInitialized()
      .then((val) => {
        setFirebaseInitialized(true);
        firebase.getCurrentUser() === null
          ? setSignedIn(false)
          : setSignedIn(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return firebaseInitialized !== false ? (
    <useUserStateContext.Provider
      initialState={{ signedIn: signedIn, selectedSchool: undefined }}
    >
      <UserStateWtapper>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <div className={classes.root}>
              <HeaderBar></HeaderBar>
              <Switch>
                <Route exact path="/" component={Login} />
                {/* <Route exact path="/login" component={Login} /> */}
                {/* <Route exact path="/register" component={Register} /> */}
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/school" component={School} />
                <Route exact path="/school/create" component={SchoolCreate} />
                <Route
                  exact
                  path="/school/:schoolId"
                  component={SchoolDetail}
                />
                <Route exact path="/course" component={Course} />
                <Route exact path="/course/create" component={CourseCreate} />
                <Route
                  exact
                  path="/course/:courseId"
                  component={CourseDetail}
                />
                <Route
                  exact
                  path="/course/roster/:courseId"
                  component={CourseEnrollment}
                />
                <Route
                  exact
                  path="/session/:sessionId"
                  component={SessionDetail}
                />
                <Route exact path="/product" component={Product} />
                <Route exact path="/product/create" component={ProductCreate} />
                <Route
                  exact
                  path="/product/:productId"
                  component={ProductDetail}
                />
                <Route exact path="/order" component={Order} />
                <Route exact path="/order/create" component={OrderCreate} />
                <Route exact path="/student" component={Student} />
                <Route
                  exact
                  path="/student/:studentId"
                  component={StudentDetail}
                />
                <Route exact path="/questionnaire" component={Questionnaire} />
                <Route
                  exact
                  path="/questionnaire/create"
                  component={QuestionnaireCreate}
                />
                <Route
                  exact
                  path="/questionnaire/:questionnaireId"
                  component={QuestionnaireDetail}
                />
                <Route
                  exact
                  path="/questionnaire/:questionnaireId/answer"
                  component={QuestionnaireAnswer}
                />
                <Route exact path="/template" component={Template} />
                <Route
                  exact
                  path="/template/create"
                  component={TemplateCreate}
                />
                <Route
                  exact
                  path="/template/:templateId"
                  component={TemplateDetail}
                />
              </Switch>
            </div>
          </Router>
        </MuiThemeProvider>
      </UserStateWtapper>
    </useUserStateContext.Provider>
  ) : (
    <div id="loader">
      <CircularProgress />
    </div>
  );
}
