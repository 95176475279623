import "firebase/auth";
import "firebase/firebase-firestore";

import app from "firebase/app";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

class Firebase {
  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
  }
  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  async register(
    name: string | null | undefined,
    email: string,
    password: string
  ) {
    await this.auth.createUserWithEmailAndPassword(email, password);
    if (this.auth.currentUser) {
      try {
        const token = await this.auth.currentUser.getIdToken();
        return await token;
      } catch (error) {
        console.log(error);
        return "";
      }
    } else {
      return "";
    }
  }

  addQuote(quote: any) {
    if (!this.auth.currentUser) {
      return alert("Not authorized");
    }

    return this.db
      .doc(`users_codedamn_video/${this.auth.currentUser.uid}`)
      .set({
        quote
      });
  }

  isInitialized() {
    return new Promise(resolve => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser && this.auth.currentUser.displayName;
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  getToken() {
    return this.auth.currentUser && this.auth.currentUser.getIdToken();
  }

  fetchToken = async () => {
    try {
      const token = await this.getToken();
      return await token;
    } catch (e) {}
  };
}

export default new Firebase();
