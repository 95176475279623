import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import {
  useCancelOrder,
  useConfirmOrder,
  useGetOrder,
  usePutOrder,
  useUnConfirmOrder,
} from "../../fetch/order";

import MaterialTable from "material-table";
import PaymentDialog from "./PaymentRecordDialog";
import PaymentHistoryDialog from "./PaymentHistoryDialog";
import { formatDateTime } from "../../util";
import { stylesMain } from "../../css";
import useUserStateContext from "../../userstate";
import withStyles from "@material-ui/core/styles/withStyles";

const Order = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { orders, getOrders } = useGetOrder();
  const { confirmOrder } = useConfirmOrder();
  const { unConfirmOrder } = useUnConfirmOrder();
  const { putOrder } = usePutOrder();
  const [open, setOpen] = useState(false);
  const [paymentHistoryOpenClose, setPaymentHistoryOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [payments, setPayments] = useState([]);
  const { cancelOrder } = useCancelOrder();
  // This is to isolate the status of columns (especially filters) so that the
  // filtering to be kept even after the data of Material Table being refreshed.
  const [columnObject] = useState<object[]>([
    { title: "Order ID", field: "id", editable: "never" },
    {
      title: "Student ID",
      field: "student.id",
      editable: "never",
    },
    {
      title: "Name",
      field: "student.full_name",
      editable: "never",
    },
    {
      title: "Name Kana",
      field: "student.full_name_kana",
      editable: "never",
    },
    {
      title: "Postcode",
      field: "student.postcode",
      editable: "never",
    },
    {
      title: "Address",
      field: "student.address",
      editable: "never",
    },
    {
      title: "E-mail",
      field: "student.email",
      editable: "never",
    },
    {
      title: "Product",
      field: "product.name",
      editable: "never",
      cellStyle: {
        minWidth: 300,
      },
    },
    {
      title: "Product Tags",
      field: "product.tags",
      editable: "never",
      export: false,
      render: (rowData: any) => rowData.product.tags?.join(", "),
    },
    {
      title: "Price Tax Exc",
      field: "price",
      type: "currency",
      currencySetting: {
        // locale: "ja",
        currencyCode: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      editable: "never",
      filtering: true,
      customFilterAndSearch: (value: any, rowData: any) => {
        return value.includes(">")
          ? rowData.price > Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : value.includes("<")
          ? rowData.price < Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : rowData.price === Number(value)
          ? true
          : false;
      },
      defaultFilter: ">0",
    },
    {
      title: "Price Tax Inc",
      field: "price_tax_inc",
      type: "currency",
      currencySetting: {
        // locale: "ja",
        currencyCode: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      editable: "never",
      filtering: true,
      customFilterAndSearch: (value: any, rowData: any) => {
        return value.includes(">")
          ? rowData.price_tax_inc > Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : value.includes("<")
          ? rowData.price_tax_inc < Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : rowData.price_tax_inc === Number(value)
          ? true
          : false;
      },
    },
    {
      title: "Ordered at",
      field: "ordered_at",
      render: (rowData: any) => {
        return formatDateTime(new Date(rowData.ordered_at));
      },
      filtering: false,
      editable: "never",
    },
    {
      title: "Prepayment",
      field: "prepayment",
      type: "boolean",
      render: (rowData: any) => {
        return rowData.prepayment === true
          ? "Prepayment"
          : rowData.prepayment === false && "Postpayment";
      },
      lookup: { true: "Prepayment", false: "Postpayment" },
    },
    {
      title: "Paid by",
      field: "oneself",
      type: "boolean",
      render: (rowData: any) => {
        return rowData.oneself === true
          ? "Oneself"
          : rowData.oneself === false && "Another One";
      },
      lookup: { true: "Oneself", false: "Another One" },
    },
    {
      title: "Payment Name",
      field: "payment_name",
    },
    {
      title: "Postcode",
      field: "postcode",
    },
    {
      title: "Address",
      field: "address",
    },
    {
      title: "Kyufukin",
      field: "kyufukin",
      type: "boolean",
      render: (rowData: any) => {
        return rowData.kyufukin === true
          ? "Applies"
          : rowData.kyufukin === false && "Does Not Apply";
      },
      lookup: { true: "Applies", false: "Does Not Apply" },
    },
    {
      title: "Unpaid Amount",
      field: "unpaid_amount",
      type: "currency",
      currencySetting: {
        // locale: "ja",
        currencyCode: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      filtering: true,
      editable: "never",
      customFilterAndSearch: (value: any, rowData: any) => {
        return value.includes(">")
          ? rowData.unpaid_amount > Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : value.includes("<")
          ? rowData.unpaid_amount < Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : rowData.unpaid_amount === Number(value)
          ? true
          : false;
      },
    },
    {
      title: "Payment Due",
      field: "dueDate",
      type: "date",
      customFilterAndSearch: (value: any, rowData: any) => {
        if (rowData.dueDate === null) {
          return false;
        } else {
          return new Date(rowData.dueDate) < value ? true : false;
        }
      },
    },
    {
      title: "Confirmed",
      field: "confirmed",
      lookup: { true: "Confirmed", false: "Not Confirmed" },
      editable: "never",
    },
    {
      title: "Canceled",
      field: "canceled",
      lookup: { true: "Canceled", false: "Not Canceled" },
      editable: "never",
    },
    {
      title: "Invoice Downloadable",
      field: "invoice",
      type: "boolean",
      render: (rowData: any) => {
        return rowData.invoice ? "Yes" : "No";
      },
      lookup: { Yes: "Downloadable", No: "Not Downloadable" },
      customFilterAndSearch: (value: any, rowData: any) => {
        if (value.length === 1) {
          value = value[0];
          return value === "Yes" ? rowData.invoice : !rowData.invoice;
        } else {
          return true;
        }
      },
      editable: "never",
    },
    {
      title: "Receipt Downloadable",
      field: "receipt",
      type: "boolean",
      render: (rowData: any) => {
        return rowData.receipt ? "Yes" : "No";
      },
      lookup: { Yes: "Downloadable", No: "Not Downloadable" },
      customFilterAndSearch: (value: any, rowData: any) => {
        if (value.length === 1) {
          value = value[0];
          return value === "Yes" ? rowData.receipt : !rowData.receipt;
        } else {
          return true;
        }
      },
      editable: "never",
    },
    {
      title: "Invoice Value",
      field: "invoice",
      type: "text",
      render: (rowData: any) => {
        return JSON.stringify(rowData.invoice);
      },
      filtering: false,
      editable: "never",
    },
    {
      title: "Receipt Value",
      field: "receipt",
      type: "text",
      render: (rowData: any) => {
        return JSON.stringify(rowData.receipt);
      },
      filtering: false,
      editable: "never",
    },
    {
      title: "Note",
      field: "note",
      filtering: false,
      type: "string",
      cellStyle: {
        minWidth: 300,
      },
    },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paymentHistoryOpen = () => {
    setPaymentHistoryOpen(true);
  };

  const paymentHistoryClose = () => {
    setPaymentHistoryOpen(false);
  };

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getOrders({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Order
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/order/create"
                  className={classes.createButton}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={orders && orders}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 40,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              filtering: true,
              actionsColumnIndex: 99,
              // doubleHorizontalScroll: true,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Order List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
            }}
            components={{
              Container: Fragment,
            }}
            editable={{
              onRowUpdate: (newOrder: any, oldOrder) =>
                new Promise(async (resolve, reject) => {
                  console.log(oldOrder);
                  console.log(newOrder);
                  if (newOrder.prepayment === "true") {
                    newOrder.prepayment = true;
                  } else if (newOrder.prepayment === "false") {
                    newOrder.prepayment = false;
                  }
                  if (newOrder.oneself === "true") {
                    newOrder.oneself = true;
                  } else if (newOrder.oneself === "false") {
                    newOrder.oneself = false;
                  }
                  if (newOrder.kyufukin === "true") {
                    newOrder.kyufukin = true;
                  } else if (newOrder.kyufukin === "false") {
                    newOrder.kyufukin = false;
                  }

                  for (let i = 0; i < newOrder.questions.length; i++) {
                    const key = Object.keys(newOrder.questions[i])[0];
                    newOrder.questions[i][key] = newOrder[key];
                  }
                  const orderBody = {
                    note: newOrder.note,
                    questions: newOrder.questions,
                  };
                  await putOrder({ orderId: newOrder.id, body: orderBody });
                  getOrders({});
                  resolve();
                }),
            }}
            actions={[
              (rowData: any) => ({
                icon: "attach_money",
                tooltip: "Record Payment",
                // isFreeAction: true,
                onClick: (event, rowData) => {
                  setSelectedOrder(rowData);
                  setPayments(rowData.payments);
                  handleClickOpen();
                },
                disabled: rowData.price == 0,
              }),
              (rowData: any) => ({
                icon: "history",
                tooltip: "Payment History",
                // isFreeAction: true,
                onClick: (event, rowData) => {
                  setPayments(rowData.payments);
                  paymentHistoryOpen();
                },
                disabled: rowData.price == 0,
              }),
              (rowData: any) => ({
                icon: "check",
                tooltip: "Confirm / Unconfirm Order",
                onClick: async (event, rowData) => {
                  if (rowData.price === 0) {
                    if (rowData.confirmed) {
                      await unConfirmOrder({ orderId: rowData.id });
                    } else {
                      let confirmed = window.confirm(
                        `This is ordering a free product. Are you sure to confirm? Student will be able to see the course in My Course Page.\nこれは無料の商品に対する注文です。Confirmすると、受講生はマイ講座画面でこの講座を見ることができるようになります。無料かつ複数回の通いの講座以外では無料商品に対する注文がConfirmされる想定はありません。Confirmしてよろしいですか?`
                      );
                      if (confirmed) {
                        await confirmOrder({ orderId: rowData.id });
                      }
                    }
                  } else {
                    rowData.confirmed
                      ? await unConfirmOrder({ orderId: rowData.id })
                      : await confirmOrder({ orderId: rowData.id });
                  }

                  // FIXME should be server side logic
                  if (!rowData.confirmed) {
                    // post payment and company then invoice will be pre-set
                    if (
                      rowData.prepayment === false &&
                      rowData.oneself === false
                    ) {
                      let confirmed = window.confirm(
                        `This order is Postpayment / Company. Normally, Name, Postcode, and Address of the Invoice should be pre-set allowing the student to download unless this order needs special care. Confirm allowing the student to download Invoice with pre-set value? \nこの注文は 後払い/法人 です。通常、宛名・郵便番号・住所が注文内容に従ってプリセットされた請求書を受講生個人がダウンロード可能にしますが、特別な対応が必要になる場合、ダウンロードは不可のままとします。受講生個人が請求書をダウンロードをできるようにしますか?`
                      );
                      if (confirmed) {
                        await putOrder({
                          orderId: rowData.id,
                          body: {
                            invoice: {
                              name: rowData.payment_name,
                              postcode: rowData.postcode,
                              address: rowData.address,
                            },
                          },
                        });
                      }
                    }
                    // prepayment and not kyufukin then receipt will be available
                    if (rowData.prepayment && !rowData.kyufukin) {
                      let confirmed = window.confirm(
                        `This order is Prepayment / Not Kyufukin. Normally, Receipt should turn into downloadable (Name, Postcode and Address being editable for the first time.) Confirm allowing the student to download Receipt? \nこの注文は 前払い/給付金対象外 です。通常、領収書を受講生個人がダウンロード可能にします（初回のみ宛名・郵便番号・住所を編集可能）が、特別な対応が必要になる場合、ダウンロードは不可のままとします。受講生個人が領収書をダウンロードをできるようにしますか?`
                      );
                      if (confirmed) {
                        await putOrder({
                          orderId: rowData.id,
                          body: {
                            receipt: {
                              name: null,
                              postcode: null,
                              address: null,
                            },
                          },
                        });
                      }
                    }
                  }
                  getOrders({});
                },
                // disabled: rowData.price == 0
              }),
              (rowData: any) => ({
                icon: "cancel",
                tooltip: "Cancel Order",
                onClick: async (event, rowData) => {
                  let confirmed = window.confirm(
                    "Are you sure to cancel the order? \n選択された注文をキャンセルします。よろしいですか? この注文で申込まれたプロダクトが内包するコースの残席が1つ増加します。ペイメントが紐付いている場合、その情報は削除されずに保持されます。"
                  );
                  if (confirmed) {
                    await cancelOrder({
                      orderId: rowData.id,
                    });
                    getOrders({});
                  }
                },
                disabled: rowData.canceled === true,
              }),
              (rowData: any) => ({
                icon: () => (
                  <Typography align="center" style={{ marginTop: "3px" }}>
                    請
                  </Typography>
                ),
                tooltip: "Disallow / Allow Invoice",
                onClick: async (event, rowData) => {
                  if (rowData.invoice != null) {
                    let confirmed = window.confirm(
                      "Are you sure to disallow Invoice download for the student? \n選択された注文の請求書のダウンロードを不可とします。よろしいですか?"
                    );
                    if (confirmed) {
                      const orderBody = {
                        invoice: null,
                      };
                      await putOrder({ orderId: rowData.id, body: orderBody });
                    }
                  } else {
                    let confirmed = window.confirm(
                      "Are you sure to allow Invoice download for the student? \n選択された注文の請求書のダウンロードを可（初回のみ宛名・郵便番号・住所を編集可能）とします。よろしいですか? \nこの操作は Postpayment / Oneself（個人後払い） → Prepayment / Oneself（個人前払い） に変更する際の用途のみを想定しています。\nもしPostpayment / Another one（法人後払い）に変更する場合、郵便番号・住所・法人名をプリセットする必要があるため、この処理は不適切です。別途ご相談ください。"
                    );
                    if (confirmed) {
                      const orderBody = {
                        invoice: {
                          name: null,
                          postcode: null,
                          address: null,
                        },
                      };
                      await putOrder({ orderId: rowData.id, body: orderBody });
                    }
                  }
                  getOrders({});
                },
                disabled: rowData.price === 0,
              }),
              (rowData: any) => ({
                icon: () => (
                  <Typography align="center" style={{ marginTop: "3px" }}>
                    領
                  </Typography>
                ),
                tooltip: "Disallow / Allow Receipt",
                onClick: async (event, rowData) => {
                  if (rowData.receipt != null) {
                    let confirmed = window.confirm(
                      "Are you sure to disallow Receipt download for the student? \n選択された注文の領収書のダウンロードを不可とします。よろしいですか?"
                    );
                    if (confirmed) {
                      const orderBody = {
                        receipt: null,
                      };
                      await putOrder({ orderId: rowData.id, body: orderBody });
                    }
                  } else {
                    let confirmed = window.confirm(
                      "Are you sure to allow Receipt download for the student? \n選択された注文の領収書のダウンロードを可（初回のみ宛名・郵便番号・住所を編集可能）とします。よろしいですか? この操作は通常の流れの場合想定されていません。"
                    );
                    if (confirmed) {
                      const orderBody = {
                        receipt: {
                          name: null,
                          postcode: null,
                          address: null,
                        },
                      };
                      await putOrder({ orderId: rowData.id, body: orderBody });
                    }
                  }
                  getOrders({});
                },
                disabled: rowData.price === 0,
              }),
            ]}
          />
        </div>
      </main>

      <PaymentDialog
        payments={payments}
        setPayments={setPayments}
        selectedOrder={selectedOrder}
        open={open}
        handleClose={handleClose}
        getOrders={getOrders}
      />

      <PaymentHistoryDialog
        payments={payments}
        open={paymentHistoryOpenClose}
        close={paymentHistoryClose}
      />
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Order));
