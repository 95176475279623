import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import {
  useGetCourse,
  useGetCourses,
  usePostCourse,
  usePutCourse,
} from "../../fetch/course";
import {
  useGetQuestionnaire,
  usePutQuestionnaire,
} from "../../fetch/questionnaire";

import { ISchool } from "../../../../interface";
import MaterialTable from "material-table";
import QuestionnaireSelectDialog from "../questionnaire/QuesionnaireSelectDialog";
import SchoolSelect from "../school/SchoolSelect";
import { formatDateTime } from "../../util";
import { intToWeekday } from "../../../../enum";
import { stylesMain } from "../../css";
import { useGetSchool } from "../../fetch/school";
import { usePostSession } from "../../fetch/session";
import useUserStateContext from "../../userstate";
import withStyles from "@material-ui/core/styles/withStyles";

const Course = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const { getCourseSync } = useGetCourse();
  const { courses, getCourses } = useGetCourses();
  const { schools, getSchools } = useGetSchool();
  const { postCourse } = usePostCourse();
  const { putCourse } = usePutCourse();
  const { postSession } = usePostSession();
  const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
  const [questionnaireSelectOpen, setQuestionnaireSelectOpen] =
    useState<boolean>(false);
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { putQuestionnaire } = usePutQuestionnaire();

  const [columnObject] = useState<object[]>([
    { title: "Course ID", field: "id" },
    { title: "Name", field: "name" },
    { title: "Code", field: "code" },
    { title: "Term", field: "term" },
    {
      title: "Weekday",
      field: "weekday",
      render: (rowData: any) => {
        return rowData.weekday != null
          ? intToWeekday[Number(rowData.weekday)]
          : null;
      },
      lookup: {
        // null: "No Weekday",
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
      },
    },
    {
      title: "Categories",
      render: (rowData: any) => {
        return rowData.categories.join(", ");
      },
    },
    {
      title: "Price Weight",
      field: "price_weight",
      type: "currency",
      currencySetting: {
        // locale: "ja",
        currencyCode: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      },
      filtering: true,
      customFilterAndSearch: (value: any, rowData: any) => {
        return value.includes(">")
          ? rowData.price_weight > Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : value.includes("<")
          ? rowData.price_weight < Number(value.replace(/[^0-9]/g, ""))
            ? true
            : false
          : rowData.price_weight === Number(value)
          ? true
          : false;
      },
      render: (rowData: any) => {
        return rowData.price_weight != null
          ? `¥ ${rowData.price_weight}`
          : null;
      },
    },
    { title: "Capacity", field: "capacity", type: "string" },
    { title: "Remainings", field: "remainings", type: "string" },
    {
      title: "Totally Confirmed",
      field: "totally_confirmed",
      type: "boolean",
    },
    {
      title: "Questionnaires",
      field: "questionnaires",
      // type: "array"
      customFilterAndSearch: (value: any, rowData: any) => {
        return (rowData.questionnaires ?? [])
          .map((questionnaire: any) => {
            return questionnaire.title;
          })
          .join(", ")
          .includes(value);
      },
      render: (rowData: any) => {
        return (rowData.questionnaires ?? [])
          .map((questionnaire: any) => {
            return questionnaire.title;
          })
          .join(", ");
      },
    },
  ]);

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    userState.selectedSchool
      ? getCourses({ schoolId: userState.selectedSchool.id })
      : getCourses({});
    getSchools({});
    getQuestionnaires({});
  }, [userState, props.history, questionnaireSelectOpen]);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Course
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/course/create"
                  className={classes.createButton}
                >
                  Create
                </Button>
                <SchoolSelect />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={courses && courses}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 40,
              pageSizeOptions: [20, 40, 60, 5000],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Course List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
            }}
            components={{
              Container: Fragment,
            }}
            editable={{
              onRowUpdate: (newCourse: any, oldProduct) =>
                new Promise(async (resolve, reject) => {
                  const courseId = newCourse.id;
                  delete newCourse.id;
                  delete newCourse.school_id;
                  newCourse.capacity === ""
                    ? (newCourse.capacity = null)
                    : newCourse.capacity === "0"
                    ? (newCourse.capacity = 0)
                    : (newCourse.capacity = Number(newCourse.capacity));
                  newCourse.price_weight === ""
                    ? (newCourse.price_weight = null)
                    : newCourse.price_weight === "0"
                    ? (newCourse.price_weight = 0)
                    : (newCourse.price_weight = Number(newCourse.price_weight));
                  newCourse.weekday =
                    typeof newCourse.weekday === "string"
                      ? Number(newCourse.weekday)
                      : newCourse.weekday;

                  await putCourse({
                    courseId: courseId,
                    body: newCourse,
                  });
                  userState.selectedSchool
                    ? getCourses({ schoolId: userState.selectedSchool.id })
                    : getCourses({});
                  resolve();
                }),
            }}
            actions={[
              {
                icon: "open_in_new",
                tooltip: "Detail in New Tab",
                onClick: (event, rowData) => {
                  window.open(`/course/${rowData.id}`);
                },
              },
              {
                icon: "people",
                tooltip: "Roster",
                onClick: async (event, rowData) => {
                  const selectedSchool: ISchool = schools.filter(
                    (item: { id: unknown }) => item.id === rowData.school_id
                  )[0];
                  selectSchool(selectedSchool);

                  window.open(`/course/roster/${rowData.id}`);
                  // props.history.push({
                  //   pathname: `/course/roster/${rowData.id}`
                  // });
                },
              },
              {
                icon: "file_copy",
                tooltip: "Copy Course",
                onClick: async (event, rowData) => {
                  if (!userState.selectedSchool) {
                    alert("Select school first.");
                  } else {
                    let confirmed = window.confirm(
                      "Are you sure to copy the course? Sessions under the course will also be copied."
                    );
                    if (confirmed) {
                      let course = await getCourseSync({
                        courseId: rowData.id,
                      });
                      const sessions = course.sessions;

                      delete course.id;
                      delete course.sessions;
                      const newCourse = {
                        name: `${course.name}_copy`,
                        description: course.description,
                        code: course.code,
                        term: course.term,
                        weekday: course.weekday,
                        capacity: course.capacity,
                        url: course.url,
                        categories: course.categories,
                        price_weight: course.price_weight,
                      };

                      const createdCourse = await postCourse({
                        body: newCourse,
                        schoolId: userState.selectedSchool.id,
                      });

                      // add to questionnaires to which the copied course is linked
                      course.questionnaires.forEach((questionnaire: any) => {
                        questionnaires.forEach(
                          async (questionnaireBase: any) => {
                            if (questionnaire.id === questionnaireBase.id) {
                              let newCourseIds = questionnaireBase.courses.map(
                                (course: any) => {
                                  return course._id;
                                }
                              );
                              newCourseIds.push(createdCourse.id);
                              await putQuestionnaire({
                                questionnaireId: questionnaireBase.id,
                                body: { course_ids: newCourseIds },
                              });
                            }
                          }
                        );
                      });

                      sessions.map(async (session: any) => {
                        const newSession = {
                          name: session.name,
                          description: session.description,
                          price_weight: session.price_weight,
                          room_id: session.room_id,
                          start_at: session.start_at,
                          end_at: session.end_at,
                        };

                        const createdSession = await postSession({
                          body: newSession,
                          courseId: createdCourse.id,
                        });

                        // add to questionnaires to which the copied session is linked
                        session.questionnaires.forEach((questionnaire: any) => {
                          questionnaires.forEach(
                            async (questionnaireBase: any) => {
                              if (questionnaire.id === questionnaireBase.id) {
                                let newSessionIds =
                                  questionnaireBase.sessions.map(
                                    (session: any) => {
                                      return session._id;
                                    }
                                  );
                                newSessionIds.push(createdSession.id);
                                await putQuestionnaire({
                                  questionnaireId: questionnaireBase.id,
                                  body: { session_ids: newSessionIds },
                                });
                              }
                            }
                          );
                        });
                      });

                      getCourses({ schoolId: userState.selectedSchool.id });
                    }
                  }
                },
              },
              // {
              //   icon: "question_answer",
              //   tooltip: "Select Questionnaire",
              //   onClick: async (event, rowData) => {
              //     setSelectedCourseId(rowData.id);
              //     setQuestionnaireSelectOpen(true);
              //   }
              // }
            ]}
            // localization={{
            //   header: { actions: "" }
            // }}
            onRowClick={(event, rowData: any) => {
              if (rowData) {
                const selectedSchool: ISchool = schools.filter(
                  (item: { id: unknown }) => item.id === rowData.school_id
                )[0];
                selectSchool(selectedSchool);

                props.history.push({
                  pathname: `/course/${rowData.id}`,
                });
              }
            }}
          />
        </div>
      </main>
      {/* <QuestionnaireSelectDialog
        courseId={selectedCourseId}
        open={questionnaireSelectOpen}
        handleClose={() => {
          setQuestionnaireSelectOpen(false);
        }}
      /> */}
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Course));
