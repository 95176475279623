import * as Yup from "yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { IQuestionnaire, IRoom, ITemplate } from "../../../../interface";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { Fragment, useEffect, useState } from "react";
import {
  useGetQuestionnaire,
  usePutQuestionnaire,
} from "../../fetch/questionnaire";

import Add from "@material-ui/icons/Add";
import ChipInput from "material-ui-chip-input";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import SchoolSelect from "../school/SchoolSelect";
import Select from "@material-ui/core/Select";
import { TextField } from "formik-material-ui";
import { intToWeekdayJa } from "../../../../enum";
import { stylesForm } from "../../css";
import { useGetRoom } from "../../fetch/room";
import { useGetTemplate } from "../../fetch/template";
import { usePostCourse } from "../../fetch/course";
import { usePostProduct } from "../../fetch/product";
import { usePostSession } from "../../fetch/session";
import useUserStateContext from "../../userstate";
import { withStyles } from "@material-ui/styles";

const ProductFreeSchema = Yup.object().shape({
  schoolId: Yup.string().required(),
  name: Yup.string().required(),
  productDescription: Yup.string().required(),
  courseDescription: Yup.string(),
  open_at: Yup.date().required(),
  term: Yup.string(),
  tags: Yup.array(),
  email_template_id: Yup.string(),
});

const ProductFreeCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { rooms, getRooms } = useGetRoom();
  const { postProduct } = usePostProduct();
  const { postCourse } = usePostCourse();
  const { postSession } = usePostSession();
  const { templates, getTemplates } = useGetTemplate();
  const { questionnaires, getQuestionnaires } = useGetQuestionnaire();
  const { putQuestionnaire } = usePutQuestionnaire();

  interface ISchedule {
    id: number;
    start_at: Date | null;
    end_at: Date | null;
    room: IRoom | null;
  }
  const [schedule, setSchedule] = useState<ISchedule[]>([
    { id: 0, start_at: null, end_at: null, room: null },
  ]);

  useEffect(() => {
    getTemplates({});
    getQuestionnaires({});
  }, []);

  useEffect(() => {
    if (userState.selectedSchool) {
      getRooms({ schoolId: userState.selectedSchool.id });
    }
  }, [userState, props.history]);

  interface IFormValues {
    schoolId: any;
    name: string;
    productDescription: string;
    courseDescription: string;
    open_at: Date | null;
    term: string;
    tags: string[];
    email_template_id: string;
    questionnaire_id: string | null;
  }

  const initialValues: IFormValues = {
    schoolId: userState.selectedSchool ? userState.selectedSchool.id : "",
    name: props.selectedFreeProduct ? props.selectedFreeProduct.name : "",
    productDescription: props.selectedFreeProduct
      ? props.selectedFreeProduct.description
      : "",
    courseDescription: props.selectedFreeProduct
      ? props.selectedFreeProduct.courses.length > 0 &&
        props.selectedFreeProduct.courses[0].description
      : "",
    open_at: null,
    term: "",
    tags: props.selectedFreeProduct ? props.selectedFreeProduct.tags : [],
    email_template_id: props.selectedFreeProduct?.email_template_id
      ? props.selectedFreeProduct.email_template_id
      : null,
    questionnaire_id: null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProductFreeSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);
        for (let eachSchedule of schedule) {
          if (
            eachSchedule.start_at === null ||
            eachSchedule.end_at === null ||
            eachSchedule.room === null
          ) {
            alert(
              `${eachSchedule.start_at} 〜 ${eachSchedule.end_at} に ${
                eachSchedule.room && eachSchedule.room.name
              } にて実施予定のスケジュールは情報が不足しているため、スキップします。 schedule starts at ${
                eachSchedule.start_at
              } and ends at ${eachSchedule.end_at} @room : ${
                eachSchedule.room && eachSchedule.room.name
              } has information missing. registration will be skipped fot this schedule.`
            );
            return null;
          }
          console.log(values);
          const newCourse = await postCourse({
            body: {
              name: `${values.name} ${
                eachSchedule.start_at.getMonth() + 1
              }/${eachSchedule.start_at.getDate()}${
                intToWeekdayJa[eachSchedule.start_at.getDay()]
              }`,
              description: values.courseDescription,
              code: values.name,
              term: values.term,
              weekday: eachSchedule.start_at && eachSchedule.start_at.getDay(),
              capacity: eachSchedule.room.capacity,
              price_weight: 0,
              categories: [],
            },
            schoolId: userState.selectedSchool && userState.selectedSchool.id,
          });

          if (values.questionnaire_id) {
            let newCourseIds = questionnaires
              .filter((questionnaire: any) => {
                return questionnaire.id === values.questionnaire_id;
              })[0]
              .courses.map((course: any) => {
                return course._id;
              });
            newCourseIds.push(newCourse.id);
            await putQuestionnaire({
              questionnaireId: values.questionnaire_id,
              body: {
                course_ids: newCourseIds,
              },
            });
          }

          await postSession({
            body: {
              course_id: newCourse.id,
              name: values.name,
              description: "",
              room_id: eachSchedule.room.id,
              start_at: eachSchedule.start_at,
              end_at: eachSchedule.end_at,
              price_weight: 0,
            },
            courseId: newCourse.id,
          });

          await postProduct({
            body: {
              name: values.name,
              description: values.productDescription,
              code: "",
              course_ids: [newCourse.id],
              price: 0,
              open_at: values.open_at,
              close_at:
                eachSchedule.end_at &&
                new Date(
                  eachSchedule.end_at.setHours(
                    eachSchedule.end_at.getHours() - 1
                  )
                ),
              hidden: false,
              application_conditions: [],
              tags: values.tags,
              email_template_id: values.email_template_id,
            },
          });
        }

        resetForm();
        setSubmitting(false);
        props.handleClose();
      }}
      render={({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Create Free Products
            </DialogTitle>
            {isSubmitting && <LinearProgress />}
            <Divider />
            <DialogContent>
              <Form>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <SchoolSelect />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      margin="dense"
                      name="name"
                      type="text"
                      label="Name"
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      margin="dense"
                      name="productDescription"
                      type="text"
                      label="Product Description"
                      component={TextField}
                      fullWidth
                      multiline
                    />
                    <Typography variant="caption">
                      This field will be used in product detail page top
                      section. Please be noted that free products with same tag
                      starting `free_` will be bundled in one product detail
                      page and description of one of those products will be
                      shown.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      margin="dense"
                      name="courseDescription"
                      type="text"
                      label="Course Description"
                      component={TextField}
                      fullWidth
                      multiline
                    />
                    <Typography variant="caption">
                      This field may only be used in email template.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ChipInput
                      // value={values.tags}
                      label="Tags"
                      defaultValue={values.tags}
                      onChange={(chips) => {
                        setFieldValue("tags", chips);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDateTimePicker
                        name="open_at"
                        label="Open at"
                        value={values.open_at}
                        onChange={(date) => setFieldValue("open_at", date)}
                        format="yyyy/MM/dd HH:mm"
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      margin="dense"
                      name="term"
                      type="text"
                      label="Target Term"
                      component={TextField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="email_template_id"
                      label="Email Template"
                      select
                      // margin="normal"
                      component={TextField}
                      fullWidth
                    >
                      {templates.map((template: ITemplate) => (
                        <MenuItem key={template.id} value={template.id}>
                          {`${template.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>

                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.mt2}
                  >
                    <Grid item>
                      <Typography>Session Schedule</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          setSchedule([
                            ...schedule,
                            {
                              id: schedule.length,
                              start_at: null,
                              end_at: null,
                              room: null,
                            },
                          ]);
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>

                  {schedule &&
                    schedule.map((eachSchedule: ISchedule, index) => {
                      return (
                        <Fragment>
                          <Grid item xs={12} md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDateTimePicker
                                // name="open_at"
                                label="Start at"
                                value={eachSchedule.start_at}
                                onChange={(date) => {
                                  let newSchedule: ISchedule[] = [];
                                  schedule.map((element) => {
                                    const newElement =
                                      element.id === index
                                        ? {
                                            id: element.id,
                                            start_at: date,
                                            end_at: element.end_at,
                                            room: element.room,
                                          }
                                        : element;
                                    newSchedule.push(newElement);
                                    setSchedule(newSchedule);
                                  });
                                }}
                                format="yyyy/MM/dd HH:mm"
                                fullWidth
                                disabled={isSubmitting}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDateTimePicker
                                // name="open_at"
                                label="End at"
                                value={eachSchedule.end_at}
                                onChange={(date) => {
                                  let newSchedule: ISchedule[] = [];
                                  schedule.map((element) => {
                                    const newElement =
                                      element.id === index
                                        ? {
                                            id: element.id,
                                            start_at: element.start_at,
                                            end_at: date,
                                            room: element.room,
                                          }
                                        : element;
                                    newSchedule.push(newElement);
                                    setSchedule(newSchedule);
                                  });
                                }}
                                format="yyyy/MM/dd HH:mm"
                                fullWidth
                                disabled={isSubmitting}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <InputLabel>Room</InputLabel>
                            <Select
                              className={classes.w100}
                              value={eachSchedule.room && eachSchedule.room}
                              onChange={(event: any) => {
                                let newSchedule: ISchedule[] = [];
                                schedule.map((element) => {
                                  const newElement =
                                    element.id === index
                                      ? {
                                          id: element.id,
                                          start_at: element.start_at,
                                          end_at: element.end_at,
                                          room: event.target.value,
                                        }
                                      : element;
                                  newSchedule.push(newElement);
                                  setSchedule(newSchedule);
                                });
                              }}
                            >
                              <MenuItem value={""}>No Room</MenuItem>
                              {rooms &&
                                rooms.map((room: any) => (
                                  <MenuItem value={room}>{room.name}</MenuItem>
                                ))}
                            </Select>
                          </Grid>
                        </Fragment>
                      );
                    })}

                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="questionnaire_id"
                      label="Questionnaire for Course"
                      select
                      // margin="normal"
                      component={TextField}
                      fullWidth
                    >
                      {questionnaires.map((questionnaire: IQuestionnaire) => (
                        <MenuItem
                          key={questionnaire.id}
                          value={questionnaire.id}
                        >
                          {`${questionnaire.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                color="default"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                // disabled={}
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  submitForm();
                  // props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

ProductFreeCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedFreeProduct: PropTypes.object.isRequired,
};

export default withStyles(stylesForm)(ProductFreeCreateDialog);
