import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { schoolNamesDict } from "../../../../enum";
import { ICourse, ITemplate } from "../../../../interface";
import { stylesForm } from "../../css";
import {
  useGetCourse,
  useGetCourses,
  useGetCoursesAdmission,
} from "../../fetch/course";
import { usePostProduct } from "../../fetch/product";
import { useGetTemplate } from "../../fetch/template";
import useUserStateContext from "../../userstate";
import {
  doubleToSingleArray,
  toDoubleDigits,
  renderTerm,
  convertToSchedule,
  extractKeywordfromProductName
} from "../../util";
import SchoolSelect from "../school/SchoolSelect";

const ProductDSIkuseiSchema = Yup.object().shape({
  description: Yup.string().required(),
  term: Yup.string(),
  openAtBCf: Yup.date().required(),
  openAtBCs: Yup.date().required(),
  openAtBS: Yup.date().required(),
  openAtAD: Yup.date().required(),
  openAtINTEG: Yup.date().required(),
  closeAtBCf: Yup.date().required(),
  closeAtBCs: Yup.date().required(),
  closeAtBS: Yup.date().required(),
  closeAtAD: Yup.date().required(),
  closeAtINTEG: Yup.date().required(),
  email_template_id: Yup.string().required(),
});

const ProductDSIkuseiCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();
  const { coursesAdmission, getCoursesAdmission } = useGetCoursesAdmission();
  const { getCourseSync } = useGetCourse();
  const { postProduct } = usePostProduct();
  const [terms, setTerms] = useState<any[]>([]);
  const [term, setTerm] = useState("");
  const { templates, getTemplates } = useGetTemplate();

  useEffect(() => {
    if (props.open) {
      if (userState.selectedSchool) {
        getCourses({ schoolId: userState.selectedSchool.id });
      }
      getCoursesAdmission({});
      getTemplates({});
    }
  }, [props.open, userState.selectedSchool]);

  useEffect(() => {
    const terms = courses
      .filter((course: any) => course.term != null)
      .map((course: any) => course.term)
      .sort((a: string, b: string) => (a < b ? 1 : -1));
    const termsUnique = Array.from(new Set(terms));
    setTerms(termsUnique);
  }, [courses]);

  interface IFormValues {
    schoolId: any;
    description: string;
    term: string;
    openAtBCf: Date | null;
    openAtBCs: Date | null;
    openAtBS: Date | null;
    openAtAD: Date | null;
    openAtINTEG: Date | null;
    closeAtBCf: Date | null;
    closeAtBCs: Date | null;
    closeAtBS: Date | null;
    closeAtAD: Date | null;
    closeAtINTEG: Date | null;
    entranceExamIds: string[];
    email_template_id: string;
  }

  const initialValues: IFormValues = {
    schoolId: userState.selectedSchool ? userState.selectedSchool.id : "",
    description: "",
    term: "",
    openAtBCf: null,
    openAtBCs: null,
    openAtBS: null,
    openAtAD: null,
    openAtINTEG: null,
    closeAtBCf: null,
    closeAtBCs: null,
    closeAtBS: null,
    closeAtAD: null,
    closeAtINTEG: null,
    entranceExamIds: [],
    email_template_id: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProductDSIkuseiSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);

        const breakFunction = (alertMessage: string) => {
          alert(alertMessage);
          resetForm();
          props.handleClose();
          throw "error";
        };

        const admissions = coursesAdmission.filter(
          (course: any) => course?.price_weight > 0
        );
        const admissionsFree = coursesAdmission.filter(
          (course: any) => course.price_weight === 0
        );
        console.log(`admissions`, admissions);
        console.log(`admissionsFree`, admissionsFree);
        if (admissions.length !== 1 || admissionsFree.length !== 1) {
          if (admissions.length < 1) {
            breakFunction(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）がありません。先にそのコースを登録してください。Admission course with price_weight > zero is required. Register the course first.`
            );
          } else if (admissions.length > 1) {
            breakFunction(
              `price_weightが0より大きい入学金コース（categoriesの中のAdmissionにチェックが入ったコース）が2つ以上あります。想定していない状態です。該当するコースを削除するか、どちらかのコースのprice_weightをnull（0ではなく空）にするか、categoriesのAdmissionのチェックを外してください。More than two Admission course with price_weight > zero are found.`
            );
          }
          if (admissionsFree.length < 1) {
            breakFunction(
              `price_weightが0の無料の入学金コース（categoriesの中のAdmissionにチェックが入ったコース）がありません。先にそのコースを登録してください。Admission course with price_weight == zero is required. Register the course first.`
            );
          } else if (admissionsFree.length > 1) {
            breakFunction(
              `price_weightが0の無料の入学金コース（categoriesの中のAdmissionにチェックが入ったコース）が2つ以上あります。想定していない状態です。該当するコースを削除するか、どちらかのコースのprice_weightをnull（0ではなく空）にするか、categoriesのAdmissionのチェックを外してください。More than two Admission course with price_weight == zero are found.`
            );
          }
        }
        const admission = admissions[0];
        const admissionFree = admissionsFree[0];

        // ~オンライン will also be considered as ikusei
        const coursesBCfirst = courses.filter((course: any) => {
          return (
            course.code === "データサイエンティストブートキャンプステップ前期" &&
            course.term === values.term
          );
        });
        const coursesBCsecond = courses.filter((course: any) => {
          return (
            course.code === "データサイエンティストブートキャンプステップ後期" &&
            course.term === values.term
          );
        });
        const coursesBS = courses.filter((course: any) => {
          return (
            course.code === "データサイエンティストベーシックステップ" &&
            course.term === values.term
          );
        });
        const coursesAD = courses.filter((course: any) => {
          return (
            course.code === "データサイエンティストアドバンスステップ" &&
            course.term === values.term
          );
        });
        const coursesINTEG = courses.filter((course: any) => {
          return (
            course.code === "データサイエンティストインテグレーションステップ" &&
            course.term === values.term
          );
        });

        const getWeekdayTimes = (courses: any) => {
          return Array.from(
            new Set(
              courses.map((course: any) => {
                if (course.weekday) {
                  const time =
                    course.name.match(/\d+/g).length > 0 &&
                    course.name.match(/\d+/g).length === 1
                      ? `${course.name.match(/\d+/g)[0]}:00`
                      : course.name.match(/\d+/g).join(":");
                  return `${course.weekday}-${time}`;
                } else {
                  return `7`;
                }
              })
            )
          ).sort();
        };

        const weekdayTimes: any[] = getWeekdayTimes(coursesBCfirst);
        let newProducts: any = [];

        const schoolName = schoolNamesDict.find(
          (schoolNameDict: { name: string; nameJa: string }) => 
            schoolNameDict.nameJa === userState.selectedSchool?.name
        )?.name;

        const generateProduct = async ({
          weekday,
          courses,
          openAt,
          closeAt,
          tags,
          application_conditions,
          hidden,
          email_template_id,
        }: {
          weekday: string;
          courses: ICourse[];
          openAt: any;
          closeAt: any;
          tags: string[];
          application_conditions: object;
          hidden: boolean;
          email_template_id: string;
        }) => {
          const coursesWithSessions: ICourse[] = await Promise.all(
            courses.map(
              async (course: ICourse) =>
                await getCourseSync({ courseId: course.id })
            )
          );
          const courseNames = coursesWithSessions
            .map((course: ICourse) => {
              return extractKeywordfromProductName(course.name)
            })
            .join(" / ");
          const name = `【${renderTerm(values.term)}】【データサイエンティスト育成講座】【 ${convertToSchedule(weekday)}】 ${courseNames}`;
          const price = coursesWithSessions
            .map((course: any) => {
              if (course.price_weight === null) {
                breakFunction(
                  `"${course.name}"というコースにprice_weight（価格）が登録されていません。先にprice_weightを登録してください。Course: "${course.name}" does not have price_weight. Set price_weight first.`
                );
              }
              return course.price_weight;
            })
            .reduce((p: number, c: number) => p + c);
          const courseIds = coursesWithSessions.map(
            (course: ICourse) => course.id
          );

          let start_ats = doubleToSingleArray(
            coursesWithSessions
              .filter(
                (course: any) => !course.categories.includes("Admission")
              )
              .map(
                (course: any) => course.sessions
              )
          );

          start_ats = start_ats.map((session: any) => {
            let newDate = new Date(session.start_at);
            return `${toDoubleDigits(
              newDate.getHours().toString()
            )}:${toDoubleDigits(newDate.getMinutes().toString())}`;
          });
          start_ats.sort((a: string, b: string) => {
            return a > b ? 1 : -1;
          });
          start_ats.length === 0 &&
            breakFunction(
              `セッションが登録されていないコースがあります。先にセッションを登録してください。Course without session was found.`
            );
          tags.push(`weekday_${weekday}`);

          newProducts.push({
            name: name,
            description: values.description,
            code: "",
            price: price,
            open_at: openAt,
            close_at: closeAt,
            hidden: hidden,
            course_ids: courseIds,
            tags: tags,
            application_conditions: application_conditions,
            email_template_id: email_template_id,
          });
        };
        console.log(weekdayTimes);
        for (let weekdayTime of weekdayTimes) {
          const courseBCfirst = coursesBCfirst.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseBCsecond = coursesBCsecond.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseBS = coursesBS.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseAD = coursesAD.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          const courseINTEG = coursesINTEG.filter((course: any) => {
            return getWeekdayTimes([course])[0] === weekdayTime;
          })[0];
          console.log(courseBCfirst);
          console.log(courseBCsecond);
          console.log(courseBS);
          console.log(courseAD);
          console.log(courseINTEG);
          !courseBCfirst &&
            breakFunction(
              `データサイエンティストブートキャンプステップ前期の曜日情報が正しく登録されていないようです。Bootcamp first step for weekday ${weekdayTime} was not found`
            );
          !courseBCsecond &&
            breakFunction(
              `データサイエンティストブートキャンプステップ後期の曜日情報が正しく登録されていないようです。Bootcamp second step for weekday ${weekdayTime} was not found`
            );
          !courseBS &&
            breakFunction(
              `データサイエンティストベーシックステップの曜日情報が正しく登録されていないようです。Basic step for weekday ${weekdayTime} was not found`
            );
          !courseAD &&
            breakFunction(
              `データサイエンティストアドバンスステップの曜日情報が正しく登録されていないようです。Advance step for weekday ${weekdayTime} was not found`
            );
          !courseINTEG &&
            breakFunction(
              `データサイエンティストインテグレーションステップの曜日情報が正しく登録されていないようです。Integration step for weekday ${weekdayTime} was not found`
            );

          const productConfigs = [
            // all w/ free admission
            {
              weekday: weekdayTime,
              courses: [
                admissionFree,
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
                courseINTEG,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_all`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // all w/o admisson
            {
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
                courseINTEG,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_all`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp first w/ admission
            {
              weekday: weekdayTime,
              courses: [
                admission,
                courseBCfirst,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcf`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp first
            {
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcf`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp w/ addmisiion
            {
              weekday: weekdayTime,
              courses: [
                admission,
                courseBCfirst,
                courseBCsecond,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcfs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp w/o addmisiion
            {
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcfs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp/Basic w/ addmisiion
            {
              weekday: weekdayTime,
              courses: [
                admission,
                courseBCfirst,
                courseBCsecond,
                courseBS,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcbs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp/Basic w/o admission
            {
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcbs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp/Basic/Advance w/ admission
            {
              weekday: weekdayTime,
              courses: [
                admission,
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcbsad`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp/Basic/Advance w/o addmisiion
            {
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
              ],
              openAt: values.openAtBCf,
              closeAt: values.closeAtBCf,
              tags: [
                `ds-ikusei`,
                `type_bcbsad`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Bootcamp second w/o addmisiion
            {
              weekday: weekdayTime,
              courses: [
                courseBCsecond,
              ],
              openAt: values.openAtBS,
              closeAt: values.closeAtBS,
              tags: [
                `ds-ikusei`,
                `type_bcs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "データサイエンティストブートキャンプステップ前期",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Basic w/o admision
            {
              weekday: weekdayTime,
              courses: [
                courseBS,
              ],
              openAt: values.openAtBS,
              closeAt: values.closeAtBS,
              tags: [
                `ds-ikusei`,
                `type_bs`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "データサイエンティストブートキャンプステップ後期",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Advance w/o admission
            {
              weekday: weekdayTime,
              courses: [
                courseAD,
              ],
              openAt: values.openAtBS,
              closeAt: values.closeAtBS,
              tags: [
                `ds-ikusei`,
                `type_ad`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "データサイエンティストベーシックステップ",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            },
            // Integration w/o admission
            {
              weekday: weekdayTime,
              courses: [
                courseINTEG,
              ],
              openAt: values.openAtINTEG,
              closeAt: values.closeAtINTEG,
              tags: [
                `ds-ikusei`,
                `type_integ`,
                `term_${term}`,
                `school_${schoolName}`,
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "データサイエンティストアドバンスステップ",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            },
          ];

          for (const config of productConfigs) {
            await generateProduct(config);
          }
        }
        console.log(JSON.stringify(newProducts));

        if (
          window.confirm(
            `${
              values.entranceExamIds.length === 0
                ? `入学試験コースが選択されていません。生成されるプロダクトは全ての受講生が申し込めるようになります。No Entrance Exam was selected. Products will be available for all students.\n`
                : ``
            }${
              newProducts.length
            } 個のプロダクトが生成されます。よろしいですか？ Totally ${
              newProducts.length
            } new products will be created. Confirm to proceed?`
          )
        ) {
          for (const product of newProducts) {
            await postProduct({ body: product });
          }
        }

        setSubmitting(false);
        props.handleClose();
      }}
      render={({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">
              Create Ikusei Products
            </DialogTitle>
            {isSubmitting && <LinearProgress />}
            <Divider />
            <DialogContent>
              <Fragment>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <SchoolSelect />
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="description"
                      label="Product Description"
                      component={TextField}
                      fullWidth
                      multiline
                    ></Field>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label-term">Term</InputLabel>
                      <Select
                        value={values.term}
                        onChange={(event: any) => {
                          setFieldValue("term", event.target.value);
                          setTerm(event.target.value);
                        }}
                        name="term"
                        disabled={isSubmitting}
                      >
                        {terms.map((term: any) => {
                          return <MenuItem value={term}>{term}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  {[
                    { name: "openAtBCf", label: "Open at of Bootcamp first" },
                    { name: "closeAtBCf", label: "Close at of Bootcamp first" },
                    { name: "openAtBCs", label: "Open at of Bootcamp second" },
                    { name: "closeAtBCs", label: "Close at of Bootcamp second" },
                    { name: "openAtBS", label: "Open at of Basic" },
                    { name: "closeAtBS", label: "Close at of Basic" },
                    { name: "openAtAD", label: "Open at of Advance" },
                    { name: "closeAtAD", label: "Close at of Advance" },
                    { name: "openAtINTEG", label: "Open at of Integration" },
                    { name: "closeAtINTEG", label: "Close at of Integration" },
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={6} className={classes.mt1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            name={item.name}
                            label={item.label}
                            value={eval(`values.${item.name}`)}
                            onChange={(date) => setFieldValue(item.name, date)}
                            format="yyyy/MM/dd HH:mm"
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} className={classes.mt1}>
                    <FormControl className={classes.w100}>
                      <InputLabel>
                        Entrance Exams for the Term Selected Above
                      </InputLabel>
                      <Select
                        multiple
                        value={values.entranceExamIds}
                        name="code"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setFieldValue(
                            "entranceExamIds",
                            event.target.value as string[]
                          );
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                        // MenuProps={MenuProps}
                        disabled={isSubmitting}
                      >
                        {courses
                          ?.filter((course: any) => {
                            return course.name.includes("試験");
                          })
                          .map((course: any) => (
                            <MenuItem key={course.id} value={course.id}>
                              <ListItemText primary={`${course.name}`} />
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        After students complete one of the courses selected
                        here, products BC, BCBS, ALL will be available for them.
                        Only courses whose name include "試験" are listed up
                        here.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="email_template_id"
                      label="Email Template"
                      select
                      // margin="normal"
                      component={TextField}
                      fullWidth
                    >
                      {templates.map((template: ITemplate) => (
                        <MenuItem key={template.id} value={template.id}>
                          {`${template.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                color="default"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                // disabled={}
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  submitForm();
                  // props.handleClose();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

ProductDSIkuseiCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(stylesForm)(ProductDSIkuseiCreateDialog);
