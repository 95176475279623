import React from "react";
import { Link } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ClassIcon from "@material-ui/icons/Class";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PeopleIcon from "@material-ui/icons/People";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import WorkIcon from "@material-ui/icons/Work";
import EmailIcon from "@material-ui/icons/Email";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import firebase from "../firebase";
import useUserStateContext from "../userstate";
import HeaderBar from "./HeaderBar";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`
    }
    // toolbar: theme.mixins.toolbar
  })
);

interface ResponsiveDrawerProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container?: Element;
}

export default function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const classes = useStyles();
  const { userState } = useUserStateContext();

  return userState.signedIn === true ? (
    <div className={classes.root}>
      <HeaderBar />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div />
          <List>
            <ListItem color="primary" component={Link} to="/">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem color="primary" component={Link} to="/school">
              <ListItemIcon>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText primary="School" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/course">
              <ListItemIcon>
                <ClassIcon />
              </ListItemIcon>
              <ListItemText primary="Course" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/product">
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Product" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/order">
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Order" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/student">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Student" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/questionnaire">
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary="Questionnaire" />
            </ListItem>
            <ListItem color="primary" component={Link} to="/template">
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Email Template" />
            </ListItem>
          </List>
          <Divider />

          <List>
            <ListItem
              color="primary"
              component={Link}
              to="/"
              onClick={async () => {
                await firebase.logout();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </Drawer>
      </nav>
    </div>
  ) : (
    <HeaderBar />
  );
}
