import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";

import { useGetStudents } from "../../fetch/student";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";

const StudentSelectDialog = (props: any) => {
  const { userState } = useUserStateContext();
  const { students, getStudents } = useGetStudents();

  useEffect(() => {
    userState.selectedSchool
      ? getStudents({ schoolId: userState.selectedSchool.id })
      : getStudents({});
  }, [userState, props.history]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Student
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <MaterialTable
            columns={[
              {
                title: "Student ID",
                field: "id",
              },
              {
                title: "Name",
                field: "full_name",
              },
              {
                title: "Name Kana",
                field: "full_name_kana",
              },

              { title: "E-mail", field: "email" },
              { title: "Gender", field: "gender" },
              { title: "tel_mobile_phone", field: "tel_mobile_phone" },
              { title: "tel_emergency", field: "tel_emergency" },
            ]}
            data={students && students}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa",
              },
              showTitle: false,
              pageSize: 20,
              pageSizeOptions: [20, 40, 60],
              searchFieldAlignment: "left",
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Student List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true,
            }}
            components={{
              Container: Fragment,
            }}
            onRowClick={(event, rowData: any) => {
              props.setStudent(rowData);
              props.setFieldValue("student_id", rowData.id);
              props.handleClose();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

StudentSelectDialog.propTypes = {
  setStudent: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StudentSelectDialog;
