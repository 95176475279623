import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { schoolNamesDict } from "../../../../enum";
import { ICourse, ITemplate } from "../../../../interface";
import { stylesForm } from "../../css";
import {
  useGetCourse,
  useGetCourses,
  useGetCoursesAdmission,
} from "../../fetch/course";
import { usePostProduct } from "../../fetch/product";
import { useGetTemplate } from "../../fetch/template";
import useUserStateContext from "../../userstate";
import { renderTerm, convertToSchedule, extractKeywordfromProductName } from "../../util";
import SchoolSelect from "../school/SchoolSelect";

const ProductAIMLEngSchema = Yup.object().shape({
  description: Yup.string().required(),
  term: Yup.string(),
  openAtBCfirst: Yup.date().required(),
  openAtBCsecond: Yup.date().required(),
  openAtBS: Yup.date().required(),
  openAtAD: Yup.date().required(),
  openAtINTEG: Yup.date().required(),
  closeAtBCfirst: Yup.date().required(),
  closeAtBCsecond: Yup.date().required(),
  closeAtBS: Yup.date().required(),
  closeAtAD: Yup.date().required(),
  closeAtInteg: Yup.date().required(),
  email_template_id: Yup.string().required(),
});

const ProductAIMLEngCreateDialog = (props: any) => {
  const { classes } = props;
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();
  const { coursesAdmission, getCoursesAdmission } = useGetCoursesAdmission();
  const { getCourseSync } = useGetCourse();
  const { postProduct } = usePostProduct();
  const [terms, setTerms] = useState<any[]>([]);
  const [term, setTerm] = useState("");
  // const [AIMLEngWeekDay, setAIMLEngWeekDay] = useState<null | number>(null);
  const { templates, getTemplates } = useGetTemplate();

  useEffect(() => {
    if (props.open) {
      if (userState.selectedSchool) {
        getCourses({ schoolId: userState.selectedSchool.id });
      }
      getCoursesAdmission({});
      getTemplates({});
    }
  }, [props.open, userState.selectedSchool]);

  useEffect(() => {
    const terms = courses
      .filter((course: any) => {
        return course.term;
      })
      .map((course: any) => {
        return course.term;
      })
      .sort((a: string, b: string) => {
        return a < b ? 1 : -1;
      });
    const termsUnique = Array.from(new Set(terms));
    setTerms(termsUnique);
  }, [courses]);

  interface IFormValues {
    schoolId: any;
    description: string;
    term: string;
    openAtBCfirst: Date | null;
    openAtBCsecond: Date | null;
    openAtBS: Date | null;
    openAtAD: Date | null;
    openAtINTEG: Date | null;
    closeAtBCfirst: Date | null;
    closeAtBCsecond: Date | null;
    closeAtBS: Date | null;
    closeAtAD: Date | null;
    closeAtInteg: Date | null;
    entranceExamIds: string[];
    AIMLEngCourseBCfirstId: string;
    AIMLEngCourseBCsecondId: string;
    AIMLEngCourseBSId: string;
    AIMLEngCourseADId: string;
    AIMLEngCourseINTEGId: string;
    email_template_id: string;
  }

  const initialValues: IFormValues = {
    schoolId: userState.selectedSchool ? userState.selectedSchool.id : "",
    description: "",
    term: "",
    openAtBCfirst: null,
    openAtBCsecond: null,
    openAtBS: null,
    openAtAD: null,
    openAtINTEG: null,
    closeAtBCfirst: null,
    closeAtBCsecond: null,
    closeAtBS: null,
    closeAtAD: null,
    closeAtInteg: null,
    entranceExamIds: [],
    AIMLEngCourseBCfirstId: "",
    AIMLEngCourseBCsecondId: "",
    AIMLEngCourseBSId: "",
    AIMLEngCourseADId: "",
    AIMLEngCourseINTEGId: "",
    email_template_id: "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProductAIMLEngSchema}
      onSubmit={
        async (values, { resetForm, setSubmitting }) => {
          setSubmitting(true);

          const admissions = coursesAdmission.filter(
            (course: any) => course?.price_weight > 0
          );
          const admissionsFree = coursesAdmission.filter(
            (course: any) => course.price_weight === 0
          );
          console.log(`admissions`, admissions);
          console.log(`admissionsFree`, admissionsFree);
          if (admissions.length !== 1 || admissionsFree.length !== 1) {
            alert(
              "有料の入学金と無料の入学金はシステム上それぞれ1つのみの作成としてください"
            );
            throw "Error";
          }
          const admission = admissions[0];
          const admissionFree = admissionsFree[0];

          const getWeekdayTimes = (courses: ICourse[]) => {
            return Array.from(
              new Set(
                courses.map((course: any) => {
                  if (course.weekday) {
                    const time =
                      course.name.match(/\d+/g).length > 0 &&
                      course.name.match(/\d+/g).length === 1
                        ? `${course.name.match(/\d+/g)[0]}:00`
                        : course.name.match(/\d+/g).join(":");
                    return `${course.weekday}-${time}`;
                  } else {
                    return `7`;
                  }
                })
              )
            ).sort();
          };

          let newProducts: any = [];

          const schoolName = schoolNamesDict.find(
            (schoolNameDict: { name: string; nameJa: string }) => {
              return schoolNameDict.nameJa === userState.selectedSchool?.name;
            }
          )?.name;

          const generateProduct = async ({
            weekday,
            courses,
            openAt,
            closeAt,
            tags,
            application_conditions,
            hidden,
            email_template_id,
          }: {
            weekday: string;
            courses: ICourse[];
            openAt: any;
            closeAt: any;
            tags: string[];
            application_conditions: object;
            hidden: boolean;
            email_template_id: string;
          }) => {
            const coursesWithSessions: ICourse[] = await Promise.all(
              courses.map(
                async (course: ICourse) =>
                  await getCourseSync({ courseId: course.id })
              )
            );
            const courseNames = coursesWithSessions
              .map((course: ICourse) => {
                return extractKeywordfromProductName(course.name)
              })
              .join(" / ");
            const name = `【${renderTerm(values.term)}】【生成AI・機械学習エンジニア育成講座】【${convertToSchedule(weekday)}】 ${courseNames}`;
            const price = coursesWithSessions
              .map((course: ICourse) => {
                if (course.price_weight === null) {
                  alert(
                    `"${course.name}"というコースにprice_weight（価格）が登録されていません。先にprice_weightを登録してください。Course: "${course.name}" does not have price_weight. Set price_weight first.`
                  );
                  throw "Error";
                }
                return course.price_weight;
              })
              .filter((price: number | undefined) => price != null)
              .reduce((p: number, c: number) => p + c);
            const courseIds = coursesWithSessions.map(
              (course: ICourse) => course.id
            );

            coursesWithSessions
              .filter(
                (course: ICourse) => !course.categories.includes("Admission")
              )
              .forEach((course: ICourse) => {
                if (course.sessions?.length === 0) {
                  alert(
                    `"${course.name}"というコースにセッションが登録いません。先にセッションを登録してください。Course without session was found.`
                  );
                  throw "Error";
                }
              });
            tags.push(`weekday_${weekday}`);

            newProducts.push({
              name: name,
              description: values.description,
              code: "",
              price: price,
              open_at: openAt,
              close_at: closeAt,
              hidden: hidden,
              course_ids: courseIds,
              tags: tags,
              application_conditions: application_conditions,
              email_template_id: email_template_id,
            });
          };

          // courseAIMLEngINTEG
          const courseINTEG = courses.find(
            (course: ICourse) =>
              course.term === term &&
              course.code === "生成AI・MLエンジニアインテグレーションステップ" // &&
              // course.weekday === AIMLEngWeekDay
          );
          const weekdayTimes = getWeekdayTimes([courseINTEG]);
          const time = (() => {
            const match = courseINTEG.name.match(/\d+/g);
            return match?.length > 0 ? (match[0] as string) : "";
          })();
          console.log(`time`, time);

          // courseAIMLEngBCfirst
          const courseBCfirst = courses.find(
            (course: ICourse) =>
              course.term === term &&
              course.code === "生成AI・MLエンジニア/データエンジニアブートキャンプステップ前期" // &&
              // course.name.includes(time) // &&
              // course.weekday === AIMLEngWeekDay
          );
          // courseAIMLEngBCsecond
          const courseBCsecond = courses.find(
            (course: ICourse) =>
              course.term === term &&
              course.code === "生成AI・MLエンジニアブートキャンプステップ後期" &&
              course.name.includes(time) // &&
              // course.weekday === AIMLEngWeekDay
          );

          // courseAIMLEngBS
          const courseBS = courses.find(
            (course: ICourse) =>
              course.term === term &&
              course.code === "生成AI・MLエンジニアベーシックステップ" &&
              course.name.includes(time) // &&
              // course.weekday === AIMLEngWeekDay
          );

          // courseAIMLEngAD
          const courseAD = courses.find(
            (course: ICourse) =>
              course.term === term &&
              course.code === "生成AI・MLエンジニアアドバンスステップ" &&
              course.name.includes(time) // &&
              // course.weekday === AIMLEngWeekDay
          );

          // check
          if (!courseBCfirst || !courseBCsecond || !courseBS || !courseAD || !courseINTEG) {
            alert(
              `未登録のコースがあります。登録済みのコース：${courseBCfirst?.name} ${courseBCsecond?.name} ${courseBS?.name} ${courseAD?.name} ${courseINTEG?.name}`
            );
            throw "Error";
          }
          for (let weekdayTime of weekdayTimes) {
            // all courses w/ admission
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
                courseINTEG,
                admissionFree
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_all`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // all courses
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
                courseINTEG
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_all`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first and admission
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                admission
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcf`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcf`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC and admission
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                admission
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcfs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcfs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first and BC second and BS and admission
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                admission
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcbs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first and BC second and BS
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcbs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first and BC second and BS and AD w/ admission
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD,
                admission
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcbsad`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission", progress: "no" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission", progress: "no" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC first and BC second and BS and AD
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCfirst,
                courseBCsecond,
                courseBS,
                courseAD
              ],
              openAt: values.openAtBCfirst,
              closeAt: values.closeAtBCfirst,
              tags: [
                `mle`,
                `type_bcbsad`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions:
                values.entranceExamIds.length > 0
                  ? values.entranceExamIds.map((id: string) => {
                      return {
                        courses: [
                          { id: id, progress: "completed" },
                          { code: "Admission" },
                        ],
                      };
                    })
                  : [{ courses: [{ code: "Admission" }] }],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BC second
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBCsecond
              ],
              openAt: values.openAtBCsecond,
              closeAt: values.closeAtBCsecond,
              tags: [
                `mle`,
                `type_bcs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "生成AI・MLエンジニア/データエンジニアブートキャンプステップ前期",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // BS
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseBS
              ],
              openAt: values.openAtBS,
              closeAt: values.closeAtBS,
              tags: [
                `mle`,
                `type_bs`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "生成AI・MLエンジニアブートキャンプステップ後期",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // AD
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseAD
              ],
              openAt: values.openAtAD,
              closeAt: values.closeAtAD,
              tags: [
                `mle`,
                `type_ad`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "生成AI・MLエンジニアベーシックステップ",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            });

            // Integ
            await generateProduct({
              weekday: weekdayTime,
              courses: [
                courseINTEG
              ],
              openAt: values.openAtINTEG,
              closeAt: values.closeAtInteg,
              tags: [
                `mle`,
                `type_integ`,
                `term_${term}`,
                `school_${schoolName}`
              ],
              application_conditions: [
                {
                  courses: [
                    {
                      code: "生成AI・MLエンジニアアドバンスステップ",
                      school_id: userState.selectedSchool
                        ? userState.selectedSchool.id
                        : null,
                    },
                  ],
                },
              ],
              hidden: false,
              email_template_id: values.email_template_id,
            });
          }

          console.log(JSON.stringify(newProducts));
          if (
            window.confirm(
              `${
                values.entranceExamIds.length === 0
                  ? `入学試験コースが選択されていません。生成されるプロダクトは全ての受講生が申し込めるようになります。No Entrance Exam was selected. Products will be available for all students.\n`
                  : ``
              }${
                newProducts.length
              } 個のプロダクトが生成されます。よろしいですか？ Totally ${
                newProducts.length
              } new products will be created. Confirm to proceed?`
            )
          ) {
            for (const product of newProducts) {
              await postProduct({ body: product });
            }
          }

          setSubmitting(false);
          props.handleClose();
        }
      }
      render={({
        submitForm,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
      }) => (
        <Fragment>
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle id="form-dialog-title">Create AIMLEngineer Products</DialogTitle>
            {isSubmitting && <LinearProgress />}
            <Divider />
            <DialogContent>
              <Fragment>
                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <SchoolSelect />
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="description"
                      label="Product Description"
                      component={TextField}
                      fullWidth
                      multiline
                    ></Field>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className={classes.mt2}>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <InputLabel id="label-term">Term</InputLabel>
                      <Select
                        value={values.term}
                        onChange={(event: any) => {
                          setFieldValue("term", event.target.value);
                          setTerm(event.target.value);
                        }}
                        name="term"
                        disabled={isSubmitting}
                      >
                        {terms.map((term: any) => {
                          return <MenuItem value={term}>{term}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  {[
                    { name: "openAtBCfirst", label: "Open at of Bootcamp First" },
                    { name: "closeAtBCfirst", label: "Close at of Bootcamp First" },
                    { name: "openAtBCsecond", label: "Open at of Bootcamp Second" },
                    { name: "closeAtBCsecond", label: "Close at of Bootcamp Second" },
                    { name: "openAtBS", label: "Open at of Basic" },
                    { name: "closeAtBS", label: "Close at of Basic" },
                    { name: "openAtAD", label: "Open at of Advance" },
                    { name: "closeAtAD", label: "Close at of Advance" },
                    { name: "openAtINTEG", label: "Open at of Integration" },
                    { name: "closeAtInteg", label: "Close at of Integration"},
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={6} className={classes.mt1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            name={item.name}
                            label={item.label}
                            value={eval(`values.${item.name}`)}
                            onChange={(date) => setFieldValue(item.name, date)}
                            format="yyyy/MM/dd HH:mm"
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} className={classes.mt1}>
                    <FormControl className={classes.w100}>
                      <InputLabel>
                        Entrance Exams for the Term Selected Above
                      </InputLabel>
                      <Select
                        multiple
                        value={values.entranceExamIds}
                        name="code"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setFieldValue(
                            "entranceExamIds",
                            event.target.value as string[]
                          );
                        }}
                        input={<Input />}
                        renderValue={(selected) =>
                          (selected as string[]).join(", ")
                        }
                        disabled={isSubmitting}
                      >
                        {courses
                          ?.filter((course: any) => {
                            return course.name.includes("試験");
                          })
                          .map((course: any) => (
                            <MenuItem key={course.id} value={course.id}>
                              <ListItemText primary={`${course.name}`} />
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        After students complete one of the courses selected
                        here, products BC, BCBS, ALL will be available for them.
                        Only courses whose name include "試験" are listed up
                        here.
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* <Grid container spacing={1}>
                    <Grid item xs={12} md={12} className={classes.mt1}>
                      <FormControl className={classes.w100}>
                        <InputLabel>Course INTEG</InputLabel>
                        <Select
                          value={values.AIMLEngCourseINTEGId}
                          name="AIMLEngCourseINTEGId"
                          onChange={(
                            event: React.ChangeEvent<{ value: unknown }>
                          ) => {
                            setFieldValue(
                              "AIMLEngCourseINTEGId",
                              event.target.value as string
                            );
                            setAIMLEngWeekDay(
                              (
                                courses.find(
                                  (course: ICourse) =>
                                    course.id === event.target.value
                                ) as ICourse
                              )?.weekday ?? 7
                            );
                          }}
                          input={<Input />}
                          disabled={isSubmitting}
                        >
                          {courses
                            ?.filter(
                              (course: ICourse) =>
                                course.code ===
                                  "データエンジニアインテグレーションステップ" &&
                                course.term === values.term
                            )
                            .map((course: ICourse) => (
                              <MenuItem key={course.id} value={course.id}>
                                <ListItemText primary={`${course.name}`} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid> */}

                  <Grid item xs={12} sm={12}>
                    <Field
                      margin="dense"
                      type="text"
                      name="email_template_id"
                      label="Email Template"
                      select
                      component={TextField}
                      fullWidth
                    >
                      {templates.map((template: ITemplate) => (
                        <MenuItem key={template.id} value={template.id}>
                          {`${template.title}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Fragment>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                color="default"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  submitForm();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    />
  );
};

ProductAIMLEngCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(stylesForm)(ProductAIMLEngCreateDialog);
