import MaterialTable from "material-table";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { intToWeekday } from "../../../../enum";
import { useGetCourses } from "../../fetch/course";
import useUserStateContext from "../../userstate";
import SchoolSelect from "../school/SchoolSelect";

const CoursesSelectDialog = (props: any) => {
  const { userState } = useUserStateContext();
  const { courses, getCourses } = useGetCourses();

  useEffect(() => {
    userState.selectedSchool
      ? getCourses({ schoolId: userState.selectedSchool.id })
      : getCourses({});
  }, [userState, props.history]);

  useEffect(() => {
    courses.forEach((course: any) => {
      return props.courses
        .map((courseTemp: any) => {
          return courseTemp.id;
        })
        .includes(course.id)
        ? (course.tableData = { checked: true })
        : course;
    });
  }, [props.open]);

  const [columnObject] = useState<object[]>([
    { title: "Name", field: "name" },
    { title: "Code", field: "code" },
    { title: "Term", field: "term" },
    {
      title: "Weekday",
      render: (rowData: any) => {
        return intToWeekday[Number(rowData.weekday)];
      }
    },
    {
      title: "Categories",
      render: (rowData: any) => {
        return rowData.categories.join(", ");
      }
    },
    { title: "Capacity", field: "capacity", type: "numeric" },
    {
      title: "Price Weight",
      field: "price_weight",
      type: "numeric"
    },
    { title: "Capacity", field: "capacity", type: "string" },
    { title: "Remainings", field: "remainings", type: "string" },
    {
      title: "Totally Confirmed",
      field: "totally_confirmed",
      type: "boolean"
    }
  ]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Select Course
              </Typography>
            </Grid>
            <Grid item>
              <SchoolSelect />
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <main>
            <div>
              <MaterialTable
                columns={columnObject}
                data={courses && courses}
                options={{
                  headerStyle: {
                    backgroundColor: "#fafafa"
                  },
                  showTitle: false,
                  pageSize: 5000,
                  pageSizeOptions: [10, 20, 40, 5000],
                  selection: true,
                  searchFieldAlignment: "left",
                  emptyRowsWhenPaging: false,
                  filtering: true
                }}
                onSelectionChange={rows => {
                  props.setCourses(rows);
                }}
                components={{
                  Container: Fragment
                }}
              />
            </div>
          </main>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

CoursesSelectDialog.propTypes = {
  courses: PropTypes.array.isRequired,
  setCourses: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CoursesSelectDialog;
