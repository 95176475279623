import { intToWeekdayShort } from "./../../enum";
export const formatDate = (date: Date) => {
  const y = date.getFullYear();
  const m = ("00" + (date.getMonth() + 1)).slice(-2);
  const d = ("00" + date.getDate()).slice(-2);
  return y + "/" + m + "/" + d;
};

export const formatDateTime = (date: Date, year: boolean = true) => {
  const y = date.getFullYear();
  const m = ("00" + (date.getMonth() + 1)).slice(-2);
  const d = ("00" + date.getDate()).slice(-2);
  const H = ("00" + date.getHours()).slice(-2);
  const M = ("00" + date.getMinutes()).slice(-2);

  const weekday = intToWeekdayShort[date.getDay()];
  return year
    ? y + "/" + m + "/" + d + " " + weekday + " " + H + ":" + M
    : m + "/" + d + " " + weekday + " " + H + ":" + M;
};

export const addTax = (price: number) => {
  return Math.floor(price * 1.1);
};

export const allPossibleCases = (arr: any) => {
  if (arr.length === 1) {
    return arr[0];
  } else {
    let result: any = [];
    let allCasesOfRest: any = allPossibleCases(arr.slice(1)); // recur with the rest of array
    for (let i = 0; i < allCasesOfRest.length; i++) {
      for (let j = 0; j < arr[0].length; j++) {
        allCasesOfRest[i].length === undefined
          ? result.push([arr[0][j], allCasesOfRest[i]])
          : result.push([arr[0][j], ...allCasesOfRest[i]]);
      }
    }
    return result;
  }
};

export const doubleToSingleArray = (doubleArray: any[]) => {
  return doubleArray.reduce((pre: any[], current: any) => {
    pre.push(...current);
    return pre;
  }, []);
};

export const toDoubleDigits = (num: string) => {
  num += "";
  if (num.length === 1) {
    num = "0" + num;
  }
  return num;
};

export const renderTerm = (term: string) => {
  if (!term) {
    return ``;
  } else {
    term.length != 6 && alert(`Format of term : ${term} seems invalid.`);
    const year = term.slice(0, 4);
    let month = term.slice(4, 6);
    month = month.slice(0, 1) === "0" ? month.slice(1, 2) : month;
    return `${year}年${month}月期`;
  }
};

export const convertToSchedule = (input: string): string => {
  const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土",];
  const [dayStr, timeStr] = input.split("-");

  const dayIndex = parseInt(dayStr, 10);
  const dayName = daysOfWeek[dayIndex];

  const [hour] = timeStr.split(":");

  return `${dayName}曜日 ${hour}時スタート`;
}

export const extractKeywordfromProductName = (element: string): string | null => {
  const keywords = [
    "ブートキャンプステップ前期",
    "ブートキャンプステップ後期",
    "ブートキャンプステップ",
    "ベーシックステップ",
    "アドバンスステップ",
    "インテグレーションステップ",
    "入学金（無料）",
    "入学金"
  ];

  for (const keyword of keywords) {
    if (element.includes(keyword)) {
      return keyword;
    }
  }
  return null;
}
