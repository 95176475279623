import React from "react";

import { getUrl } from "../userstate";
import useFetch from "./useFetch";

export const useGetOrder = (): any => {
  const { execute } = useFetch("GET");
  const [orders, setOrders] = React.useState([]);
  const [getOrdersError, setGetOrdersError] = React.useState(null);

  const getOrders = async () => {
    try {
      const fetchedOrders = await execute({
        url: getUrl() + `v1/a/billing/order`,
      });
      const transformedOrders = fetchedOrders.map((order: any) => {
        order.confirmed_at
          ? (order.confirmed = true)
          : (order.confirmed = false);
        order.canceled_at ? (order.canceled = true) : (order.canceled = false);
        order.price_tax_inc = order.price + order.tax;
        order.unpaid_amount = order.price_tax_inc - order.paid_amount;
        for (let i = 0; i < order.questions.length; i++) {
          const key = Object.keys(order.questions[i])[0];
          order[key] = order.questions[i][key];
        }
        // https://docs.google.com/spreadsheets/d/1KnpnwIUOddTN9AHbPYng0yBQ7C2-9DHWZmLgq45Z_Ak/edit#gid=0
        order.dueDate = new Date(order.product?.close_at);
        const confirmedAt = new Date(order.confirmed_at);
        if (order.prepayment === false && order.oneself === true) {
          order.dueDate = null;
        }
        if (order.prepayment === false && order.oneself === false) {
          if (order.confirmed === true) {
            order.dueDate = new Date(
              confirmedAt.getFullYear(),
              confirmedAt.getMonth() + 2,
              0
            );
          } else {
            order.dueDate = null;
          }
        }
        if (order.price === 0) {
          order.dueDate = null;
        }
        return order;
      });
      setOrders(transformedOrders);
    } catch (error) {
      setGetOrdersError(error);
    }
  };

  return { orders, getOrders, getOrdersError };
};

export const useCancelOrder = (): any => {
  const { execute } = useFetch("DELETE");

  const cancelOrder = async ({ orderId }: { orderId: string }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/billing/order/${orderId}`,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { cancelOrder };
};

export const useConfirmOrder = () => {
  const { execute } = useFetch("PUT");

  const confirmOrder = async ({ orderId }: { orderId: string }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/billing/order/${orderId}/confirm`,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { confirmOrder };
};

export const useUnConfirmOrder = () => {
  const { execute } = useFetch("DELETE");

  const unConfirmOrder = async ({ orderId }: { orderId: string }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/billing/order/${orderId}/confirm`,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { unConfirmOrder };
};

export const usePutOrder = () => {
  const { execute } = useFetch("PUT");

  const putOrder = async ({
    orderId,
    body,
  }: {
    orderId: string;
    body: object;
  }) => {
    try {
      await execute({
        url: getUrl() + `v1/a/billing/order/${orderId}`,
        body: body,
      });
    } catch (error) {
      alert(error);
    }
  };

  return { putOrder };
};

export const usePostOrder = () => {
  const { execute } = useFetch("POST");

  const postOrder = async ({
    productId,
    studentId,
    body,
  }: {
    productId: string;
    studentId: string;
    body: object;
  }) => {
    try {
      const newOrder = await execute({
        url: getUrl() + `v1/a/student/${studentId}/product/${productId}/order`,
        body: body,
      });
      return newOrder;
    } catch (error) {
      alert(error);
    }
  };

  return { postOrder };
};
