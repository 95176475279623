import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { Button, Divider, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { stylesMain } from "../../css";
import { useGetTemplate } from "../../fetch/template";
import useUserStateContext from "../../userstate";
import { formatDateTime } from "../../util";

const Template = (props: any) => {
  const { classes } = props;
  const { userState, selectSchool } = useUserStateContext();
  const { templates, getTemplates } = useGetTemplate();

  const [columnObject] = useState<object[]>([
    { title: "Title", field: "title" },
    {
      title: "Body",
      field: "body",
      render: (rowData: any) => {
        return rowData.body.length > 100
          ? `${rowData.body.slice(0, 100)} ...`
          : rowData.body;
      }
    }
  ]);

  useEffect(() => {
    userState.signedIn === false && props.history.push("/login");
    getTemplates({});
  }, []);

  return (
    <Fragment>
      <main className={classes.main}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Typography component="h1" variant="h6">
                Email Template
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/template/create"
                  className={classes.createButton}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <div className={classes.table}>
          <MaterialTable
            columns={columnObject}
            data={templates && templates}
            options={{
              headerStyle: {
                backgroundColor: "#fafafa"
              },
              showTitle: false,
              pageSize: 20,
              pageSizeOptions: [20, 40, 60],
              searchFieldAlignment: "left",
              actionsColumnIndex: 99,
              emptyRowsWhenPaging: false,
              exportButton: true,
              exportFileName: `S2M Template List_${formatDateTime(new Date())}`,
              toolbarButtonAlignment: "left",
              filtering: true
            }}
            components={{
              Container: Fragment
            }}
            onRowClick={(event, rowData: any) => {
              if (rowData) {
                props.history.push({
                  pathname: `/template/${rowData.id}`
                });
              }
            }}
          />
        </div>
      </main>
    </Fragment>
  );
};

export default withStyles(stylesMain)(withRouter(Template));
